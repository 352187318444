import React from "react";
import { Row } from "antd";
import weightLogo from "../../../assests/images/weightLogo.svg";
import packetContents from "../../../assests/images/packetContents.svg";
import packedIdIcon from "../../../assests/images/packetIcon.svg";
import truck from "../../../assests/images/manifestTruckIcon.svg";
import air from "../../../assests/images/manifestAirIcon.svg";
import { PacketContents } from "./PacketContents";
// import { PacketFooter } from "./PacketFooter";

export const ManifestCard = ({ color, item }) => {
  const felxProp = {
    display: "flex",
    justifyContent: "spaceBetween",
    gap: "9px",
  };
  return (
    <div className={`reactangle-${color}`}>
      <div className="reactangle2">
        <div className="manifestCard-container">
          <div className="firstRow">
            <div style={felxProp}>
              <div>
                <img src={packedIdIcon} className="first-row-img" />
              </div>
              <div>
                <div className="packetId">Packet ID</div>
                <div className="packetNumber">{item?.packetBarcode}</div>
              </div>
            </div>
            <div style={felxProp}>
              <div>
                <img src={weightLogo} />
              </div>
              <div>
                <div className="packetId">Weight</div>
                <div className="packetNumber">{item?.weight?.toFixed(2)}Kg</div>
              </div>
            </div>
            <div style={felxProp}>
              <div style={{ paddingLeft: "10px" }}>
                {item?.travelBy == "surface" ? (
                  <img
                    src={truck}
                    width="25px"
                    style={{ paddingBottom: "5px" }}
                  />
                ) : (
                  <img src={air} width="25px" />
                )}
              </div>
            </div>
          </div>

          <Row gutter={12} className="manifestCard-container-secondRow">
            <div className="packetContent" style={{ display: "flex" }}>
              <div>
                <img src={packetContents} />
              </div>
              <div className="manifestCard-container-text">
                <div>{"Packet Contents"}</div>
                <div
                  style={{
                    maxWidth: "202px",
                    paddingLeft: "5px",
                    paddingTop: "5px",
                  }}
                >
                  <PacketContents numberOfPakcet={item?.packetShipments} />
                </div>
              </div>
            </div>

            <div className="manifestCard-container-secondRow-footer"></div>
          </Row>
        </div>
      </div>
    </div>
  );
};

export const PackedIdAndValue = ({ text, number }) => (
  <>
    <div className="manifestCard-container-text">{text}</div>
    <div className="manifestCard-container-number">{number}</div>
  </>
);
