const constants = {
  ROUTES: {
    HOME: "/home",
    CONTACT_US: "/contactUs",
    LOGIN: "/login",
  },
  HTTP_REQ_DEFAULT_HEADERS: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=UTF-8",
  },
  HTTP_MULTIPART_FORM_HEADERS: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=UTF-8",
  },
  HTTP_METHODS: {
    POST: "POST",
    GET: "GET",
    DELETE: "DELETE",
    PATCH: "PATCH",
    PUT: "PUT",
  },

  HOME: {
    WELCOME: "WELCOME TO",
  },
  ROLES: {
    CP_ADMIN: "ROLE_CPADMIN",
    HO_ADMIN: "ROLE_HOADMIN",
    HUB_ADMIN: "ROLE_HUBADMIN",
    CP_OPERATOR: "ROLE_CPOPERATOR",
    ROLE_HOMARKETING: "ROLE_HOMARKETING",
  },
  CAMPAIGN_NAME: "standard_v20",

  WHATSAPPKEY:
    // eslint-disable-next-line max-len
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1ODAyMmZmODExOGRhNTQ0YmVkMTVmMCIsIm5hbWUiOiJTSFJJIE1BUlVUSSBJTlRFR1JBVEVEIExPR0lTVElDUyBMSU1JVEVEIiwiYXBwTmFtZSI6IkFpU2Vuc3kiLCJjbGllbnRJZCI6IjY1ODAyMjdmMDllNTBkMTBkMDA2Yjk1OCIsImFjdGl2ZVBsYW4iOiJOT05FIiwiaWF0IjoxNzAyODk2MzgzfQ.57vK6P_o4ABg9feJanqUVjyGXubEsNGFQ1KAWKCYoR4",
};

export default constants;
