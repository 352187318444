/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  message,
  Select,
  DatePicker,
  Spin,
  Upload,
} from "antd";
import uploadLogo from "../../../../../assests/images/bx_upload.svg";
import { APIs, getPremiseId, getToken } from "../../../../../utils/worker";
import { useNavigate } from "react-router-dom";
import API from "../../../../../api";
import urls from "../../../../../api/urls";
import axios from "axios";
import moment from "moment";
const { UPLOAD_CLIENT_DOC, UPLOAD_DOC_TO_CLIENT } = urls;
const token = getToken();
const { Dragger } = Upload;
const userId = JSON.parse(localStorage.getItem("userId"));

export const AddAgreement = ({
  getData,
  handleStepsChange = () => {},
  goBackStep = () => {},
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileNew, setFileNew] = useState();

  const formatedDate = (date) => {
    let dd = date.getDate();

    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    let today = dd + "/" + mm + "/" + yyyy;
    return today;
  };

  const dateFormat = "DD/MM/YYYY";

  const disabledDate = (current) => {
    return current < moment().startOf("day");
  };

  const disabledDateNext = (current) => {
    return current <= moment(fromDate, "DD/MM/YYYY").endOf("day");
  };

  const changeFromDate = (date, dateString) => {
    setFromDate(dateString);
    form.setFieldValue("validTo", null);
  };
  const changeToDate = (date, dateString) => {
    setToDate(dateString);
  };

  useEffect(() => {
    let doc = getData?.clientDocument;
    let temp = [];
    if (doc?.length > 0) {
      doc.map((e) => {
        let name = e.fileName?.toString("")?.split("/");
        temp.push({
          name: name[name?.length - 1],
          url: e?.fileName,
          id: e?.fileId,
        });
      });
      setFileList([...temp]);
      form.setFieldValue(
        "validFrom",
        doc[0].startDate ? moment(doc[0].startDate, "DD/MM/YYYY") : ""
      );
      form.setFieldValue(
        "validTo",
        doc[0].startDate ? moment(doc[0].tillDate, "DD/MM/YYYY") : ""
      );
    }
  }, []);
  const propsPan = {
    name: "file",
    multiple: true,
    listType: "picture",
    showUploadList: true,

    action: UPLOAD_CLIENT_DOC,
    className: "upload-list-inline",

    fileList: [],
  };
  const removeFile = (fileList_) => {
    let filteredData = fileList.filter((e) => e.id !== fileList_.id);
    message.success("File deleted successfully");
    setFileList([...filteredData]);
    setFileNew(null);
  };
  const fileRequestHandle = async (e) => {
    setFileNew(e);
    if (e?.name == fileNew?.name) {
      message.error("This file is already uploaded.");
    } else {
      setLoading(true);
      let clientId = localStorage.getItem("clientId");
      const form = new FormData();
      form.set("file", e);
      try {
        const response = await axios.post(UPLOAD_CLIENT_DOC, form, {
          headers: {
            //"Content-Type": "multipart/form-data",
            Authorization: token,
            clientId: clientId,
          },
        });
        if (response.status === 200) {
          let name = response.data.data.filePath.toString("")?.split("/");
          setFileList([
            {
              name: name[name.length - 1],
              url: response.data.data.filePath,
              id: response.data.data.id,
            },
          ]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        message.error(error.response.data.message);
        setLoading(false);
      }
    }
  };

  const submitDocData = async () => {
    setLoading(true);
    if (fileList.length === 0) {
      message.error("Please upload a file.");
      setLoading(false);
    } else {
      if (getData.clientDocument.length > 0) {
        navigate("/client-list");
        localStorage.removeItem("clientId");
        setLoading(false);
      } else {
        let clientId = localStorage.getItem("clientId");
        let payload = [];
        fileList.map((e) => {
          payload.push({
            fileName: e.url,
            fileId: e.id,
            startDate: fromDate,
            tillDate: toDate,
          });
        });
        let response = await API.Api(
          UPLOAD_DOC_TO_CLIENT + clientId
        ).patchCustomHeader(payload, { cpId: userId?.userInfo?.premiseId });
        if (response.status) {
          message.success(
            "Document added successfully, once details will approved by HO you can proceed further. Please wait for 24 hours."
          );
          localStorage.removeItem("clientId");
          setLoading(false);
          navigate("/client-list");
        } else {
          message.error("Internal Server Error");
          setLoading(false);
        }
      }
    }
  };

  return (
    <Spin spinning={loading}>
      <Form
        className="global-form pl-4 pr-4 p-2"
        layout="vertical"
        form={form}
        scrollToFirstError={{
          behavior: "smooth",
          block: "center",
          inline: "center",
        }}
        onFinish={submitDocData}
      >
        <Row gutter={20} className="mt-1 global-form-row">
          <Col span={24} className="mb-1">
            <span className="master-title">Add Documents</span>
            <hr className="divider" />
          </Col>
        </Row>
        <Row gutter={40} className="mt-1 global-form-row">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Form.Item
              name="pancardUrl"
              label="Upload document"
              rules={[
                {
                  required: getData?.clientDocument?.length > 0 ? false : true,
                  message: "Please upload a file",
                },
              ]}
            >
              <div className="one-row">
                <Dragger
                  className=""
                  maxCount={1}
                  disabled={getData?.clientDocument?.length > 0 ? true : false}
                  onRemove={removeFile}
                  beforeUpload={(file) => {
                    let type = file.type.split("/")[1];
                    if (file.size / 1024 / 1024 > 5) {
                      message.warning("Maximum upload file size 5 MB.");
                    } else if (
                      type !== "jpeg" &&
                      type !== "png" &&
                      type !== "pdf"
                    ) {
                      message.warning("Only PNG, JPEG, PDF are allowed.");
                    } else {
                      fileRequestHandle(file);
                    }
                  }}
                  multiple={false}
                  fileList={fileList}
                  style={{ borderRadius: "10px", padding: "10px" }}
                >
                  <Row>
                    <Col
                      span={8}
                      className="m-auto"
                      style={{ borderRight: "1px solid #CECECE" }}
                    >
                      <img
                        src={uploadLogo}
                        className="Upload-logo"
                        style={{ width: "40px", height: "40px" }}
                      />
                    </Col>
                    <Col span={16} className="f-13">
                      <p>Click to upload or drag and drop</p>
                      {/* <p>Maximum file size 10 MB</p> */}
                      <p>
                        Supported file type: PDF, JPEG, PNG and Max 10MB file
                        size
                      </p>
                    </Col>
                  </Row>
                </Dragger>
              </div>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="validFrom"
                  label="Start date"
                  // initialValue={moment(fromDate, dateFormat)}
                  rules={[
                    {
                      required: true,
                      message: "Please select valid from",
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={disabledDate}
                    format={dateFormat}
                    onChange={(date, dateString) =>
                      changeFromDate(date, dateString)
                    }
                    disabled={
                      getData?.clientDocument?.length > 0 ? true : false
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item
                  name="validTo"
                  label="Valid till"
                  // initialValue={moment(toDate, dateFormat)}
                  rules={[
                    {
                      required: true,
                      message: "Please select valid to",
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={disabledDateNext}
                    disabled={getData?.clientDocument?.length > 0}
                    format={dateFormat}
                    onChange={(date, dateString) => setToDate(dateString)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr className="divider" />
        <div className="d-flex flex-end p-1">
          <Button className="cancel-btn ml-1 w-15" onClick={goBackStep}>
            BACK
          </Button>
          <Button htmlType="submit" className="save-btn w-15 ml-1">
            SUBMIT
          </Button>
        </div>
      </Form>
    </Spin>
  );
};

// export default AddRateCard;

export default AddAgreement;
