/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import { useLocation } from "react-router";
import CreateTemplate from "./createTemplate";
import ViewTemplate from "./viewTemplate";
import Heading from "../../../../components/Heading";
import "./index.scss";
import "../../index.scss";
const Master = () => {
  const history = useLocation();
  const [activeKey, setActiveKey] = useState("1");
  const [templateType, setTemplateType] = useState("create");
  useEffect(() => {
    if (
      history?.state === "allocate" ||
      history?.state === "update" ||
      history?.state === "create"
    ) {
      setActiveKey("2");
      setTemplateType("view");
    }
  }, [history?.state]);

  const changeTab = (key) => {
    history.state = "";
    setActiveKey(key);
    switch (key) {
      case "1":
        setTemplateType("create");
        break;
      case "2":
        setTemplateType("view");
        break;
    }
  };

  return (
    <div className="p-2-tab">
      <Heading
        title={"Retail Booking Rate Card"}
        navigateTo={"/master-card-child"}
        id={5}
      />
      <div className="rate-card-tab-container">
        <Tabs
          activeKey={activeKey}
          onChange={changeTab}
          items={[
            {
              label: "Create Template",
              key: "1",
              children: (
                <CreateTemplate
                  templateType={templateType}
                  allocateFor={"RETAIL"}
                />
              ),
            },
            {
              label: "View Template",
              key: "2",
              children: (
                <ViewTemplate
                  templateType={templateType}
                  slug={"/cash-booking-ratecard"}
                  allocateFor={"RETAIL"}
                />
              ),
            },
          ]}
        />
      </div>
    </div>
  );
};
export default Master;
