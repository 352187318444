import { call } from "redux-saga/effects";

import actions from "../../constants/masterActions";
import constants from "../../constants/constants";
import { getDataFromAPI } from "../../utils/sagas";

import { getToken, APIs } from "../../utils/worker";
import { getUserAndPremiseId } from "../../utils";

const getTokenId = getToken() || null;
const authToken = getTokenId || null;

const getUserInfo = getUserAndPremiseId();
const premiseId = getUserInfo?.premiseId;
const userId = getUserInfo?.userId;

export function* getPincodeMasterTableWorker({ pageNum, pageSize }) {
  const reqParams = {
    url: `${APIs.baseURL}/pincode-service/v1/pincode?pageNum=${pageNum}&pageSize=${pageSize}`,
    successAction: actions.GET_PINCODE_MASTER_TABLE_SUCCESS,
    errorAction: actions.GET_PINCODE_MASTER_TABLE_ERROR,
    params: { authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET, {
    SMCS_PREMISE_ID: premiseId,
    USER_ID: userId,
  });
}

export function* getPincodeWorker({ data }) {
  const reqParams = {
    url: `https://api.postalpincode.in/pincode/${data}`,
    successAction: actions.GET_PINCODE_SUCCESS,
    errorAction: actions.GET_PINCODE_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* getStateWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/pincode-service/v1/pincode/state/${data}/pincodes`,
    successAction: actions.GET_STATE_SUCCESS,
    errorAction: actions.GET_STATE_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* getServiceablitiyWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/pincode-service/v1/serviceability`,
    successAction: actions.GET_SERVICEABILITY_SUCCESS,
    errorAction: actions.GET_SERVICEABILITY_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* createPincodeWorker({ data }) {
  let getWebToken = authToken;
  let webToken = getWebToken.slice(6);
  const reqParams = {
    url: `${APIs.baseURL}/pincode-service/v1/pincode`,
    successAction: actions.CREATE_PINCODE_SUCCESS,
    errorAction: actions.CREATE_PINCODE_ERROR,
    params: { ...data, authToken, webToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.POST, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "USER-ID": userId,
  });
}

export function* updatePincodeWorker({ data, id }) {
  let getWebToken = authToken;
  let webToken = getWebToken.slice(6);

  const reqParams = {
    url: `${APIs.baseURL}/pincode-service/v1/pincode/${id}`,
    successAction: actions.UPDATE_PINCODE_SUCCESS,
    errorAction: actions.UPDATE_PINCODE_ERROR,
    params: { ...data, authToken, webToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.PUT);
}

export function* searchPincodeMasterTableWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/pincode-service/v1/searchPincode`,
    successAction: actions.SEARCH_PINCODE_MASTER_TABLE_SUCCESS,
    errorAction: actions.SEARCH_PINCODE_MASTER_TABLE_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* enableDisablePincodeWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/pincode-service/v1/pincode/toggle/${data}`,
    successAction: actions.ENABLE_DISABLE_PINCODE_SUCCESS,
    errorAction: actions.ENABLE_DISABLE_PINCODE_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.PUT);
}

export function* deletePincodeAreaWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/pincode-service/v1/pincode/${data}`,
    successAction: actions.DELETE_PINCODE_AREA_SUCCESS,
    errorAction: actions.DELETE_PINCODE_AREA_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.PUT);
}

//--Rate Card Master--//
export function* getDefaultRateCardWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/ratecard-service/v1/ratecard/1`,
    successAction: actions.GET_DEFAULT_RATE_CARD_SUCCESS,
    errorAction: actions.GET_DEFAULT_RATE_CARD_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* getCategoryBasedValueWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/ratecard-service/v1/ratecard/${data}/values`,
    successAction: actions.GET_CATEGORY_BASED_VALUE_SUCCESS,
    errorAction: actions.GET_CATEGORY_BASED_VALUE_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* createCashRateCardWorker({ data, method }) {
  const reqParams = {
    url: `${APIs.baseURL}/ratecard-service/v1/ratecard`,
    successAction: actions.CREATE_CASH_RATE_CARD_TEMPLATE_SUCCESS,
    errorAction: actions.CREATE_CASH_RATE_CARD_TEMPLATE_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, method);
}

export function* getCashRateCardTemplatesWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/ratecard-service/v1/ratecards${data}`,
    successAction: actions.GET_CASH_RATE_CARD_TEMPLATES_SUCCESS,
    errorAction: actions.GET_CASH_RATE_CARD_TEMPLATES_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* getCashRateCardDetailsWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/ratecard-service/v1/ratecard/${data}`,
    successAction: actions.GET_CASH_RATE_CARD_DETAILS_SUCCESS,
    errorAction: actions.GET_CASH_RATE_CARD_DETAILS_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* allocateCashRateCardWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/ratecard-service/v1/ratecard/allocate`,
    successAction: actions.ALLOCATE_CASH_RATE_CARD_SUCCESS,
    errorAction: actions.ALLOCATE_CASH_RATE_CARD_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.POST);
}

export function* getExistingCashRateCardTemplatesWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/ratecard-service/v1/ratecards/all`,
    successAction: actions.GET_EXISTING_CASH_RATE_CARD_TEMPLATES_SUCCESS,
    errorAction: actions.GET_EXISTING_CASH_RATE_CARD_TEMPLATES_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* getProfileWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/account-service/v1/user/email/${data}`,
    successAction: actions.GET_PROFILE_DETAILS_SUCCESS,
    errorAction: actions.GET_PROFILE_DETAILS_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* saveProfileWorker({ data, payload }) {
  const reqParams = {
    url: `${APIs.baseURL}/account-service/v1/user/${data}`,
    successAction: actions.SAVE_PROFILE_DETAILS_SUCCESS,
    errorAction: actions.SAVE_PROFILE_DETAILS_ERROR,
    params: { ...payload, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.PUT);
}
//----//
//PACKETING GUID//
export function* getPacketingGuideFromHublistWorker(data) {
  const reqParams = {
    url: `${APIs.baseURL}/packet-service/v1/packeting-guide/from-hub`,
    successAction: actions.GET_PACKETING_GUIDE_FROM_HUBLIST_SUCCESS,
    errorAction: actions.GET_PACKETING_GUIDE_FROM_HUBLIST_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}
export function* getPacketingGuideToHublistWorker(data) {
  const reqParams = {
    url: `${APIs.baseURL}/packet-service/v1/packeting-guide/to-hub`,
    successAction: actions.GET_PACKETING_GUIDE_TO_HUBLIST_SUCCESS,
    errorAction: actions.GET_PACKETING_GUIDE_TO_HUBLIST_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}
export function* getPacketingGuideFromCplistWorker(data) {
  const reqParams = {
    url: `${
      APIs.baseURL
    }/packet-service/v1/packeting-guide/from-cp/${+data?.data}`,
    successAction: actions.GET_PACKETING_GUIDE_FROM_CPLIST_SUCCESS,
    errorAction: actions.GET_PACKETING_GUIDE_FROM_CPLIST_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* getPacketingGuideToCplistWorker(data) {
  const reqParams = {
    url: `${
      APIs.baseURL
    }/packet-service/v1/packeting-guide/to-cp/${data?.data?.toString()}`,
    successAction: actions.GET_PACKETING_GUIDE_TO_CPLIST_SUCCESS,
    errorAction: actions.GET_PACKETING_GUIDE_TO_CPLIST_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* getPacketingGuideMasterWorker(data) {
  const reqParams = {
    url: `${APIs.baseURL}/packet-service/v1/packeting-guide`,
    successAction: actions.GET_PACKETING_GUIDE_MASTER_SUCCESS,
    errorAction: actions.GET_PACKETING_GUIDE_MASTER_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* getPacketingGuideSearchWorker(data) {
  const reqParams = {
    url: `${APIs.baseURL}/packet-service/v1/packeting-guide/search/${data?.data}`,
    successAction: actions.GET_PACKETING_GUIDE_SEARCH_SUCCESS,
    errorAction: actions.GET_PACKETING_GUIDE_SEARCH_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* postPacketingGuideMasterWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/packet-service/v1/packeting-guide`,
    successAction: actions.POST_PACKETING_GUIDE_MASTER_SUCCESS,
    errorAction: actions.POST_PACKETING_GUIDE_MASTER_ERROR,
    clearAction: actions.POST_PACKETING_GUIDE_MASTER_CLEAR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.POST);
}

export function* putPacketingGuideMasterWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/packet-service/v1/packeting-guide/`,
    successAction: actions.PUT_PACKETING_GUIDE_MASTER_SUCCESS,
    errorAction: actions.PUT_PACKETING_GUIDE_MASTER_ERROR,
    clearAction: actions.PUT_PACKETING_GUIDE_MASTER_CLEAR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.PUT);
}

export function* getPacketingGuideDownloadWorker(data) {
  const reqParams = {
    url: `${APIs.baseURL}/packet-service/v1/packeting-guide/download`,
    successAction: actions.GET_PACKETING_GUIDE_DOWNLOAD_SUCCESS,
    errorAction: actions.GET_PACKETING_GUIDE_DOWNLOAD_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}
export function* getPacketConfigurationWorker(data) {
  const reqParams = {
    url: `${APIs.baseURL}/admin-config/v1`,
    successAction: actions.GET_PACKET_CONFIGURATION_SUCCESS,
    errorAction: actions.GET_PACKET_CONFIGURATION_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}
//-----------------//

// pincode mapping //
export function* getPincodeMappingWorker(pageData, pageSize) {
  const reqParams = {
    url: `${APIs.baseURL}/pincode-service/v1/pincode-mapping?pageNo=${
      pageData?.pageNum ? pageData?.pageNum - 1 : 0
    }&pageSize=${pageData?.pageSize}`,
    successAction: actions.GET_PINCODE_MAPPING_SUCCESS,
    errorAction: actions.GET_PINCODE_MAPPING_ERROR,
    params: { ...pageData, ...pageSize, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}
// ----------------//

// ----Hub onboarding-----//
export function* getAllHubOnboardWorker(pageData, pageSize) {
  const reqParams = {
    url: `${APIs.baseURL}/premise-service/v1/premises/hub?pageNo=${
      pageData?.pageNum ? pageData?.pageNum - 1 : 0
    }&pageSize=${pageData?.pageSize}`,
    successAction: actions.GET_ALL_HUB_ONBOARD_SUCCESS,
    errorAction: actions.GET_ALL_HUB_ONBOARD_ERROR,
    params: { ...pageData, ...pageSize, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

//--Sorting Guide--//
export function* getHubListWorker(data) {
  const reqParams = {
    url: `${APIs.baseURL}/premise-service/v1/premises`,
    successAction: actions.GET_HUB_LIST_SUCCESS,
    errorAction: actions.GET_HUB_LIST_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* createSortingGuideWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/sorting-service/v1/sorting-guide`,
    successAction: actions.CREATE_SORTING_GUIDE_SUCCESS,
    errorAction: actions.CREATE_SORTING_GUIDE_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.POST);
}

export function* getHubBasedSortingGuideWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/sorting-service/v1/sorting-guide/premise-id/${data}`,
    successAction: actions.GET_HUB_BASED_SORTING_GUIDE_SUCCESS,
    errorAction: actions.GET_HUB_BASED_SORTING_GUIDE_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* getAllSortingGuideWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/sorting-service/v1/sorting-guide`,
    successAction: actions.GET_ALL_SORTING_GUIDE_SUCCESS,
    errorAction: actions.GET_ALL_SORTING_GUIDE_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* viewSortingGuideWorker({ data }) {
  // eslint-disable-next-line no-unused-vars
  let { travelBy, SMCS_PREMISE_ID } = data;
  let url = travelBy ? `&travelBy=${travelBy}` : "";
  const reqParams = {
    url: `${APIs.baseURL}/sorting-service/v1/sorting-guide/view-sorting-guide?SMCS-PREMISE-ID=${SMCS_PREMISE_ID}${url}`,
    successAction: actions.VIEW_SORTING_GUIDE_SUCCESS,
    errorAction: actions.VIEW_SORTING_GUIDE_ERROR,
    params: { ...travelBy, "SMCS-PREMISE-ID": SMCS_PREMISE_ID, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}
//--//
// onBording //
export function* premiseTableWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/premise-service/v1/premises/view${data}
    `, //changed
    successAction: actions.PREMISE_TABLE_SUCCESS,
    errorAction: actions.PREMISE_TABLE_ERROR,
    params: { ...data, authToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}

// onBording //
export function* putPacketConfigurationWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/admin-config/v1/${data?.id}`,
    successAction: actions.PUT_PACKET_CONFIGURATION_SUCCESS,
    errorAction: actions.PUT_PACKET_CONFIGURATION_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.PUT);
}

export function* patchOnboardFormWorker({ data, id }) {
  const reqParams = {
    url: `${APIs.baseURL}/premise-service/v1/premise/cp/${id}`,
    successAction: actions.ONBOARD_PATCH_SUCCESS,
    errorAction: actions.ONBOARD_PATCH_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.PATCH, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
  });
}
//////////////Vehicle Onboar start ///////////////////////
export function* getCPAdminAllVehicle(data) {
  const reqParams = {
    url: `${APIs.baseURL}/admin-config/v1`,
    successAction: actions.GET_PACKET_CONFIGURATION_SUCCESS,
    errorAction: actions.GET_PACKET_CONFIGURATION_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}
//////////////Vehicle Onboar ENd ///////////////////////
//--TAT MASTER--//

export function* getFromHubTatWorker(data) {
  const reqParams = {
    url: `${APIs.baseURL}/tat-service/v1/tat/mm-tat/all`,
    successAction: actions.GET_FROM_HUB_TAT_SUCCESS,
    errorAction: actions.GET_FROM_HUB_TAT_ERROR,
    params: { ...data, authToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* getToHubTatWorker({ data, id }) {
  const reqParams = {
    url: `${APIs.baseURL}/tat-service/v1/tat/mm-tat/${data}/${id}`,
    successAction: actions.GET_TO_HUB_TAT_SUCCESS,
    errorAction: actions.GET_TO_HUB_TAT_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* getFMLMTatWorker(data) {
  const reqParams = {
    url: `${APIs.baseURL}/tat-service/v1/tat/fm-lm-tat/all`,
    successAction: actions.GET_FM_LM_TAT_SUCCESS,
    errorAction: actions.GET_FM_LM_TAT_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}
