/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import back_img from "../../../assests/images/rightVector.svg";
import TableContent from "./TableContent";
import appLogo from "../../../assests/images/smcsLogo.svg";
import printLogo from "../../../assests/images/bluePrint.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";
import locationLogo from "../../../assests/images/LocationIconFomManifest.svg";
import { Button, Col, Row, Table, Tooltip, Spin, message } from "antd";
import Moment from "react-moment";
import { camelCase } from "../../../utils";

import "./style.scss";
import API from "../../../api";
import urls from "../../../api/urls";
import Heading from "../../../components/Heading";
const { CP_WISE_HUB_PRINT } = urls;

function printManifest() {
  const [apiData, setApiData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const manifestId = useParams();

  useEffect(() => {
    getPrintData();
  }, [manifestId]);

  const getPrintData = async () => {
    setLoading(true);
    let response = await API.Api(
      CP_WISE_HUB_PRINT + manifestId?.id
    ).getWithUserNPremiseHeader();
    if (response?.status) {
      setLoading(false);
      setApiData(response?.response?.data);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network error");
    }
  };

  function DateFormat(dateToFormat) {
    const toUpperCaseFilter = (d) => {
      return d.slice(0, 10);
    };
    return (
      <Moment filter={toUpperCaseFilter} format="DD/MM/YYYY">
        {dateToFormat}
      </Moment>
    );
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="container">
        <div className="p-05">
          <Heading
            title={"Print Manifest"}
            nextPage={null}
            nextPageTitle={null}
            // imgSrc={sorting}
          />
        </div>
        <hr className="divider" />
        <div className="print-btn">
          <Button onClick={handlePrint}>
            <img src={printLogo} />
            PRINT
          </Button>
        </div>
        <div className="container-flex p-2" ref={componentRef}>
          <div className="container-contain">
            <Row className="container-contain-div">
              <Col span={8} className="">
                <img src={appLogo} />
              </Col>
            </Row>
            <Row style={{ padding: "0 21px" }}>
              <Col lg={10}>
                <Address
                  cpOrHub={"CP"}
                  address={camelCase(apiData?.cpAddress)}
                />
              </Col>
              <Col lg={4}></Col>
              <Col lg={10}>
                <Address
                  cpOrHub={"Hub"}
                  address={camelCase(apiData?.hubAddress)}
                />
              </Col>
            </Row>
            <Row className="container-contain-dateandtime">
              <Col lg={6}>
                Manifest ID : <span>{apiData?.manifestId}</span>
              </Col>
              <Col lg={5}>
                Date :{" "}
                <span className="container-contain-dateandtime-heading">
                  {DateFormat(apiData?.manifestDate)}
                </span>
              </Col>
              <Col lg={4}>
                Time :{" "}
                <span className="container-contain-dateandtime-heading">
                  {apiData?.manifestTime}
                </span>
              </Col>
              <Col lg={6}></Col>
            </Row>
            <Row
              className="container-contain-dateandtime"
              style={{
                // marginLeft: "20px",
                marginTop: "-45px",
                // fontWeight: "500",
              }}
            >
              <Col lg={4}>
                Drive name :{" "}
                <span className="container-contain-dateandtime-heading">
                  {apiData?.driverName || "N/A"}
                </span>
              </Col>
              <Col lg={2}></Col>
              <Col lg={6}>
                Vehicle No :{" "}
                <span className="container-contain-dateandtime-heading">
                  {apiData?.vehicleNumber?.toUpperCase() || "N/A"}
                </span>
              </Col>
              <Col lg={6}></Col>
            </Row>
            <div className="container-contain-table">
              <TableContent apiData={apiData} />
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}

const Address = ({ address, cpOrHub }) => {
  return (
    <div className="address">
      <div>
        <img src={locationLogo} />
        <span className="address-header ml-05">{`${cpOrHub} Address`}</span>
      </div>
      <div className="address-desc">{address}</div>
    </div>
  );
};

export default printManifest;
