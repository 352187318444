/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  message,
  Tooltip,
  Modal,
  Spin,
  Button,
  Input,
  Form,
} from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { APIs, getToken } from "../../../../utils/worker";
import { capitaliseWithHyphen } from "../../../../utils";
import Heading from "../../../../components/Heading";
import API from "../../../../api";
import urls from "../../../../api/urls";
import postcard from "../../../../assests/images/master/postcard.svg";
import zone_location_icon from "../../../../assests/images/master/zone-location-icon.svg";
import zone_location_circle from "../../../../assests/images/master/zone-location-circle.svg";
import city_icon from "../../../../assests/images/master/city.svg";
import scooter from "../../../../assests/images/master/scooter.svg";
import with_in_state from "../../../../assests/images/master/with_in_state.svg";
import print_icon from "../../../../assests/images/master/print.svg";
import download_icon from "../../../../assests/images/master/download.svg";
import rest_of_india from "../../../../assests/images/master/restofindia-icon.svg";
import search_icon from "../../../../assests/images/search-icon.svg";
import RateCardTable from "../../../../components/Master/RateCard/RateCardTable";
import rate_card_back from "../../../../assests/images/onbording/rate-card-back.svg";
import Smcs_Loader from "../../../../assests/images/loader-new.gif";
import { fovFormInitialValues } from "../../../LeadGenerationCP/AllocateRateCard/rateCardUtils";

const {
  GET_CLIENT_DETAILS,
  PINCODE_MAPPING_PATCH,
  APPROVE_CLIENT,
  GET_CLIENT_RATE_CARD,
  REJECT_CLIENT,
} = urls;

const token = getToken();

const ViewClientDetails = ({ navigation }) => {
  const navigate = useNavigate();
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const params = useParams();
  const userId = JSON.parse(localStorage.getItem("userId"));
  const userInfo = JSON.parse(localStorage.getItem("userinfo"));
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pincodeModalVisible, setPincodeModalVisible] = useState(false);
  const [rejectModalVisible, setRejectModalVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [parentDataSource, setParentDataSource] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [clientOnbaord, setClientOnboard] = useState(null);
  const [clientOrganizationDetails, setClientOrganizationDetails] =
    useState(null);
  const [standardDox, setStandardDox] = useState([]);
  const [standardNonDox, setStandardNonDox] = useState([]);
  const [fastTrackSurface, setFastTrackSurface] = useState([]);
  const [fastTrackAir, setFastTrackAir] = useState([]);
  const [fastTrackNonDox, setFastTrackNonDox] = useState([]);
  const [valuePlusDox, setValuePlusDox] = useState([]);
  const [valuePlusNonDox, setValuePlusNonDox] = useState([]);
  const [viewRateCard, setViewRateCard] = useState(false);

  const columns = [
    {
      title: (
        <div className="d-flex align-center">
          <img src={scooter} />
          <span>Local</span>
        </div>
      ),
      dataIndex: "local",
      width: 130,
      render: (text, _record, index) => (
        <>
          {text ? (
            <div className="d-flex">
              <span style={{ color: "#2E77D0" }}>
                {capitaliseWithHyphen(text)}
              </span>
              <Button
                className="pin-code-count"
                onClick={() => openPincodeModal(_record.localValue)}
              >
                {_record.localValue.length}
              </Button>
            </div>
          ) : (
            "------"
          )}
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={with_in_state} />
          <span>Within State</span>
        </div>
      ),
      dataIndex: "withInState",
      width: 130,
      render: (text, _record, index) => (
        <>
          {text ? (
            <div className="d-flex">
              <span style={{ color: "#2E77D0" }}>
                {capitaliseWithHyphen(text)}
              </span>
              <Button
                className="pin-code-count"
                onClick={() => openPincodeModal(_record.withInStateValue)}
              >
                {_record.withInStateValue.length}
              </Button>
            </div>
          ) : (
            "------"
          )}
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={zone_location_circle} />
          <span>My Zone 1</span>
        </div>
      ),
      dataIndex: "myZoneOne",
      width: 130,
      render: (text, _record, index) => (
        <>
          {text ? (
            <div className="d-flex">
              <span style={{ color: "#2E77D0" }}>
                {capitaliseWithHyphen(text)}
              </span>
              <Button
                className="pin-code-count"
                onClick={() => openPincodeModal(_record.myZoneOneValue)}
              >
                {_record.myZoneOneValue.length}
              </Button>
            </div>
          ) : (
            "------"
          )}
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={zone_location_circle} />
          <span>My Zone 2</span>
        </div>
      ),
      dataIndex: "myZoneTwo",
      width: 130,
      render: (text, _record, index) => (
        <>
          {text ? (
            <div className="d-flex">
              <span style={{ color: "#2E77D0" }}>
                {capitaliseWithHyphen(text)}
              </span>
              <Button
                className="pin-code-count"
                onClick={() => openPincodeModal(_record.myZoneTwoValue)}
              >
                {_record.myZoneTwoValue.length}
              </Button>
            </div>
          ) : (
            "------"
          )}
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={zone_location_circle} />
          <span>My Zone 3</span>
        </div>
      ),
      dataIndex: "myZoneThree",
      width: 130,
      render: (text, _record, index) => (
        <>
          {text ? (
            <div className="d-flex">
              <span style={{ color: "#2E77D0" }}>
                {capitaliseWithHyphen(text)}
              </span>
              <Button
                className="pin-code-count"
                onClick={() => openPincodeModal(_record.myZoneThreeValue)}
              >
                {_record.myZoneThreeValue.length}
              </Button>
            </div>
          ) : (
            "------"
          )}
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={zone_location_circle} />
          <span>My Zone 4</span>
        </div>
      ),
      dataIndex: "myZoneFour",
      width: 130,
      render: (text, _record, index) => (
        <>
          {text ? (
            <span style={{ color: "#2E77D0" }}>
              {capitaliseWithHyphen(text)}
            </span>
          ) : (
            "------"
          )}
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={city_icon} />
          <span>Metro</span>
        </div>
      ),
      dataIndex: "metro",
      render: (text, _record, index) => (
        <>
          {text ? (
            <span style={{ color: "#2E77D0" }}>
              {capitaliseWithHyphen(text)}
            </span>
          ) : (
            "------"
          )}
        </>
      ),
      width: 130,
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={zone_location_circle} />
          <span>Special locations</span>
        </div>
      ),
      dataIndex: "specialPlaces",
      width: 130,
      render: (text, _record, index) => (
        <>
          {text ? (
            <span style={{ color: "#2E77D0" }}>
              {capitaliseWithHyphen(text)}
            </span>
          ) : (
            "------"
          )}
        </>
      ),
    },
    {
      title: (
        <div className="d-flex align-center">
          <img src={rest_of_india} />
          <span>Rest of India</span>
        </div>
      ),
      dataIndex: "restOfIndia",
      width: 130,
      render: (text, _record, index) => (
        <>
          {text ? (
            <span style={{ color: "#2E77D0" }}>
              {capitaliseWithHyphen(text)}
            </span>
          ) : (
            "------"
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (params.id) {
      getClientDetails(params.id);
      getRateCardDetails(params.id);
    }
  }, []);

  const openPincodeModal = (value) => {
    setModalData(value);
    setPincodeModalVisible(true);
  };

  const approveClient = async () => {
    setLoading(true);
    let response = await API.Api(
      APPROVE_CLIENT + `client/${params.id}/approve`
    ).putWithHeader();
    if (response.status) {
      message.success("Client details approved successfully");
      navigate("/dashboard");
      setLoading(false);
    } else {
      message.error(
        "Something wrong , please contact to your respective Admin"
      );
      setLoading(false);
    }
  };

  const rejectClient = async () => {
    setLoading(true);
    let reason = form.getFieldValue("reason");
    let userData = JSON.parse(localStorage.getItem("userinfo"));
    let response = await API.Api(REJECT_CLIENT).putCustomHeader(reason, {
      token: userData?.accessToken,
      clientId: params.id,
      "content-type": "text/plain",
    });
    if (response.status) {
      message.success("Client details declined");
      navigate("/dashboard");
      setRejectModalVisible(false);
      form.resetFields();
      setLoading(false);
    } else {
      message.success(
        "Something wrong , please contact to your respective Admin"
      );
      setRejectModalVisible(false);
      setLoading(false);
    }
  };

  // useEffect(() => {}, [loading]);

  const getRateCardDetails = async () => {
    let { response } = await API.Api(
      GET_CLIENT_RATE_CARD + `${params?.id}`
    ).getWithHeader();
    let data = response?.data;
    if (data) {
      form.setFieldsValue({
        fov: data?.fov ? parseFloat(data.fov).toFixed(2) : "0.00",
        insurance: data?.insurance
          ? parseFloat(data.insurance).toFixed(2)
          : "0.00",
        handlingCharges: data?.handlingCharges
          ? parseFloat(data.handlingCharges).toFixed(2)
          : "0.00",
        premiumServiceCharges: data?.premiumServiceCharges
          ? parseFloat(data.premiumServiceCharges).toFixed(2)
          : "0.00",
        fuelServiceCharges: data?.fuelServiceCharges
          ? parseFloat(data.fuelServiceCharges).toFixed(2)
          : "0.00",
        codCharges: data?.codCharges
          ? parseFloat(data.codCharges).toFixed(2)
          : "0.00",
      });
      let temp = data?.services;
      let standardTemp = [];
      let fastTrackTemp = [];
      let valuePlusTemp = [];
      if (temp) {
        temp.map((e) => {
          switch (e.serviceType) {
            case "STANDARD":
              {
                standardTemp.push(e.docs);
              }
              break;
            case "FAST TRACK":
              {
                fastTrackTemp.push(e.docs);
              }
              break;
            case "VALUE PLUS":
              {
                valuePlusTemp.push(e.docs);
              }
              break;
          }
        });
      }
      if (standardTemp) {
        standardTemp.map((item) => {
          item.map((e) => {
            switch (e.docType) {
              case "DOX": {
                let standardDoxTemp = [];
                e.transports.map((item) => {
                  switch (item.mode) {
                    case "Surface": {
                      item.rates.map((rateValue, index) => {
                        standardDoxTemp.push({
                          title:
                            index === item.rates.length - 1
                              ? `Add ${rateValue.rateMode.split("Slab ")[1]}`
                              : `up to ${rateValue.rateMode}`,
                          design: index === item.rates.length - 1 ? true : null,
                          value: Object.entries(
                            item.rates[index]?.rateTypes
                          ).map(([title, value]) => ({
                            title,
                            value: parseFloat(value).toFixed(2),
                          })),
                        });
                      });
                    }
                  }
                });
                setStandardDox(standardDoxTemp);
                break;
              }

              case "NON_DOX":
                {
                  let standardNonDoxTemp = [];
                  e.transports.map((item) => {
                    switch (item.mode) {
                      case "Surface": {
                        standardNonDoxTemp.push({
                          title: "By Surface (Per Kg)",
                          design: null,
                          value: Object.entries(item.rates[0]?.rateTypes).map(
                            ([title, value]) => ({ title, value })
                          ),
                        });
                        break;
                      }
                      case "Air": {
                        item.rates.map((rateItem, index) => {
                          standardNonDoxTemp.push({
                            title:
                              index === item.rates.length - 1
                                ? `Add ${rateItem.rateMode.split("Slab ")[1]}`
                                : "By Air (Per Kg)",
                            design:
                              index === item.rates.length - 1 ? true : null,
                            value: Object.entries(
                              item.rates[index]?.rateTypes
                            ).map(([title, value]) => ({ title, value })),
                          });
                        });
                        break;
                      }
                    }
                  });

                  setStandardNonDox(standardNonDoxTemp);
                }
                break;
            }
          });
        });
      }
      if (fastTrackTemp) {
        fastTrackTemp.map((item) => {
          item.map((e) => {
            switch (e.docType) {
              case "DOX": {
                let fastTrackDoxSurfaceTemp = [];
                let fastTrackDoxAirTemp = [];
                e.transports.map((item) => {
                  switch (item.mode) {
                    case "Surface": {
                      item.rates.map((rateValue, index) => {
                        fastTrackDoxSurfaceTemp.push({
                          title:
                            index === item.rates.length - 1
                              ? `Add ${rateValue.rateMode.split("Slab ")[1]}`
                              : `up to ${rateValue.rateMode}`,
                          design: index === item.rates.length - 1 ? true : null,
                          value: Object.entries(
                            item.rates[index]?.rateTypes
                          ).map(([title, value]) => ({ title, value })),
                        });
                      });
                      break;
                    }
                    case "Air": {
                      item.rates.map((rateValue, index) => {
                        fastTrackDoxAirTemp.push({
                          title:
                            index === item.rates.length - 1
                              ? `Add ${rateValue.rateMode.split("Slab ")[1]}`
                              : `up to ${rateValue.rateMode}`,
                          design: index === item.rates.length - 1 ? true : null,
                          value: Object.entries(
                            item.rates[index]?.rateTypes
                          ).map(([title, value]) => ({ title, value })),
                        });
                      });
                    }
                  }
                });
                setFastTrackSurface(fastTrackDoxSurfaceTemp);
                setFastTrackAir(fastTrackDoxAirTemp);
                break;
              }

              case "NON_DOX":
                {
                  let fastTrackNonDoxTemp = [];
                  e.transports.map((item) => {
                    switch (item.mode) {
                      case "Surface": {
                        fastTrackNonDoxTemp.push({
                          title: "By Surface (Per Kg)",
                          design: null,
                          value: Object.entries(item.rates[0]?.rateTypes).map(
                            ([title, value]) => ({ title, value })
                          ),
                        });
                        break;
                      }
                      case "Air": {
                        item.rates.map((rateItem, index) => {
                          fastTrackNonDoxTemp.push({
                            title:
                              index === item.rates.length - 1
                                ? `Add ${rateItem.rateMode.split("Slab ")[1]}`
                                : "By Air (Per Kg)",
                            design:
                              index === item.rates.length - 1 ? true : null,
                            value: Object.entries(
                              item.rates[index]?.rateTypes
                            ).map(([title, value]) => ({ title, value })),
                          });
                        });
                        break;
                      }
                    }
                  });
                  setFastTrackNonDox(fastTrackNonDoxTemp);
                }
                break;
            }
          });
        });
      }
      if (valuePlusTemp) {
        valuePlusTemp.map((item) => {
          item.map((e) => {
            switch (e.docType) {
              case "DOX": {
                let valuePlusDoxTemp = [];
                e.transports.map((item) => {
                  switch (item.mode) {
                    case "Surface": {
                      item.rates.map((rateValue, index) => {
                        valuePlusDoxTemp.push({
                          title:
                            index === item.rates.length - 1
                              ? `Add ${rateValue.rateMode.split("Slab ")[1]}`
                              : `up to ${rateValue.rateMode}`,
                          design: index === item.rates.length - 1 ? true : null,
                          value: Object.entries(
                            item.rates[index]?.rateTypes
                          ).map(([title, value]) => ({ title, value })),
                        });
                      });
                    }
                  }
                });
                setValuePlusDox(valuePlusDoxTemp);
                break;
              }

              case "NON_DOX":
                {
                  let valuePlusNonDoxTemp = [];
                  e.transports.map((item) => {
                    switch (item.mode) {
                      case "Surface": {
                        valuePlusNonDoxTemp.push({
                          title: "By Surface (Per Kg)",
                          design: null,
                          value: Object.entries(item.rates[0]?.rateTypes).map(
                            ([title, value]) => ({ title, value })
                          ),
                        });
                        break;
                      }
                      case "Air": {
                        item.rates.map((rateItem, index) => {
                          valuePlusNonDoxTemp.push({
                            title:
                              index === item.rates.length - 1
                                ? `Add ${rateItem.rateMode.split("Slab ")[1]}`
                                : "By Air (Per Kg)",
                            design:
                              index === item.rates.length - 1 ? true : null,
                            value: Object.entries(
                              item.rates[index]?.rateTypes
                            ).map(([title, value]) => ({ title, value })),
                          });
                        });
                        break;
                      }
                    }
                  });
                  setValuePlusNonDox(valuePlusNonDoxTemp);
                }
                break;
            }
          });
        });
      }
    }
  };
  const getClientDetails = async (clientId) => {
    setLoading(true);
    let response = await API.Api(
      GET_CLIENT_DETAILS + `${clientId}`
    ).getCustomHeader({ cpId: userId?.userInfo?.premiseId });
    if (response.status) {
      let data = response.response.data;
      let client = {
        "Client name": data.clientOnboard.clientName,
        "Organization name": data.clientOnboard.organizationName,
        GSTIN: data.clientOnboard.gstin,
        Address:
          data.clientOnboard.addressline1 +
          ", " +
          data.clientOnboard.addressline2,
        Email: data.clientOnboard.emailId,
        "Primary phone number": data.clientOnboard.primaryNum,
        "Secondary phone number": data.clientOnboard.secondaryNum,
        City: data.clientOnboard.city,
        State: data.clientOnboard.state,
        Pincode: data.clientOnboard.pincode,
        Status: data.clientOnboard.status,
      };
      let organization = {
        "Contact person": data.clientOrganizationDetails.contactPersonName,
        "Contact person no": data.clientOrganizationDetails.contactPerson,
        Designation: data.clientOrganizationDetails.designation || "N/A",
        Department: data.clientOrganizationDetails.departmentName,
        "Party name": data.clientOrganizationDetails.partyName,
        "Nature of business": data.clientOrganizationDetails.bussiness || "N/A",
        "Is SEZ?": data.clientOrganizationDetails.sez ? "Yes" : "No",
        Centralized: data.clientOrganizationDetails.centeralized ? "Yes" : "No",
        "Pickup time": data.clientOrganizationDetails.pickupTime,
        "Committed business volume per month":
          data.clientOrganizationDetails.bussinessVolume,
      };
      setClientOnboard(client);
      setClientOrganizationDetails(organization);
      let doc = data.clientDocument;
      let temp = [];
      doc.map((e) => {
        let name = e.fileName.toString("")?.split("/");
        temp.push({
          name: name[name.length - 1],
          url: e.fileName,
          id: e.fileId,
        });
      });
      setFileList([...temp]);
      if (data.pincodeMapping) {
        getPincodeDetails(clientId);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getPincodeDetails = async (clientId) => {
    let response = await API.Api(
      PINCODE_MAPPING_PATCH + `${clientId}`
    ).getCustomHeader({ cpId: userId?.userInfo?.premiseId });
    if (response.status) {
      setParentDataSource(response.response.data);
      getUpdatedTable(response.response.data);
    }
  };
  const getUpdatedTable = (result) => {
    let {
      localMap,
      metroMap,
      restOfIndiaMap,
      withInStateMap,
      specialPlacesMap,
      myZoneOneMap,
      myZoneTwoMap,
      myZoneThreeMap,
      myZoneFourMap,
    } = result;
    let local = Object.keys(localMap || []);
    let localValue = Object.values(localMap || []);
    let metro = Object.keys(metroMap || []);
    let metroValue = Object.values(metroMap || []);
    let restOfIndia = Object.keys(restOfIndiaMap || []);
    let restOfIndiaValue = Object.values(restOfIndiaMap || []);
    let withInState = Object.keys(withInStateMap || []);
    let withInStateValue = Object.values(withInStateMap || []);
    let specialPlaces = Object.keys(specialPlacesMap || []);
    let specialPlacesValue = Object.values(specialPlacesMap || []);
    let myZoneOne = Object.keys(myZoneOneMap || []);
    let myZoneOneValue = Object.values(myZoneOneMap || []);
    let myZoneTwo = Object.keys(myZoneTwoMap || []);
    let myZoneTwoValue = Object.values(myZoneTwoMap || []);
    let myZoneThree = Object.keys(myZoneThreeMap || []);
    let myZoneThreeValue = Object.values(myZoneThreeMap || []);
    let myZoneFour = Object.keys(myZoneFourMap || []);
    let myZoneFourValue = Object.values(myZoneFourMap || []);

    let lengthTest = [
      metro?.length,
      restOfIndia?.length,
      withInState?.length,
      specialPlaces?.length,
      myZoneOne?.length,
      myZoneTwo?.length,
      myZoneThree?.length,
      myZoneFour?.length,
    ].sort((a, b) => {
      return a - b;
    });
    let dataSourceTemp = [];
    for (let i = 0; i < lengthTest[lengthTest.length - 1]; i++) {
      dataSourceTemp.push({
        local: local[i] !== undefined ? local[i] : null,
        localValue: localValue[i] !== undefined ? localValue[i] : null,
        metro: metro[i] !== undefined ? metro[i] : null,
        metroValue: metroValue[i] !== undefined ? metroValue[i] : null,
        restOfIndia: restOfIndia[i] !== undefined ? restOfIndia[i] : null,
        restOfIndiaValue:
          restOfIndiaValue[i] !== undefined ? restOfIndiaValue[i] : null,
        withInState: withInState[i] !== undefined ? withInState[i] : null,
        withInStateValue:
          withInStateValue[i] !== undefined ? withInStateValue[i] : null,
        specialPlaces: specialPlaces[i] !== undefined ? specialPlaces[i] : null,
        specialPlacesValue:
          specialPlacesValue[i] !== undefined ? specialPlacesValue[i] : null,
        myZoneOne: myZoneOne[i] !== undefined ? myZoneOne[i] : null,
        myZoneOneValue:
          myZoneOneValue[i] !== undefined ? myZoneOneValue[i] : null,
        myZoneTwo: myZoneTwo[i] !== undefined ? myZoneTwo[i] : null,
        myZoneTwoValue:
          myZoneTwoValue[i] !== undefined ? myZoneTwoValue[i] : null,
        myZoneThree: myZoneThree[i] !== undefined ? myZoneThree[i] : null,
        myZoneFour: myZoneFour[i] !== undefined ? myZoneFour[i] : null,
        myZoneThreeValue:
          myZoneThreeValue[i] !== undefined ? myZoneThreeValue[i] : null,
        myZoneFourValue:
          myZoneFourValue[i] !== undefined ? myZoneFourValue[i] : null,
      });
    }
    setDataSource(dataSourceTemp);
  };

  const clientOnbaordDesign = () => {
    let temp = [];
    for (let key in clientOnbaord) {
      temp.push(
        <Col span={8}>
          <div
            style={{
              fontSize: "14px",
              color: "#888888",
              padding: "5px",
            }}
          >
            {key}
          </div>
          <div
            style={{
              fontSize: "14px",
              color: "#202124",
              padding: "5px",
              marginBottom: "5px",
              width: "95%",
              fontWeight: "500",
              textTransform: key === "Email" ? "" : "capitalize",
              overflowWrap: "anywhere",
            }}
          >
            {clientOnbaord[key] || "N/A"}
          </div>
        </Col>
      );
    }
    return temp;
  };
  const orgOnbaordDesign = () => {
    let temp = [];
    for (let key in clientOrganizationDetails) {
      temp.push(
        <Col span={8}>
          <div
            style={{
              fontSize: "14px",
              color: "#888888",
              padding: "5px",
            }}
          >
            {key}
          </div>
          <div
            style={{
              fontSize: "14px",
              color: "#202124",
              padding: "5px",
              marginBottom: "5px",
              width: "95%",
              fontWeight: "500",
              textTransform: "capitalize",
              overflowWrap: "anywhere",
            }}
          >
            {clientOrganizationDetails[key] || "N/A"}
          </div>
        </Col>
      );
    }
    return temp;
  };

  const tableLoading = {
    spinning: isTableLoading,
    indicator: <SyncOutlined spin />,
  };

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  const openFile = (url) => {
    window.open(url);
  };

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div style={{ margin: "42px  22px -28px 23px" }}>
        {viewRateCard ? (
          <span
            className="master-title"
            style={{ padding: "0 0 1rem", fontSize: "16px" }}
          >
            <img
              src={rate_card_back}
              className="mr-1 pointer"
              onClick={() => setViewRateCard(false)}
            />{" "}
            View Rate Card
          </span>
        ) : (
          <Heading title="Client List" />
        )}
      </div>
      {!viewRateCard ? (
        <Row>
          <Col span={3} />
          <Col span={18}>
            <div className="master-main-container m-1 mt-2 p-2">
              <div
                style={{
                  backgroundColor: "#F9F9F9",
                  padding: "0.5rem",
                  display: "flex",
                  alignItems: "center",
                  color: "#061624",
                  fontWeight: "500",
                  fontSize: "15px",
                }}
              >
                Organization Details
              </div>
              <Row className="mt-1 p-1">{clientOnbaordDesign()}</Row>
              <div
                style={{
                  backgroundColor: "#F9F9F9",
                  padding: "0.5rem",
                  display: "flex",
                  alignItems: "center",
                  color: "#061624",
                  fontWeight: "500",
                  fontSize: "15px",
                }}
              >
                Business Details
              </div>
              <Row className="mt-1 p-1">{orgOnbaordDesign()}</Row>
              <div
                style={{
                  backgroundColor: "#F9F9F9",
                  padding: "0.5rem",
                  display: "flex",
                  alignItems: "center",
                  color: "#061624",
                  fontWeight: "500",
                  fontSize: "15px",
                }}
              >
                Pincode Mapping
              </div>
              <Row className="mt-1 p-1">
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{
                    itemRender: itemRender,
                    pageSizeOptions: [5, 10, 20, 50, 100],
                  }}
                  scroll={{
                    x: 1700,
                  }}
                />
              </Row>
              <div
                style={{
                  backgroundColor: "#F9F9F9",
                  padding: "0.5rem",
                  display: "flex",
                  alignItems: "center",
                  color: "#061624",
                  fontWeight: "500",
                  fontSize: "15px",
                }}
              >
                Ratecard Details
              </div>
              <Row className="mt-1 p-1">
                <div
                  className="rate-card-container"
                  style={{
                    background: "#fff",
                    border: "1px solid #DDDDDD",
                    width: "100%",
                  }}
                >
                  <Button
                    className="cancel-btn"
                    onClick={() => setViewRateCard(true)}
                  >
                    VIEW RATE CARD
                  </Button>
                </div>
              </Row>
              <div
                style={{
                  backgroundColor: "#F9F9F9",
                  padding: "0.5rem",
                  display: "flex",
                  alignItems: "center",
                  color: "#061624",
                  fontWeight: "500",
                  fontSize: "15px",
                }}
              >
                Agreement Details
              </div>
              <Row className="p-1">
                <div
                  style={{
                    padding: "3rem",
                    borderRadius: "8px",
                    background: "#fff",
                    border: "1px solid #DDDDDD",
                    width: "100%",
                  }}
                >
                  <Row>
                    {fileList?.map((item) => (
                      <Col span={8} key={item.id}>
                        <div
                          className="pointer"
                          style={{ color: "#2E77D0", fontWeight: "500" }}
                          onClick={() => openFile(item.url)}
                        >
                          {item.name}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Row>
              <hr className="divider" />
              {userInfo.roles.includes("ROLE_HOADMIN") &&
              clientOnbaord?.Status === "Pending" ? (
                <div className="flex-end mt-1">
                  <Button
                    className="cancel-btn ml-1 w-15"
                    onClick={() => setRejectModalVisible(true)}
                  >
                    REJECT
                  </Button>
                  <Button
                    onClick={approveClient}
                    className="save-btn w-15 ml-1"
                    // onClick={handleStepsChange}
                  >
                    APPROVE
                  </Button>
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
          <Col span={3} />
        </Row>
      ) : (
        <Form
          className="global-form master-main-container m-1 mt-2"
          layout="vertical"
          form={form}
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
          initialValues={fovFormInitialValues}
          style={{ height: "70vh", overflow: "scroll" }}
        >
          <Row style={{ padding: "1rem 1rem 0.5rem 1rem" }}>
            <Col xs={1} sm={1} md={2} lg={3} xl={3}></Col>
            <Col xs={22} sm={22} md={20} lg={18} xl={18}>
              <Row gutter={40} className="global-form-row mt-1">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="fov" label="FOV">
                    <Input placeholder="Value INR" disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="insurance" label="Insurance">
                    <Input placeholder="Value INR" disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="handlingCharges" label="Handling charges">
                    <Input placeholder="Value INR" disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="premiumServiceCharges"
                    label="Premium service charges"
                  >
                    <Input placeholder="Value INR" disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    name="fuelServiceCharges"
                    label="Fuel service charges"
                  >
                    <Input placeholder="Value INR" disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item name="codCharges" label="COD charges">
                    <Input placeholder="Value INR" disabled />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <hr className="divider" />
                </Col>
              </Row>
            </Col>
            <Col xs={1} sm={1} md={2} lg={3} xl={3}></Col>
          </Row>
          <div style={{ marginTop: "5px" }}>
            <RateCardTable
              standardDocsData={standardDox}
              standardNonDocsData={standardNonDox}
              fastTrackSurfaceData={fastTrackSurface}
              fastTrackAirData={fastTrackAir}
              fastTrackNonDoxData={fastTrackNonDox}
              valuePlusDoxData={valuePlusDox}
              valuePlusNonDoxData={valuePlusNonDox}
              templateType={"view"}
            />
          </div>

          <div className="d-flex flex-end p-1">
            <Button
              className="cancel-btn ml-1"
              onClick={() => setViewRateCard(false)}
            >
              BACK
            </Button>
          </div>
        </Form>
      )}
      {/* //--// */}
      <Modal
        className="pincode-mapping-modal"
        title={"Pincodes"}
        open={pincodeModalVisible}
        onCancel={() => setPincodeModalVisible(false)}
        footer={null}
        maskClosable={false}
        destroyOnClose
      >
        <Row className="pincode-mapping-modal-container" gutter={37}>
          {modalData?.map((data, idx) => (
            <Col key={idx} className="pincode-data">
              {data}
            </Col>
          ))}
        </Row>
      </Modal>
      <Modal
        open={rejectModalVisible}
        title={"Decline Agreements"}
        footer={null}
        onCancel={() => setRejectModalVisible(false)}
        width={450}
        destroyOnClose
        // maskClosable={false}
      >
        <Form form={form} className="global-form" layout="vertical">
          <Row className="global-form-row">
            <Col span={24}>
              <Form.Item
                name="reason"
                label="Reason for Decline"
                rules={[
                  {
                    required: true,
                    message: "Please enter reason",
                  },
                ]}
              >
                <TextArea rows={4} maxLength={30} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="global-form-row flex-end">
            <Button className="btn-style save-btn ml-1" onClick={rejectClient}>
              SUBMIT
            </Button>
          </Row>
        </Form>
      </Modal>
    </Spin>
  );
};
export default ViewClientDetails;
