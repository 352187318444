/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Space, Input, Tooltip, Spin, Popconfirm, message } from "antd";

import { useNavigate } from "react-router-dom";

import TableContainer from "../../../../components/TableContainer";
import { capitalize, serialNum } from "../../../../utils";
import { QuestionCircleOutlined } from "@ant-design/icons";

import { ReactComponent as Edit_icon } from "../../../../assests/images/edit-logo.svg";
import { ReactComponent as Disable_Edit_icon } from "../../../../assests/images/SVG/disableEdit.svg";

import { ReactComponent as Disable_icon } from "../../../../assests/images/SVG/disable.svg";
import { ReactComponent as DisableGrey_icon } from "../../../../assests/images/SVG/disableGrey.svg";
import { ReactComponent as EyeIcon } from "../../../../assests/images/eyeIcon.svg";
import { ReactComponent as DisableEyeIcon } from "../../../../assests/images/disbaleEyeIcon.svg";
import Smcs_Loader from "../../../../assests/images/loader-new.gif";
import { ReactComponent as Reason } from "../../../../assests/images/reason.svg";

// import "./style.scss";

import API from "../../../../api";

import urls from "../../../../api/urls";
import Heading from "../../../../components/Heading";
import axios from "axios";
import { APIs } from "../../../../utils/worker";

const { GET_CLIENT_TABLE, DISABLE_CLIENT, GET_CLIENT_TABLE_HO } = urls;

const ViewClientTable = () => {
  const navigate = useNavigate();
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [tableData, setTableData] = useState([]);
  const [dataSource, setdataSource] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [originalTotal, setOriginalTotal] = useState();
  const userId = JSON.parse(localStorage.getItem("userId"));
  const userinfo = JSON.parse(localStorage.getItem("userinfo"));

  useEffect(() => {
    getTableData();
  }, []);

  // useEffect(() => {
  //   if (tableData) setPageSize(tableData?.length);
  // }, [tableData]);

  const getTableData = async (p1 = 10, p2 = 0) => {
    if (userinfo.roles.includes("ROLE_HOADMIN")) {
      let response = await API.Api(
        GET_CLIENT_TABLE_HO + `?pageSize=${p1}&pageNum=${p2}`
      ).get();

      if (response.status) {
        setTotal(response?.response?.data[0]?.count);
        setOriginalTotal(response?.response?.data[0]?.count);
        setTableData(response.response.data);
        setdataSource(response.response.data);
      }
    } else {
      let response = await API.Api(
        GET_CLIENT_TABLE + `?pageSize=${p1}&pageNum=${p2}`
      ).getCustomHeader({
        cpId: userId?.userInfo?.premiseId,
      });
      if (response.status) {
        setTotal(response?.response?.data[0]?.count);
        setOriginalTotal(response?.response?.data[0]?.count);
        setTableData(response.response.data);
        setdataSource(response.response.data);
      }
    }
  };
  const handleEdit = (e) => {
    localStorage.setItem("clientId", e);
    navigate("/client-onboarding");
  };
  const handleDisable = async (value) => {
    setLoading(true);
    let response = await API.Api(DISABLE_CLIENT + value).putCustomHeader(null, {
      cpId: userId?.userInfo?.premiseId,
    });
    if (response.response.status === 200) {
      message.success(
        response.response.data.message || "Client disabled successfully"
      );
      getTableData();
      setLoading(false);
    }
    if (response.response.status === 400) {
      setLoading(false);

      message.error(response.response.data.message || "Network error");
    }
  };
  const ViewClientTable = async (id) => {
    navigate("/view-client/" + id);
  };

  const columns = [
    {
      title: "Sl No",
      dataIndex: "key",
      key: "key",
      align: "center",
      width: 50,
      render: (a, b, idx) => serialNum(pageSize, pageNum, idx),
    },
    {
      title: "Client name",
      dataIndex: "clientName",
      key: "clientName",
      //align: "center",
      render: (text) => {
        return (
          (
            <span style={{ textTransform: "capitalize" }}>
              {capitalize(text)}
            </span>
          ) || "N/A"
        );
      },
    },
    {
      title: "Email",
      dataIndex: "emailId",
      key: "emailId",
      width: 100,
    },
    {
      title: "Phone",
      dataIndex: "primaryNum",
      key: "primaryNum",
      width: 100,
    },
    {
      title: "Role",
      dataIndex: "designation",
      key: "designation",
      width: 100,

      render: (text) => {
        return capitalize(text) || "--";
      },
    },
    {
      title: "Date of joining",
      dataIndex: "createDate",
      key: "createDate",
      width: 100,
      render: (text) => {
        return text?.split(" ")[0]?.split("-").reverse().join("/");
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (text, item) => {
        return (
          <>
            {text === "Approved" && item.enable && (
              <p
                style={{
                  background: "#72CB48",
                  borderRadius: "5px",
                  padding: "5px 0",
                  textAlign: "center",
                  color: "white",
                  marginBottom: 0,
                  maxWidth: "90px",
                }}
              >
                {text}
              </p>
            )}
            {text === "Rejected" && item.enable && (
              <Tooltip
                title={item?.reason}
                placement="bottom"
                className="pointer"
              >
                <p
                  style={{
                    background: "rgb(243 74 58)",
                    borderRadius: "5px",
                    padding: "5px 0",
                    textAlign: "center",
                    color: "white",
                    marginBottom: 0,
                    maxWidth: "90px",
                  }}
                >
                  {text}
                </p>
              </Tooltip>
            )}
            {text === "Pending" && item.enable && (
              <p
                style={{
                  background: "#F2BE23",
                  borderRadius: "5px",
                  padding: "5px 0",
                  textAlign: "center",
                  color: "white",
                  marginBottom: 0,
                  maxWidth: "90px",
                }}
              >
                {text}
              </p>
            )}
            {text === "Inprogress" && item.enable && (
              <p
                style={{
                  background: "#0CB0DC",
                  borderRadius: "5px",
                  padding: "5px 0",
                  textAlign: "center",
                  color: "white",
                  marginBottom: 0,
                  maxWidth: "90px",
                }}
              >
                {"In Progress"}
              </p>
            )}
            {!item.enable && <p>{"---"}</p>}
          </>
        );
      },
    },
    {
      title: "Actions",
      key: "action",
      align: "center",
      className: "actions-col",
      width: 150,

      render: (_, record, idx) => {
        return (
          <Space>
            <Tooltip title={"Edit"} placement="bottom">
              <p
                className="pointer "
                style={{ marginBottom: 0 }}
                onClick={
                  record.enable &&
                  !userinfo.roles.includes("ROLE_HOADMIN") &&
                  (record?.status === "Pending" ||
                    record?.status === "Inprogress" ||
                    record?.status === "Rejected")
                    ? () => handleEdit(record?.clientId)
                    : ""
                }
              >
                {record.enable &&
                !userinfo.roles.includes("ROLE_HOADMIN") &&
                (record?.status === "Pending" ||
                  record?.status === "Inprogress" ||
                  record?.status === "Rejected") ? (
                  <Edit_icon />
                ) : (
                  <p style={{ cursor: "not-allowed", marginBottom: 0 }}>
                    <Disable_Edit_icon />
                  </p>
                )}
              </p>
            </Tooltip>
            <Tooltip title="Disable" placement="bottom">
              {!userinfo.roles.includes("ROLE_HOADMIN") && record.enable ? (
                <Popconfirm
                  title={
                    <span
                      className="test"
                      style={{
                        textTransform: "initial",
                      }}
                    >
                      Do you want to disable this Client ?
                    </span>
                  }
                  cancelText="No"
                  okText="Yes"
                  icon={
                    <QuestionCircleOutlined
                      style={{
                        color: "red",
                      }}
                    />
                  }
                  onConfirm={() => handleDisable(record?.clientId)}
                >
                  <p className="pointer" style={{ marginBottom: 0 }}>
                    <Disable_icon />
                  </p>
                </Popconfirm>
              ) : (
                <p className="pointer" style={{ marginBottom: 0 }}>
                  <DisableGrey_icon />
                </p>
              )}
            </Tooltip>
            {record.enable ? (
              <Tooltip title="View" placement="bottom">
                <p
                  className="pointer"
                  style={{ marginBottom: 0 }}
                  onClick={() => ViewClientTable(record?.clientId)}
                >
                  <EyeIcon />
                </p>
              </Tooltip>
            ) : (
              <p
                // className="pointer"
                style={{ marginBottom: 0 }}
                // onClick={() => ViewClientTable(record?.clientId)}
              >
                <DisableEyeIcon />
              </p>
            )}
          </Space>
        );
      },
    },
  ];

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return (
      <div>
        <p>{originalElement} </p>
      </div>
    );
  };

  const onSearch = async (e) => {
    setPageNum(0);
    let cpId = {};
    if (userinfo.roles.includes("ROLE_CPADMIN")) {
      cpId = { cpId: userId?.userInfo?.premiseId };
    }

    if (e.target.value.length > 2) {
      let response = await API.Api(
        `${
          APIs.baseURL
        }/client-onboard/v1/client/search/${e.target.value.toLowerCase()}`
      ).getCustomHeader({ ...cpId });
      if (response.status) {
        setTableData(response.response.data);
        setTotal(response.response.data.length);
      }
    } else {
      setTableData(dataSource);
      setTotal(originalTotal);
    }
  };

  const filterArray = (dataSource, currValue) =>
    dataSource?.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k]).toLowerCase().includes(currValue.toLowerCase())
      )
    );

  const handlePageChange = (pagination) => {
    // console.log(pagination.pageSize, pagination.current);
    setPageSize(pagination.pageSize);
    setPageNum(pagination.current);
    getTableData(pagination.pageSize, pagination.current - 1);
  };

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      {" "}
      <div style={{ margin: "42px  22px -28px 17px" }}>
        <Heading title="Client List" />
      </div>
      <div className="master-main-container m-1 mt-3 ">
        <div className="align-center space-between p-1">
          <h3>
            <b></b>
          </h3>
          {/* <div className="pl-1-5 fw-600">Client List</div> */}
          <div className="text-right">
            <Input
              placeholder="Search by client name, email"
              bordered
              onChange={onSearch}
              style={{ borderRadius: "5px", height: "40px", width: "400px" }}
              // suffix={<img src={search_icon} />}
            />
          </div>
        </div>
        <TableContainer
          columns={columns}
          // dataSource={inputSearchValue.length == 0 ? tableData : dataSource}
          dataSource={tableData}
          pagination={{
            pageSize: pageSize,
            total: total,
            itemRender: itemRender,
            current: pageNum == 0 ? +1 : pageNum,
          }}
          onChange={handlePageChange}
          rowClassName={(record) => !record.enable && "disabled-row"}
        />
      </div>
    </Spin>
  );
};

export default ViewClientTable;
