/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, useEffect, useRef } from "react";

import { Button, Row, Col, Input, Spin, message, Form, Empty } from "antd";
import { useNavigate } from "react-router-dom";

import {
  capitaliseWithHyphen,
  checkNumbervalue,
  checkSpace,
  checkSpecialCharacter,
} from "../../../utils";
import Heading from "../../../components/Heading";

import packetIcon from "../../../assests/images/packetIcon.svg";
import HubIcon from "../../../assests/images/HubIcon.svg";
import weightIcon from "../../../assests/images/weightLogo.svg";
import countIcon from "../../../assests/images/countLogo.svg";
import guideIcon from "../../../assests/images/guide.svg";
import checkIcon from "../../../assests/images/SVG/check.svg";
import crossIcon from "../../../assests/images/redCross.svg";
import packetContents from "../../../assests/images/packetContents.svg";
import shipmentTruck from "../../../assests/images/SVG/packetSurface.svg";
import planeIcon from "../../../assests/images/planeicon.svg";

import noPacketsIcon from "../../../assests/images/SVG/nopackets.svg";
import bagging from "../../../assests/images/SVG/bag-white.svg";
import Smcs_Loader from "../../../assests/images/loader-new.gif";

import "./style.scss";

import urls from "../../../api/urls";
import API from "../../../api";

const { GET_HUB_PACKETS, CREATE_PACKETS, UPDATE_HUB_PACKET } = urls;

const HubPacketing = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const shipmentRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [manifestFor, setManifestFor] = useState("");
  const [scannedIndex, setScannedIndex] = useState();
  const [enableInput, setenableInput] = useState(true);
  const [currentScanNum, setcurrentScanNum] = useState([]);
  const [scannedNumbers, setScannedNumbers] = useState([]);

  const [allPackets, setAllPackets] = useState([]);
  const premiseId = JSON.parse(localStorage.getItem("userId"));

  useEffect(() => {
    getTableData();
  }, []);

  const getTableData = async () => {
    setLoading(true);
    let response = await API.Api(GET_HUB_PACKETS).getWithUserNPremiseHeader();
    if (response.status) {
      setLoading(false);
      let data = response?.response?.data;
      setAllPackets(data);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network error");
    }
  };

  const shipmentIdMap =
    allPackets &&
    allPackets &&
    allPackets["createPacketDtos"]?.map((val) => val?.shipmentsAdded);

  const activepacket = shipmentIdMap?.twoDIndexOf(parseInt(currentScanNum));

  const findCurrentCard =
    allPackets &&
    allPackets &&
    allPackets["createPacketDtos"]?.find((val, idx) => {
      return idx === scannedIndex;
    });

  const filterCurrentAwbs = findCurrentCard?.shipmentsAdded;

  useEffect(() => {
    let checkScanNum =
      filterCurrentAwbs && filterCurrentAwbs?.includes(currentScanNum);

    if (checkScanNum) {
      if (scannedNumbers.includes(currentScanNum)) {
        return message.error(currentScanNum + " barcode already scanned");
      } else {
        setScannedNumbers((oldArray) => [
          ...oldArray,
          parseInt(currentScanNum),
        ]);
        message.success(`${currentScanNum} barcode scanned successfully`);
      }
    } else {
      if (scannedIndex !== undefined) {
        message.error(currentScanNum + " barcode not found in current packet");
      }
    }
    if (scannedIndex === undefined && activepacket !== -1) {
      setScannedIndex(activepacket && activepacket[0]);
      setScannedNumbers((oldArray) => [...oldArray, parseInt(currentScanNum)]);
    }
  }, [currentScanNum]);

  useEffect(() => {
    if (currentScanNum.length === 0) {
      setenableInput(false);
    } else {
      setenableInput(true);
    }
  }, [scannedNumbers]);

  const filterTwoArrays = (arr1, arr2) => {
    return arr1?.filter((item) => !arr2.includes(item));
  };

  const unScannedAwbs = filterTwoArrays(filterCurrentAwbs, scannedNumbers);

  const handleUpdatePacket = async (params) => {
    let response = await API.Api(
      UPDATE_HUB_PACKET + params?.packetBarcode
    ).putIdHeader({
      status: "FINALISED",
    });
    if (response.status) {
      setLoading(false);
      getTableData();
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network error");
    }
  };

  const handlecreatePackets = async (payload) => {
    setLoading(true);

    let response = await API.Api(CREATE_PACKETS).postIdHeader(payload);
    if (response.status) {
      setLoading(false);
      setScannedNumbers([]);
      setcurrentScanNum([]);
      setScannedIndex(undefined);
      form.resetFields();

      shipmentRef.current.input.value = "";
      let res = response?.response?.data;
      message.success(`Packet with Id ${res?.packetBarcode} created`);
      handleUpdatePacket(res);
    } else {
      setLoading(false);
      message.error(response?.response?.data?.message || "Network error");
    }
  };

  function onFinish() {
    const createPacketPayload = {
      packetBarcode: findCurrentCard && findCurrentCard?.packetBarcode,
      service: findCurrentCard && findCurrentCard?.service,
      travelBy: findCurrentCard?.travelBy,
      toPremiseId: findCurrentCard?.toPremise,
      fromPremiseId: premiseId?.userInfo?.premiseId,
      packetShipments: scannedNumbers
        ?.filter((val) => val === val)
        ?.map((val) => {
          if (val !== null) {
            return { awbNo: val };
          }
        }),
    };

    handlecreatePackets(createPacketPayload);
    allPackets?.length === 0;
  }

  Array.prototype.twoDIndexOf = function (element) {
    if (this === null || this === undefined)
      throw TypeError("Array.prototype.indexOf called on null or undefined");
    for (let i = 0; i < this.length; i++) {
      const curr = this[i];
      if (curr?.includes(element)) return [i];
    }
    return -1;
  };

  let barcode = "";
  let interval;
  const handleKeyDown = (event) => {
    if (interval) clearInterval(interval);
    if (event.code == "Enter") {
      if (barcode) handleBarcode(barcode);
      barcode = "";
      return;
    }
    if (event.key != "Shift") barcode += event.key;
    interval = setInterval(() => (barcode = ""), 20);
  };
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleBarcode = (scanned_barcode) => {
    const resultNumber = scanned_barcode?.replace(/[a-zA-Z]/g, "");
    setcurrentScanNum(+resultNumber);
    return +scanned_barcode;
  };

  const handleNext = () => {
    navigate("/dashboard");
  };

  return (
    <Spin
      spinning={loading}
      indicator={
        <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
      }
    >
      <div className="middle-mile-packet">
        <Heading
          title="Hub Packeting"
          nextPage={"/shipment-bagging"}
          nextPageTitle={"Bagging"}
          imgSrc={bagging}
        />
        <div>
          <div className="packet-container">
            <div className="content-header">
              <Row>
                <Col lg={19}>All packets</Col>

                <Col lg={5} align="end">
                  Total packet count:
                  <span>
                    {(allPackets && allPackets["createPacketDtos"]?.length) ||
                      0}
                  </span>
                </Col>
                <hr />
              </Row>
            </div>
            <div className="content-body">
              <Row align="center">
                {!allPackets?.createPacketDtos ||
                  (allPackets?.createPacketDtos?.length == 0 && (
                    <p className={"no-packets"}>
                      <>
                        <Row>
                          <img
                            src={noPacketsIcon}
                            style={{ margin: "30px auto" }}
                          />
                        </Row>
                        <Row>{"No eligible packets to be packeted"}</Row>
                      </>
                    </p>
                  ))}

                {allPackets &&
                  allPackets["createPacketDtos"]?.map((val, idx) => {
                    return (
                      <>
                        <Form onFinish={onFinish}>
                          <Col
                            lg={8}
                            className={
                              idx === scannedIndex
                                ? "active-cards cards"
                                : "cards"
                            }
                          >
                            <Row>
                              <Col span={14}>
                                <Row className="row-one">
                                  {" "}
                                  <img src={packetIcon} /> Packet Id
                                </Row>
                                <Row className="row-two">
                                  <Form.Item
                                    // label="Id"
                                    name={"PId"}
                                    rules={[
                                      {
                                        required: false,
                                        message: "Id is required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      disabled
                                      // ref={packetRef}
                                      defaultValue={val?.packetBarcode}
                                      value={val?.packetBarcode}
                                    />
                                  </Form.Item>
                                </Row>
                              </Col>
                              <Col span={8}>
                                <Row className="row-one">
                                  <Col span={23}>
                                    <img src={HubIcon} /> Hub
                                  </Col>
                                  <Col span={1}>
                                    {manifestFor === "air" ? (
                                      <img
                                        src={planeIcon}
                                        style={{ width: "25px" }}
                                      />
                                    ) : (
                                      <img
                                        src={shipmentTruck}
                                        style={{ width: "25px" }}
                                      />
                                    )}
                                  </Col>
                                </Row>
                                <Row className="row-two">
                                  {capitaliseWithHyphen(
                                    allPackets?.parentHubName
                                  )}
                                </Row>
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: "16px" }}>
                              <Col span={8}>
                                <Row className="row-one">
                                  {" "}
                                  <img src={weightIcon} /> Weight
                                </Row>
                                <Row className="row-two">
                                  {val?.weight?.toFixed(2)}kg
                                </Row>
                              </Col>
                              <Col span={6}>
                                <Row className="row-one">
                                  {" "}
                                  <img src={countIcon} /> Count
                                </Row>
                                <Row className="row-two">
                                  {val?.shipmentsAdded?.length}
                                </Row>
                              </Col>
                              <Col span={10}>
                                <Row className="row-one guide-name">
                                  {" "}
                                  <img src={guideIcon} />
                                  <span style={{ width: "70%" }}>
                                    Packeting guide name
                                  </span>
                                </Row>
                                <Row className="row-two">
                                  {capitaliseWithHyphen(
                                    val?.packetingGuideName
                                  )}
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Input
                                placeholder="Enter AWB number to scan"
                                style={{ width: "81%", margin: "10px auto" }}
                                disabled={idx !== scannedIndex && enableInput}
                                ref={shipmentRef}
                                maxLength={14}
                                onKeyPress={(event) => {
                                  if (
                                    checkNumbervalue(event) ||
                                    checkSpace(event) ||
                                    checkSpecialCharacter(event)
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                onPressEnter={(e) => {
                                  if (e.target.value.length > 9) {
                                    handleBarcode(e.target.value);
                                  } else {
                                    message.error("Enter correct AWB number");
                                  }
                                }}
                              />
                            </Row>

                            <Row className="row-one">
                              <Col span={12}>
                                {" "}
                                <img src={packetContents} /> Packet contents
                              </Col>
                            </Row>
                            <Row className="content-items">
                              <Col span={2}></Col>
                              <Col span={22}>
                                <Row className="content-table-header">
                                  <Col span={15} className="awb-col">
                                    AWB No.
                                  </Col>
                                  <Col span={9}>Destination</Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row className="awb-destiny-row">
                              <Col span={2}></Col>
                              <Col className="awb-col-items" span={22}>
                                {val?.shipmentsAdded?.map((awbNum, index) => {
                                  return (
                                    <Row
                                      className="space-between"
                                      key={index}
                                      style={{ marginBottom: "14px" }}
                                    >
                                      <Col key={index} span={14}>
                                        {awbNum}
                                        {scannedNumbers?.includes(awbNum) ? (
                                          <img src={checkIcon} />
                                        ) : (
                                          <img src={crossIcon} />
                                        )}
                                      </Col>
                                      <Col key={index} span={10}>
                                        {capitaliseWithHyphen(val?.premiseName)}
                                      </Col>
                                    </Row>
                                  );
                                })}
                              </Col>
                            </Row>
                            <div className="close">
                              {idx === scannedIndex ? (
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  disabled={unScannedAwbs?.length !== 0}
                                >
                                  READY TO CLOSE
                                </Button>
                              ) : null}
                            </div>
                          </Col>
                        </Form>
                      </>
                    );
                  })}
              </Row>
              <Row className="submit">
                <Button
                  type="primary"
                  disabled={allPackets?.createPacketDtos?.length !== 0}
                  onClick={handleNext}
                >
                  DONE
                </Button>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default HubPacketing;
