/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Row, Col, Input, Button, Form, message, Radio } from "antd";
import RateCardTable from "../../../../../components/Master/RateCard/RateCardTable";
import { cashBookingRateCardData } from "../../../../Master/RateCard/CashbookingRateCard/cashBookingRateCardData";
import { defaultRateCard } from "../../../../Master/RateCard/CashbookingRateCard/defaultRateCard";
import API from "../../../../../api";
import urls from "../../../../../api/urls";
import rate_card_back from "../../../../../assests/images/onbording/rate-card-back.svg";
const { ADD_CLIENT_RATE_CARD, GET_CLIENT_RATE_CARD } = urls;
import {
  fovFormInitialValues,
  fovChargeRule,
} from "../../../../LeadGenerationCP/AllocateRateCard/rateCardUtils";

export const AddRateCard = ({
  handleStepsChange = () => {},
  sideStepsHide = () => {},
  sideStepsShow = () => {},
  goBackStep = () => {},
  rateCardPresent,
  clientName,
}) => {
  const [form] = Form.useForm();
  const [showAddRateCard, setShowAddRateCard] = useState(false);
  const [standardDox, setStandardDox] = useState([]);
  const [standardNonDox, setStandardNonDox] = useState([]);
  const [fastTrackSurface, setFastTrackSurface] = useState([]);
  const [fastTrackAir, setFastTrackAir] = useState([]);
  const [fastTrackNonDox, setFastTrackNonDox] = useState([]);
  const [valuePlusDox, setValuePlusDox] = useState([]);
  const [valuePlusNonDox, setValuePlusNonDox] = useState([]);
  const [rateAdded, setRateAdded] = useState(false);
  const [rateDisable, setRateDisable] = useState(false);
  let clientId = localStorage.getItem("clientId");

  const initialize = async () => {
    let { response } = await API.Api(
      GET_CLIENT_RATE_CARD + clientId
    ).getWithHeader();
    let data = response?.data;
    if (data) {
      setRateDisable(true);
      form.setFieldsValue({
        fov: data?.fov ? parseFloat(data.fov).toFixed(2) : "0.00",
        insurance: data?.insurance
          ? parseFloat(data.insurance).toFixed(2)
          : "0.00",
        handlingCharges: data?.handlingCharges
          ? parseFloat(data.handlingCharges).toFixed(2)
          : "0.00",
        premiumServiceCharges: data?.premiumServiceCharges
          ? parseFloat(data.premiumServiceCharges).toFixed(2)
          : "0.00",
        fuelServiceCharges: data?.fuelServiceCharges
          ? parseFloat(data.fuelServiceCharges).toFixed(2)
          : "0.00",
        codCharges: data?.codCharges
          ? parseFloat(data.codCharges).toFixed(2)
          : "0.00",
      });
      let temp = data?.services;
      let standardTemp = [];
      let fastTrackTemp = [];
      let valuePlusTemp = [];

      if (temp) {
        temp.map((e) => {
          switch (e.serviceType) {
            case "STANDARD":
              {
                standardTemp.push(e.docs);
              }
              break;
            case "FAST TRACK":
              {
                fastTrackTemp.push(e.docs);
              }
              break;
            case "VALUE PLUS":
              {
                valuePlusTemp.push(e.docs);
              }
              break;
          }
        });
      }
      if (standardTemp) {
        standardTemp.map((item) => {
          item.map((e) => {
            switch (e.docType) {
              case "DOX": {
                let standardDoxTemp = [];
                e.transports.map((item) => {
                  switch (item.mode) {
                    case "Surface": {
                      item.rates.map((rateValue, index) => {
                        standardDoxTemp.push({
                          title:
                            index === item.rates.length - 1
                              ? `Add ${rateValue.rateMode.split("Slab ")[1]}`
                              : `up to ${rateValue.rateMode}`,
                          design: index === item.rates.length - 1 ? true : null,
                          value: Object.entries(
                            item.rates[index]?.rateTypes
                          ).map(([title, value]) => ({
                            title,
                            value: parseFloat(value).toFixed(2),
                          })),
                        });
                      });
                    }
                  }
                });
                setStandardDox(standardDoxTemp);
                break;
              }

              case "NON_DOX":
                {
                  let standardNonDoxTemp = [];
                  e.transports.map((item) => {
                    switch (item.mode) {
                      case "Surface": {
                        standardNonDoxTemp.push({
                          title: "By Surface (Per Kg)",
                          design: null,
                          value: Object.entries(item.rates[0]?.rateTypes).map(
                            ([title, value]) => ({ title, value })
                          ),
                        });
                        break;
                      }
                      case "Air": {
                        item.rates.map((rateItem, index) => {
                          standardNonDoxTemp.push({
                            title:
                              index === item.rates.length - 1
                                ? `Add ${rateItem.rateMode.split("Slab ")[1]}`
                                : "By Air (Per Kg)",
                            design:
                              index === item.rates.length - 1 ? true : null,
                            value: Object.entries(
                              item.rates[index]?.rateTypes
                            ).map(([title, value]) => ({ title, value })),
                          });
                        });
                        break;
                      }
                    }
                  });

                  setStandardNonDox(standardNonDoxTemp);
                }
                break;
            }
          });
        });
      }
      if (fastTrackTemp) {
        fastTrackTemp.map((item) => {
          item.map((e) => {
            switch (e.docType) {
              case "DOX": {
                let fastTrackDoxSurfaceTemp = [];
                let fastTrackDoxAirTemp = [];
                e.transports.map((item) => {
                  switch (item.mode) {
                    case "Surface": {
                      item.rates.map((rateValue, index) => {
                        fastTrackDoxSurfaceTemp.push({
                          title:
                            index === item.rates.length - 1
                              ? `Add ${rateValue.rateMode.split("Slab ")[1]}`
                              : `up to ${rateValue.rateMode}`,
                          design: index === item.rates.length - 1 ? true : null,
                          value: Object.entries(
                            item.rates[index]?.rateTypes
                          ).map(([title, value]) => ({ title, value })),
                        });
                      });
                      break;
                    }
                    case "Air": {
                      item.rates.map((rateValue, index) => {
                        fastTrackDoxAirTemp.push({
                          title:
                            index === item.rates.length - 1
                              ? `Add ${rateValue.rateMode.split("Slab ")[1]}`
                              : `up to ${rateValue.rateMode}`,
                          design: index === item.rates.length - 1 ? true : null,
                          value: Object.entries(
                            item.rates[index]?.rateTypes
                          ).map(([title, value]) => ({ title, value })),
                        });
                      });
                    }
                  }
                });
                setFastTrackSurface(fastTrackDoxSurfaceTemp);
                setFastTrackAir(fastTrackDoxAirTemp);
                break;
              }

              case "NON_DOX":
                {
                  let fastTrackNonDoxTemp = [];
                  e.transports.map((item) => {
                    switch (item.mode) {
                      case "Surface": {
                        fastTrackNonDoxTemp.push({
                          title: "By Surface (Per Kg)",
                          design: null,
                          value: Object.entries(item.rates[0]?.rateTypes).map(
                            ([title, value]) => ({ title, value })
                          ),
                        });
                        break;
                      }
                      case "Air": {
                        item.rates.map((rateItem, index) => {
                          fastTrackNonDoxTemp.push({
                            title:
                              index === item.rates.length - 1
                                ? `Add ${rateItem.rateMode.split("Slab ")[1]}`
                                : "By Air (Per Kg)",
                            design:
                              index === item.rates.length - 1 ? true : null,
                            value: Object.entries(
                              item.rates[index]?.rateTypes
                            ).map(([title, value]) => ({ title, value })),
                          });
                        });
                        break;
                      }
                    }
                  });
                  setFastTrackNonDox(fastTrackNonDoxTemp);
                }
                break;
            }
          });
        });
      }
      if (valuePlusTemp) {
        valuePlusTemp.map((item) => {
          item.map((e) => {
            switch (e.docType) {
              case "DOX": {
                let valuePlusDoxTemp = [];
                e.transports.map((item) => {
                  switch (item.mode) {
                    case "Surface": {
                      item.rates.map((rateValue, index) => {
                        valuePlusDoxTemp.push({
                          title:
                            index === item.rates.length - 1
                              ? `Add ${rateValue.rateMode.split("Slab ")[1]}`
                              : `up to ${rateValue.rateMode}`,
                          design: index === item.rates.length - 1 ? true : null,
                          value: Object.entries(
                            item.rates[index]?.rateTypes
                          ).map(([title, value]) => ({ title, value })),
                        });
                      });
                    }
                  }
                });
                setValuePlusDox(valuePlusDoxTemp);
                break;
              }

              case "NON_DOX":
                {
                  let valuePlusNonDoxTemp = [];
                  e.transports.map((item) => {
                    switch (item.mode) {
                      case "Surface": {
                        valuePlusNonDoxTemp.push({
                          title: "By Surface (Per Kg)",
                          design: null,
                          value: Object.entries(item.rates[0]?.rateTypes).map(
                            ([title, value]) => ({ title, value })
                          ),
                        });
                        break;
                      }
                      case "Air": {
                        item.rates.map((rateItem, index) => {
                          valuePlusNonDoxTemp.push({
                            title:
                              index === item.rates.length - 1
                                ? `Add ${rateItem.rateMode.split("Slab ")[1]}`
                                : "By Air (Per Kg)",
                            design:
                              index === item.rates.length - 1 ? true : null,
                            value: Object.entries(
                              item.rates[index]?.rateTypes
                            ).map(([title, value]) => ({ title, value })),
                          });
                        });
                        break;
                      }
                    }
                  });
                  setValuePlusNonDox(valuePlusNonDoxTemp);
                }
                break;
            }
          });
        });
      }
    }
  };
  let {
    standardDoxDatas,
    fastTrackSurfaceDatas,
    standardNonDoxDatas,
    fastTrackAirDatas,
    fastTrackNonDoxDatas,
    valuePlusDoxDatas,
    valuePlusNonDoxDatas,
  } = cashBookingRateCardData();

  useEffect(() => {
    setStandardDox(standardDoxDatas);
    setStandardNonDox(standardNonDoxDatas);
    setFastTrackSurface(fastTrackSurfaceDatas);
    setFastTrackAir(fastTrackAirDatas);
    setFastTrackNonDox(fastTrackNonDoxDatas);
    setValuePlusDox(valuePlusDoxDatas);
    setValuePlusNonDox(valuePlusNonDoxDatas);
  }, []);

  let {
    standardDoxDefaultDatas,
    fastTrackSurfaceDefaultDatas,
    standardNonDoxDefaultDatas,
    fastTrackAirDefaultDatas,
    fastTrackNonDoxDefaultDatas,
    valuePlusDoxDefaultDatas,
    valuePlusNonDoxDefaultDatas,
  } = defaultRateCard();

  const setWeightSlabData = (data, title) => {
    switch (title) {
      case "standardDocs":
        setStandardDox(data);
        break;
      case "standardNonDocs":
        setStandardNonDox(data);
        break;
      case "fastTrackSurface":
        setFastTrackSurface(data);
        break;
      case "fastTrackAir":
        setFastTrackAir(data);
        break;
      case "fastTrackNonDox":
        setFastTrackNonDox(data);
        break;
      case "valueplusDox":
        setValuePlusDox(data);
        break;
      case "valueplusNonDox":
        setValuePlusNonDox(data);
        break;
    }
  };

  const changeDynamicSelect = (title, data) => {
    switch (title) {
      case "standardNonDocs":
        setStandardNonDox(data);
        break;
      case "fastTrackNonDox":
        setFastTrackNonDox(data);
        break;
      case "valueplusNonDox":
        setValuePlusNonDox(data);
        break;
    }
  };

  const selectTemplate = (e) => {
    form.setFieldValue("tempValue", null);
    setStandardDox(standardDoxDatas);
    setStandardNonDox(standardNonDoxDatas);
    setFastTrackSurface(fastTrackSurfaceDatas);
    setFastTrackAir(fastTrackAirDatas);
    setFastTrackNonDox(fastTrackNonDoxDatas);
    setValuePlusDox(valuePlusDoxDatas);
    setValuePlusNonDox(valuePlusNonDoxDatas);
    if (e.target.value === 2) {
      setStandardDox(standardDoxDefaultDatas);
      setStandardNonDox(standardNonDoxDefaultDatas);
      setFastTrackSurface(fastTrackSurfaceDefaultDatas);
      setFastTrackAir(fastTrackAirDefaultDatas);
      setFastTrackNonDox(fastTrackNonDoxDefaultDatas);
      setValuePlusDox(valuePlusDoxDefaultDatas);
      setValuePlusNonDox(valuePlusNonDoxDefaultDatas);
    }
  };

  const addRateCard = () => {
    sideStepsHide();
    setShowAddRateCard(true);
  };

  const showSideStep = () => {
    sideStepsShow();
    setShowAddRateCard(false);
  };

  const viewRateCard = () => {
    sideStepsHide();
    setShowAddRateCard(true);
    initialize();
  };

  const showSideStepCardAdded = () => {
    sideStepsShow();
    setShowAddRateCard(false);
    setRateAdded(true);
  };

  const getRatesDox = (data) => {
    let rateData = [];
    data.map((e) => {
      let temp = {};
      e.value.map(
        (item) =>
          (temp[item.title] = item.value
            ? parseFloat(item.value).toFixed(2)
            : "0.00")
      );
      rateData.push({
        rateMode: e.title.split(" ").includes("up")
          ? e.title.split("to ")[1]
          : `Additional Weight Slab ${e.title.split("Add ")[1]}`,
        rateTypes: temp,
      });
    });
    return rateData;
  };

  const getRatesNonDoxSurface = (data) => {
    let temp1 = {};
    let temp2 = {};
    data[0].value.map(
      (item) =>
        (temp1[item.title] = item.value
          ? parseFloat(item.value).toFixed(2)
          : "0.00")
    );
    data[2].value.map(
      (item) =>
        (temp2[item.title] = item.value
          ? parseFloat(item.value).toFixed(2)
          : "0.00")
    );
    let rateData = [
      {
        rateMode: "Weight Slab 1000 Gms",
        rateTypes: temp1,
      },
      {
        rateMode: `Additional Weight Slab ${data[2].title.split("Add ")[1]}`,
        rateTypes: temp2,
      },
    ];
    return rateData;
  };

  const getRatesNonDoxAir = (data) => {
    let temp1 = {};
    let temp2 = {};
    data[1].value.map(
      (item) =>
        (temp1[item.title] = item.value
          ? parseFloat(item.value).toFixed(2)
          : "0.00")
    );
    data[2].value.map(
      (item) =>
        (temp2[item.title] = item.value
          ? parseFloat(item.value).toFixed(2)
          : "0.00")
    );
    let rateData = [
      {
        rateMode: "Weight Slab 1000 Gms",
        rateTypes: temp1,
      },
      {
        rateMode: `Additional Weight Slab ${data[2].title.split("Add ")[1]}`,
        rateTypes: temp2,
      },
    ];
    return rateData;
  };

  const createRateCard = async (values) => {
    const standardDoxData = getRatesDox(standardDox);
    const fastTrackSurfaceData = getRatesDox(fastTrackSurface);
    const fastTrackAirData = getRatesDox(fastTrackAir);
    const valuePlusDoxData = getRatesDox(valuePlusDox);
    const standardNonDoxSurfaceData = getRatesNonDoxSurface(standardNonDox);
    const standardNonDoxAirData = getRatesNonDoxAir(standardNonDox);
    const fastTrackNonDoxSurfaceData = getRatesNonDoxSurface(fastTrackNonDox);
    const fastTrackNonDoxAirData = getRatesNonDoxAir(fastTrackNonDox);
    const valuePlusNonDoxSurfaceData = getRatesNonDoxSurface(valuePlusNonDox);
    const valuePlusNonDoxAirData = getRatesNonDoxAir(valuePlusNonDox);
    const payload = {
      ...values,
      // ratecardName:"Rate card temp 30-03-2023",
      // financialYear: "2023-2024",
      services: [
        {
          serviceType: "STANDARD",
          docs: [
            {
              docType: "DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: standardDoxData,
                },
                {
                  mode: "Air",
                  rates: standardDoxData,
                },
              ],
            },
            {
              docType: "NON_DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: standardNonDoxSurfaceData,
                },
                {
                  mode: "Air",
                  rates: standardNonDoxAirData,
                },
              ],
            },
          ],
        },
        {
          serviceType: "FAST TRACK",
          docs: [
            {
              docType: "DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: fastTrackSurfaceData,
                },
                {
                  mode: "Air",
                  rates: fastTrackAirData,
                },
              ],
            },
            {
              docType: "NON_DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: fastTrackNonDoxSurfaceData,
                },
                {
                  mode: "Air",
                  rates: fastTrackNonDoxAirData,
                },
              ],
            },
          ],
        },
        {
          serviceType: "VALUE PLUS",
          docs: [
            {
              docType: "DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: valuePlusDoxData,
                },
                {
                  mode: "Air",
                  rates: valuePlusDoxData,
                },
              ],
            },
            {
              docType: "NON_DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: valuePlusNonDoxSurfaceData,
                },
                {
                  mode: "Air",
                  rates: valuePlusNonDoxAirData,
                },
              ],
            },
          ],
        },
      ],
    };
    let response = await API.Api(ADD_CLIENT_RATE_CARD).postCustomHeader(
      payload,
      { clientId: clientId, clientname: clientName }
    );
    if (response.status) {
      message.success("Rate card added successfully.");
      showSideStepCardAdded();
    } else {
      message.error(response.response.data.message);
    }
  };

  const checkNum = (e) => {
    if (/[^0-9.]/.test(e.key)) {
      e.preventDefault();
    }
  };
  return (
    <>
      {!showAddRateCard ? (
        <div className="p-2">
          <span className="master-title">Add Rate Card</span>
          <hr className="divider" />
          {rateAdded || rateCardPresent ? (
            <div
              className="rate-card-container"
              style={{
                background: "#fff",
                border: "1px solid #DDDDDD",
                width: "100%",
              }}
            >
              <Button className="cancel-btn" onClick={viewRateCard}>
                VIEW RATE CARD
              </Button>
            </div>
          ) : (
            <div className="rate-card-container">
              <p>Click here to get started</p>
              <Button className="save-btn" onClick={addRateCard}>
                + ADD RATE CARD
              </Button>
            </div>
          )}
          <hr className="divider mt-2" />
          <div className="flex-end" style={{ paddingTop: "1rem" }}>
            <Button className="cancel-btn ml-1 w-15" onClick={goBackStep}>
              BACK
            </Button>
            <Button
              onClick={handleStepsChange}
              className={
                rateAdded || rateCardPresent
                  ? "save-btn w-15 ml-1"
                  : "btn-style w-15 ml-1"
              }
              disabled={rateAdded || rateCardPresent ? false : true}
            >
              CONTINUE
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div>
            <span
              className="master-title"
              style={{ padding: "0.8rem 1rem 0.3rem 1rem", fontSize: "16px" }}
            >
              <img
                src={rate_card_back}
                className="mr-1 pointer"
                onClick={showSideStep}
              />{" "}
              Add Rate Card
            </span>
          </div>
          <hr className="divider" />
          <Form
            className="global-form"
            layout="vertical"
            form={form}
            scrollToFirstError={{
              behavior: "smooth",
              block: "center",
              inline: "center",
            }}
            initialValues={fovFormInitialValues}
            onFinish={createRateCard}
          >
            <Row style={{ padding: "1rem 1rem 0.5rem 1rem" }}>
              <Col xs={1} sm={1} md={2} lg={3} xl={3}></Col>
              <Col xs={22} sm={22} md={20} lg={18} xl={18}>
                <Row gutter={40} className="global-form-row">
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item name="fov" label="FOV" rules={fovChargeRule}>
                      <Input
                        placeholder="Value INR"
                        onKeyPress={(e) => checkNum(e)}
                        disabled={rateDisable}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="insurance"
                      label="Insurance"
                      rules={fovChargeRule}
                    >
                      <Input
                        placeholder="Value INR"
                        onKeyPress={(e) => checkNum(e)}
                        disabled={rateDisable}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="handlingCharges"
                      label="Handling charges"
                      rules={fovChargeRule}
                    >
                      <Input
                        placeholder="Value INR"
                        onKeyPress={(e) => checkNum(e)}
                        disabled={rateDisable}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="premiumServiceCharges"
                      label="Premium service charges"
                      rules={fovChargeRule}
                    >
                      <Input
                        placeholder="Value INR"
                        onKeyPress={(e) => checkNum(e)}
                        disabled={rateDisable}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="fuelServiceCharges"
                      label="Fuel service charges"
                      rules={fovChargeRule}
                    >
                      <Input
                        placeholder="Value INR"
                        onKeyPress={(e) => checkNum(e)}
                        disabled={rateDisable}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      name="codCharges"
                      label="COD charges"
                      rules={fovChargeRule}
                    >
                      <Input
                        placeholder="Value INR"
                        onKeyPress={(e) => checkNum(e)}
                        disabled={rateDisable}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <hr className="divider" />
                  </Col>
                  {!rateDisable && (
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                      <span className="ml-1">Select Weight Slab</span>
                      <Radio.Group onChange={selectTemplate} className="p-05">
                        <Radio value={1}>
                          <span className="f-14">None</span>
                        </Radio>
                        <Radio value={2} className="ml-1">
                          <span className="f-14">Default Values</span>
                        </Radio>
                      </Radio.Group>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col xs={1} sm={1} md={2} lg={3} xl={3}></Col>
            </Row>
            <div style={{ marginTop: "5px" }}>
              <RateCardTable
                standardDocsData={standardDox}
                standardNonDocsData={standardNonDox}
                fastTrackSurfaceData={fastTrackSurface}
                fastTrackAirData={fastTrackAir}
                fastTrackNonDoxData={fastTrackNonDox}
                valuePlusDoxData={valuePlusDox}
                valuePlusNonDoxData={valuePlusNonDox}
                handleChangeDocs={(e, data) => setWeightSlabData(e, data)}
                handleDynamicSelect={(data, title) =>
                  changeDynamicSelect(data, title)
                }
                templateType={rateDisable ? "view" : "create"}
              />
            </div>

            <div className="d-flex flex-end p-1">
              <Button className="cancel-btn ml-1" onClick={showSideStep}>
                BACK
              </Button>
              {!rateDisable && (
                <Button
                  htmlType="submit"
                  className="save-btn ml-1"
                  //onClick={handleStepsChange}
                >
                  SAVE
                </Button>
              )}
            </div>
          </Form>
        </>
      )}
    </>
  );
};

export default AddRateCard;
