/* eslint-disable react/no-unknown-property */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Row,
  Form,
  Col,
  Input,
  Tooltip,
  Select,
  AutoComplete,
  Button,
  Spin,
} from "antd";
import {
  checkAlphabets,
  checkAlphabetsAndNumber,
  checkNumbervalue,
  checkSpace,
  checkSpecialCharacter,
  firstLetterCapitaliseDropDown,
  isValidDocumentNum,
} from "../../../../utils";
import useClientAndDepartment from "../../CrediteBooking/useClientAndDepartment ";
import { HeaderWithBottomLine } from "../../../Master/CpOnbordingSteps/HeaderWithBottomLine";
import AutoCompleteAddress from "../../CrediteBooking/AutoCompleteAddress";
import { ReciverAddressComponent } from "../../../../components/ReciverAddressComponent";
import { ReactComponent as AttachFile } from "../../../../assests/images/FileEarmarkPlus.svg";
import { SenderFormFields } from "./senderForm";
import API from "../../../../api";
import urls from "../../../../api/urls";
import Smcs_Loader from "../../../../assests/images/loader-new.gif";

function NewRetailForm({
  ewayDrawercallBack,
  childDrawerCallBack,
  handleSenderGST,
  alertData,
  form,
  retailLoading,
  onCESSChange,
  onFreightChange,
  allEwayBills,
  childShipments,
  reviverNameAndAddress,
  getDestinationId,
  mainBaseCharge,
  handleTravelBy = () => {},
  resetForm,
  serviceOptions,
  setRateAndGetTat = () => {},
  callMeBack,
  gstStatus,
  setGstStatus,
  setPanStatus,
  panStatus,
}) {
  const { CHECK_LOCAL } = urls;

  const { userInfo } = JSON.parse(window?.localStorage?.getItem("userId"));
  const [ewayBill, setEwayBill] = useState(false);
  const { Option } = Select;
  const [isNonDox, setIsNonDox] = useState(false);
  const userId = JSON.parse(localStorage.getItem("userId"));

  const [documentLength, setDocumentLength] = useState(14);
  const [shipmentContentOptions, setShipmentContentOptions] = useState([]);
  const [dropDownChanges, setdropDownChanges] = useState(false);
  const [number, setNumber] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      setNumber(18);
      // if (window.innerWidth < 600) {
      //   setNumber(2);
      // } else if (window.innerWidth > 1023) {
      //   setNumber(9);
      // } else if (window.innerWidth > 1200) {
      //   setNumber(9);
      // } else {
      //   695034(12);
      // }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let sezOptions = [
    {
      value: "No",
      label: "No",
    },
    { value: "Yes", label: "Yes" },
  ];

  let paymentOptions = [
    { value: "cash", label: "Cash" },
    { value: "online", label: "Online" },
  ];

  const [travelByOptions, setTravelByOptions] = useState([
    {
      value: "Surface",
      label: "Surface",
    },
    {
      value: "Air",
      label: "Air",
    },
  ]);

  const defaultTravelByOptions = [
    {
      value: "Surface",
      label: "Surface",
    },
    {
      value: "Air",
      label: "Air",
    },
  ];

  const localTravelByOptions = [
    {
      value: "Surface",
      label: "Surface",
    },
  ];

  const setTravelOptions = (isLocal) => {
    if (isLocal) {
      form.setFieldValue("travelBy", "Surface");
    }

    setTravelByOptions(isLocal ? localTravelByOptions : defaultTravelByOptions);
  };

  const getLocal = async (e) => {
    const value = e.target.value;

    if (value?.length <= 5) {
      setTravelOptions(false);
      return;
    }

    try {
      const response = await API.Api(
        CHECK_LOCAL + `${userId?.premises?.pincode}/to/${value}`
      ).getWithUserNPremiseHeader();
      if (response?.status) {
        const data = response?.response?.data;
        setTravelOptions(data === "LOCAL");
      } else {
        setTravelOptions(false);
      }
    } catch (error) {
      setTravelOptions(false);
    }
  };

  const doxandNonDoxChange = {
    content: null,
    description: null,
    weight: null,
    length: null,
    width: null,
    height: null,
    volumatricWeight: null,
    child_Selected: false,
    value: null,
  };

  const {
    pinCodeServiceableStatus,
    areaList,
    loading,
    reviverStateCity,
    // reviverNameAndAddress,
    contentDiscriptionData,
    areaDestinationPremiseId,
    getAreaDestinationPremiseId,
    getDeliveryPincode,
    getContentDiscriptionData,
    resetCredit,
  } = useClientAndDepartment(userInfo, form);

  useEffect(() => {
    callMeBack(reviverStateCity);
  }, [reviverStateCity]);

  useEffect(() => {
    if (resetForm) {
      resetCredit();
      setEwayBill(false);
      form.setFieldValue("setFieldValue", null);
    }
  }, [resetForm]);

  useEffect(() => {
    if (areaDestinationPremiseId?.areaId !== null) {
      getDestinationId(areaDestinationPremiseId, pinCodeServiceableStatus);
    }
  }, [areaDestinationPremiseId]);

  useEffect(() => {
    // getRevicerNameAndAddress("CASH");
    getContentDiscriptionData();
  }, []);

  useEffect(() => {
    if (Object.entries(contentDiscriptionData).length !== 0) {
      form.setFieldsValue({
        shipmentType: "Dox",
        // service: "standard",
        // travelBy: null,
        service: null,
      });

      handleShipmentTypeChange("_", { key: 1, value: "Dox" });
    }
  }, [contentDiscriptionData]);

  const handleShipmentTypeChange = (_, { key, value }) => {
    form.setFieldsValue({
      content: null,
    });
    const isDox = value == "Non-Dox";
    setIsNonDox(isDox);
    if (isDox) {
      form.setFieldsValue(doxandNonDoxChange);
      //   setChilldShipmentLength(0);
      form.setFieldsValue({
        value: "0",
        weight: "1",
      });
    } else {
      form.setFieldsValue({
        value: "0",
        weight: "50",
      });
    }
    let output = [];

    contentDiscriptionData?.shipmentContent?.forEach((content) => {
      let filteredDescriptions =
        contentDiscriptionData?.shipmentDescription?.filter((description) => {
          return (
            description?.shipmentContentId === content?.shipmentContentId &&
            content?.shipmentTypeId === +key
          );
        });

      filteredDescriptions?.forEach((description) => {
        let combinedName = `${content?.shipmentContentName} - ${description?.shipmentDescriptionName}`;
        output.push({
          shipmentContentId: content?.shipmentContentId,
          shipmentContentName: combinedName,
          shipmentTypeId: content?.shipmentTypeId,
        });
      });
    });

    setShipmentContentOptions(output);
  };
  const autoCompleteOptions = shipmentContentOptions?.map((option) => ({
    value: option?.shipmentContentName,
    label: option?.shipmentContentName,
  }));

  const filterOption = (input, option) =>
    (option?.children ?? "").toLowerCase().includes(input.toLowerCase());

  const volumCalculation = () => {
    const { length, width, height } = form.getFieldValue();
    const volume = ((+length * +width * +height) / 5000).toFixed(2);
    form.setFieldsValue({
      volumatricWeight: volume,
    });
    form.setFieldValue("service", null);
    form.setFieldValue("travelBy", null);
  };

  const onChangeWeight = () => {
    // setdropDownChanges(!dropDownChanges);

    // document.querySelectorAll(".ant-select-item-option").forEach((option) => {
    //   option.classList.remove("ant-select-item-option-active");
    // });

    form.setFieldValue("service", null);
    form.setFieldValue("travelBy", null);
  };

  const handleService = (e) => {
    // form.setFieldValue("travelBy", null);
    // form.setFieldsValue({ documentNumber: null });

    // setDocumentLength(e?.toLowerCase() === "standard" ? 14 : 11);

    setRateAndGetTat(e);
    if (form.getFieldValue("documentNumber")) {
      const validDoc = isValidDocumentNum(
        form.getFieldValue("documentNumber"),
        e?.toLowerCase() === "standard" ? "standardcash" : e
      );

      if (!validDoc) {
        form.setFields([
          {
            name: "documentNumber",
            errors: ["Enter a valid doc number."],
          },
        ]);
      } else {
        form.setFields([
          {
            name: "documentNumber",
            errors: null,
          },
        ]);
      }
    }
  };

  useEffect(() => {
    const resetActiveState = () => {
      document.querySelectorAll(".ant-select-item-option").forEach((option) => {
        option.classList.remove("ant-select-item-option-active");
      });
    };

    const handleTabPress = (event) => {
      if (event.key === "Tab") {
        resetActiveState();
      }
    };

    document.addEventListener("keydown", handleTabPress);

    return () => {
      document.removeEventListener("keydown", handleTabPress);
    };
  }, [dropDownChanges]);

  // const activeOption = document.querySelector(".ant-select-item-option-active");
  // console.log(activeOption, "activeOption");

  const handleKeyDown = (event, dataOptions, formItemName) => {
    switch (event.key) {
      case "Enter":
        event.target.blur();
        break;

      case "Tab":
        setdropDownChanges(!dropDownChanges);

        // eslint-disable-next-line no-case-declarations
        const activeOption = document.querySelector(
          ".ant-select-item-option-active"
        );

        if (activeOption) {
          const value = activeOption.getAttribute("title");
          const optionExists = dataOptions.some(
            (option) => option?.value?.toLowerCase() === value?.toLowerCase()
          );

          if (optionExists) {
            form.setFieldsValue({ [formItemName]: value });
            if (formItemName === "service") {
              handleService(value);
            }
            if (formItemName === "travelBy") {
              handleTravelBy(value);
            }
            return;
          }

          if (formItemName === "shipmentType") {
            form.setFieldsValue({ [formItemName]: value });

            const optionExists = dataOptions.some(
              (option) =>
                option?.shipmentTypeName?.toLowerCase() === value?.toLowerCase()
            );
            if (optionExists) {
              const fil = contentDiscriptionData?.shipmentType?.find(
                (option) =>
                  option.shipmentTypeName.toLocaleLowerCase() ===
                  value?.toLocaleLowerCase()
              );
              const newVal = {
                key: fil.shipmentTypeId,
                value: fil.shipmentTypeName,
              };
              handleShipmentTypeChange("_", newVal);
            }
          }
          return;
        }

        break;

      default:
        break;
    }
  };

  const handleAPIKeyDown = (
    event,
    dataOptions,
    formItemName,
    formKeyName = "value",
    dropDownName
  ) => {
    switch (event.key) {
      case "Enter":
        event.preventDefault(); // Prevent any default action.

        {
          const focusableElements = Array.from(
            /* eslint-disable quotes */
            document.querySelectorAll(
              'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])'
            )
          ).filter((el) => !el.disabled && el.tabIndex >= 0);

          const currentElement = document.activeElement;
          const currentIndex = focusableElements.indexOf(currentElement);

          const nextIndex = (currentIndex + 1) % focusableElements.length;

          const nextFocusableElement = focusableElements[nextIndex];

          if (nextFocusableElement) {
            nextFocusableElement.focus();
          }
        }

        break;
      case "Tab":
        setdropDownChanges(!dropDownChanges);
        // eslint-disable-next-line no-case-declarations
        let activeOption = document.querySelector(
          ".ant-select-item-option-active"
        );

        if (activeOption) {
          // setdropDownChanges((prevState) => !prevState);
          const value = activeOption.getAttribute("title");

          const optionExists = dataOptions.find(
            (option) =>
              option[formKeyName].toLowerCase() == value?.toLowerCase()
          );
          if (optionExists) {
            form.setFieldsValue({ [formItemName]: value });

            if (dropDownName == "receiverAreaId") {
              const valueId = activeOption.getAttribute("id");

              const filterArea = dataOptions.find(
                (option) => option?.value == +valueId
              );

              form.setFieldsValue({ [formItemName]: filterArea?.label });

              const child = {
                key: optionExists?.value,
              };
              getAreaDestinationPremiseId(optionExists?.label, child);
              return;
            }

            return;
          }
        }
        break;
      default:
        break;
    }
  };

  const onPanChange = (e) => {
    const value = e.target.value.toUpperCase();
    setPanStatus(value);

    const panPattern = /^([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
    if (value && !panPattern.test(value)) {
      setPanStatus({ status: "error", msg: "Enter a valid PAN number" });
    } else {
      setPanStatus({ status: null, msg: null });
      return Promise.resolve();
    }
  };

  return (
    <>
      <Spin
        spinning={retailLoading || loading}
        indicator={
          <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
        }
      >
        <HeaderWithBottomLine text="General Details" icon={false} />
        <Row className="global-form-row" gutter={16}>
          <Col lg={6} sm={6}>
            <Form.Item
              label="Document Number"
              name="documentNumber"
              rules={[
                {
                  required: true,
                  message: null,
                },
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.reject("Enter valid doc number.");
                    }
                    if (form.getFieldValue("service")) {
                      if (
                        !isValidDocumentNum(
                          value,
                          form.getFieldValue("service")?.toLowerCase() ===
                            "standard"
                            ? "standardcash"
                            : form.getFieldValue("service") || "standardcash"
                        )
                      ) {
                        return Promise.reject("Enter valid doc number.");
                      }
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              onKeyPress={(event) => {
                if (
                  event.code == "Enter" &&
                  event.target.id !== "documentNumber"
                ) {
                  document.getElementById("submit_retail").blur();
                } else if (checkNumbervalue(event)) {
                  event.preventDefault();
                }
              }}
            >
              <Input
                placeholder="Enter document number"
                id="documentNumber"
                onChange={() => {
                  onChangeWeight();
                }}
                maxLength={documentLength}
                // maxLength={14}
              />
            </Form.Item>
          </Col>
          <Col lg={8} sm={8}>
            <Form.Item
              name="deliveryPincode"
              validateStatus={pinCodeServiceableStatus?.status}
              help={pinCodeServiceableStatus?.msg}
              label="Delivery PIN code"
              rules={[
                {
                  required: true,
                  message: "Enter delivery pincode",
                },
                () => ({
                  validator(rule, value = "") {
                    if (value.length > 0 && value.length < 6) {
                      return Promise.reject("Pincode should be 6 digits");
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
              onKeyPress={(event) => {
                if (event.code == "Enter") {
                  document.getElementById("submit_credit").blur();
                } else if (checkNumbervalue(event)) {
                  event.preventDefault();
                }
              }}
              onChange={() =>
                form.setFieldsValue({ service: null, receiverAreaId: null })
              }
            >
              <Input
                onChange={(e) => {
                  getLocal(e);
                  getDeliveryPincode(e, "deliveryPincode");
                }}
                placeholder="Enter delivery PIN code"
                maxLength={6}
                suffix={
                  <span
                    className={
                      pinCodeServiceableStatus?.serviceability === "Regular"
                        ? "green"
                        : pinCodeServiceableStatus?.serviceability === "Premium"
                        ? "orange"
                        : "red"
                    }
                  >
                    <Tooltip
                      title={
                        pinCodeServiceableStatus?.city?.length > number
                          ? pinCodeServiceableStatus?.city
                          : null
                      }
                    >
                      {pinCodeServiceableStatus?.city?.length > number
                        ? pinCodeServiceableStatus?.city?.slice(0, number) +
                          "..."
                        : pinCodeServiceableStatus?.city}
                      {pinCodeServiceableStatus?.city ? "-" : null}
                      {pinCodeServiceableStatus?.stateCode}
                    </Tooltip>
                  </span>
                }
              />
            </Form.Item>
          </Col>
          <Col lg={10} sm={10} md={10}>
            <Form.Item
              lg={6}
              sm={6}
              label="Area"
              name="receiverAreaId"
              placeholder="Search area"
              rules={[
                {
                  required: true,
                  message: "Select area",
                },
              ]}
            >
              <Select
                onSelect={(val, child) =>
                  getAreaDestinationPremiseId(val, child)
                }
                placeholder="Select area"
                showSearch
                onKeyDown={(event) =>
                  handleAPIKeyDown(
                    event,
                    areaList,
                    "receiverAreaId",
                    "label",
                    "receiverAreaId"
                  )
                }
                // options={areaList}
              >
                {areaList?.map((item) => (
                  <Option
                    key={item?.value}
                    id={item?.value}
                    value={item?.label}
                    disabled={item?.disabled}
                  >
                    {item?.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <HeaderWithBottomLine text="Sender Details" icon={false} />
        <Row className="global-form-row" gutter={16}>
          <Col lg={6} sm={6}>
            <Form.Item
              label="Name"
              name="senderName"
              type="input"
              rules={[
                {
                  required: true,
                  message: "Enter name",
                },
              ]}
              onKeyPress={(event) => {
                event.target.value = firstLetterCapitaliseDropDown(
                  event?.target?.value
                );
                if (event.code == "Enter") {
                  document.getElementById("submit_credit").blur();
                }
              }}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
          </Col>
          <SenderFormFields
            form={form}
            handleAPIKeyDown={handleAPIKeyDown}
            userInfo={userInfo}
            filterOption={filterOption}
            resetForm={resetForm}
          />
          <Col lg={6} sm={6}>
            <AutoCompleteAddress
              resetForm={resetForm}
              form={form}
              addressDetailsCallback={null}
              fieldName="senderAddress"
              label="Sender address"
              rules={[
                {
                  required: true,
                  message: "Enter sender address",
                },
              ]}
            />
          </Col>
          <Col span={6}>
            <Form.Item
              label="Email address"
              name="senderEmail"
              rules={[
                {
                  type: "email",
                  message: "Give the correct email",
                },
              ]}
            >
              <Input
                placeholder="Enter email address"
                onBlur={(e) =>
                  form.setFieldValue(
                    "returnEmail",
                    e.target.value.toLocaleLowerCase()
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Sender Mobile number"
              name="senderMobileNumber"
              rules={[
                {
                  required: true,
                  message: "Enter sender mobile no.",
                },
                () => ({
                  validator(rule, value = "") {
                    if (value.length > 0 && value.length < 10) {
                      return Promise.reject("Enter 10 digits phone number");
                    } else if (parseInt(value.substring(1, 0)) === 0) {
                      return Promise.reject("Mobile number should not be 0");
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input
                placeholder="Enter phone number"
                onKeyPress={(e) => {
                  if (e.code == "Enter") {
                    document.getElementById("submit_retail").blur();
                  } else if (/[^0-9]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                maxLength={10}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="GST number"
              name="gst"
              id="gst"
              validateStatus={gstStatus?.status}
              help={gstStatus?.msg}
              rules={[
                {
                  required: false,
                },
              ]}
              onChange={(e) => handleSenderGST(e)}
            >
              <Input
                placeholder="Enter GST number"
                maxLength={15}
                onKeyPress={(e) => {
                  if (e.code == "Enter") {
                    document.getElementById("submit_retail").blur();
                  } else if (checkSpecialCharacter(e)) {
                    e.preventDefault();
                  }
                }}
                onInput={(e) =>
                  (e.target.value = e?.target?.value?.toUpperCase())
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="pan"
              id="pan"
              label="PAN number"
              validateStatus={panStatus?.status}
              help={panStatus?.msg}
              onKeyPress={(event) => {
                if (event.code == "Enter") {
                  document.getElementById("submit_credit").blur();
                } else if (checkSpecialCharacter(event) || checkSpace(event)) {
                  event.preventDefault();
                }
              }}
            >
              <Input
                onChange={onPanChange}
                placeholder="Enter PAN number"
                type="text"
                maxLength={10}
                onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                onKeyPress={(e) => {
                  if (e.code == "Enter") {
                    document.getElementById("submit_retail").blur();
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <HeaderWithBottomLine text="Receiver Details" icon={false} />
        <Row className="global-form-row" gutter={16}>
          <ReciverAddressComponent
            form={form}
            reviverNameAndAddress={reviverNameAndAddress}
            parseInt={parseInt}
          />
        </Row>
        <HeaderWithBottomLine text="Service & Shipment Details" icon={false} />
        <Row className="global-form-row" gutter={16}>
          <Col lg={4} sm={6}>
            <Form.Item
              name="shipmentType"
              label="Type"
              rules={[
                {
                  required: true,
                  message: "Enter shipment type",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select DOX type"
                // optionFilterProp="children"
                // filterOption={filterOption}
                onChange={(value, obj) => {
                  handleShipmentTypeChange(value, obj);
                  onChangeWeight(value);
                }}
                // onBlur={() => {
                //   console.log("on Blur");
                //   document
                //     .querySelectorAll(".ant-select-item-option")
                //     .forEach((option) => {
                //       option.classList.remove("ant-select-item-option-active");
                //     });
                // }}
                onKeyDown={(event) =>
                  handleKeyDown(
                    event,
                    contentDiscriptionData?.shipmentType,
                    "shipmentType",
                    "value"
                  )
                }
              >
                {contentDiscriptionData?.shipmentType?.map((type) => (
                  <Option
                    key={type?.shipmentTypeId}
                    value={type?.shipmentTypeName}
                  >
                    {type?.shipmentTypeName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col lg={4} sm={6}>
            <Form.Item
              name="value"
              label="Value (INR)"
              rules={[
                {
                  required: isNonDox ? true : false,
                  pattern: /^[0-9].*/g,
                  message: "Enter value",
                },
              ]}
              onKeyPress={(event) => {
                if (event.code == "Enter") {
                  document.getElementById("submit_retail").blur();
                } else if (checkNumbervalue(event) || event.key === ".") {
                  event.preventDefault();
                }
              }}
            >
              <Input
                placeholder="Enter value"
                maxLength={7}
                onChange={(e) =>
                  +e.target.value > 50000
                    ? setEwayBill(true)
                    : setEwayBill(false)
                }
              />
            </Form.Item>
          </Col>

          {isNonDox ? (
            <>
              <Col md={4} lg={4} xl={4} sm={6}>
                <Form.Item
                  label="Weight(Kg)"
                  name="weight"
                  rules={[
                    {
                      required: true,
                      message: "Enter weight",
                    },

                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject();
                        }

                        if (
                          parseInt(value?.toString()?.substring(1, 0)) === 0
                        ) {
                          return Promise.reject("Weight should not be 0");
                        }

                        if (value && value?.split(".")?.length > 2) {
                          return Promise.reject(
                            "Please enter only one decimal value"
                          );
                        }

                        if (value > 499.99) {
                          return Promise.reject(
                            "Weight should be less than 499kg"
                          );
                        }
                        return Promise.resolve();
                      },
                      //validateTrigger: "onSubmit",
                    }),
                  ]}
                  onKeyPress={(event) => {
                    if (event.code == "Enter") {
                      document.getElementById("submit_retail").blur();
                    } else if (
                      checkNumbervalue(event) ||
                      event.target.value.length >= 7
                    ) {
                      event.preventDefault();
                    }
                  }}
                >
                  <Input
                    placeholder="Enter weight"
                    maxLength={6}
                    onChange={onChangeWeight}
                  />
                </Form.Item>
              </Col>
              <Col lg={4} sm={6}>
                <Form.Item
                  label={"Length(Cm)"}
                  name="length"
                  rules={[
                    {
                      required: false,
                      message: "Enter  length",
                    },
                  ]}
                  onKeyPress={(event) => {
                    if (event.code == "Enter") {
                      document.getElementById("submit_retail").blur();
                    } else if (checkNumbervalue(event) || event.key === ".") {
                      event.preventDefault();
                    }
                  }}
                >
                  <Input
                    placeholder="Enter length"
                    maxLength={3}
                    onChange={volumCalculation}
                  />
                </Form.Item>
              </Col>
              <Col lg={4} sm={6}>
                <Form.Item
                  label="Width(Cm)"
                  name="width"
                  rules={[
                    {
                      required: false,
                      message: "Enter width",
                    },
                  ]}
                  onKeyPress={(event) => {
                    if (event.code == "Enter") {
                      document.getElementById("submit_retail").blur();
                    } else if (checkNumbervalue(event) || event.key === ".") {
                      event.preventDefault();
                    }
                  }}
                >
                  <Input
                    placeholder="Enter width"
                    maxLength={3}
                    onChange={volumCalculation}
                    min={"0"}
                  />
                </Form.Item>
              </Col>
              <Col lg={4} sm={6}>
                <Form.Item
                  label={
                    <span className="calculater-container-pikpin">
                      Height (Cm)
                    </span>
                  }
                  name="height"
                  rules={[
                    {
                      required: false,
                      message: "Enter height",
                    },
                  ]}
                  onKeyPress={(event) => {
                    if (event.code == "Enter") {
                      document.getElementById("submit_retail").blur();
                    } else if (checkNumbervalue(event) || event.key === ".") {
                      event.preventDefault();
                    }
                  }}
                >
                  <Input
                    placeholder="Enter height"
                    maxLength={3}
                    onChange={volumCalculation}
                  />
                </Form.Item>
              </Col>
              <Col lg={4} sm={6}>
                <Form.Item label="Vol weight (Kg)" name="volumatricWeight">
                  <Input readOnly={true} type="number" />
                </Form.Item>
              </Col>
            </>
          ) : (
            <Col lg={4} sm={6}>
              <Form.Item
                label="Weight(Gms)"
                name="weight"
                rules={[
                  {
                    required: true,
                    message: "Enter weight",
                  },
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject();
                      }

                      if (value < 50) {
                        return Promise.reject(
                          "Enter a weight of at least 50Gm"
                        );
                      }

                      if (parseInt(value.substring(1, 0)) === 0) {
                        return Promise.reject("Weight should not be 0");
                      }

                      if (value && value.split(".").length > 2) {
                        return Promise.reject(
                          "Please enter only one decimal value"
                        );
                      }

                      if (value > 999.99) {
                        return Promise.reject(
                          "Weight should be less than 1000Gm"
                        );
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
                onKeyPress={(event) => {
                  if (event.code == "Enter") {
                    document.getElementById("submit_retail").blur();
                  } else if (checkNumbervalue(event)) {
                    event.preventDefault();
                  }
                }}
              >
                <Input
                  placeholder="Enter weight"
                  maxLength={7}
                  min={1}
                  onChange={onChangeWeight}
                />
              </Form.Item>
            </Col>
          )}

          <Col
            lg={4}
            sm={6}
            style={{ paddingTop: "24px" }}
            className="flex-column"
          >
            <Button
              type="secondary"
              className={
                allEwayBills?.length > 0
                  ? "eway-Child-Active"
                  : "eway-Child-InActive"
              }
              // size={"large"}
              style={{
                height: "30px",
                padding: "0 6px",
                display: "flex",
                justifyContent: "space-between",
                // width: "max-content",
                alignItems: "center",
              }}
              onClick={() => ewayDrawercallBack(true)}
              onKeyPress={(e) => e.key === "Enter" && ewayDrawercallBack(true)}
            >
              <span className="pr-05"> E-way bills</span>
              <AttachFile width={13} height={13} />
            </Button>
            <div>
              {ewayBill && allEwayBills?.length === 0 ? (
                <span style={{ color: "#FF5376" }}> Add e-way bill</span>
              ) : allEwayBills?.length > 0 ? (
                <span style={{ color: "#50CD89" }}>
                  {allEwayBills?.length} E-way Bill added
                </span>
              ) : null}
            </div>
          </Col>
          {isNonDox ? (
            <Col
              lg={4}
              sm={6}
              style={{ paddingTop: "24px", paddingRight: "5px" }}
              className="flex-column"
            >
              <Button
                type="secondary"
                className={
                  childShipments?.length > 0
                    ? "eway-Child-Active"
                    : "eway-Child-InActive"
                }
                // size={"large"}
                style={{
                  height: "30px",
                  // fontSize: "12px",
                  // width: "max-content",
                  justifyContent: "space-between",
                  padding: "0 6px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => childDrawerCallBack(true)}
                onKeyPress={(e) =>
                  e.key === "Enter" && childDrawerCallBack(true)
                }
              >
                <span className="pr-05"> Child Badge</span>
                <AttachFile width={13} height={13} />
              </Button>
              <div>
                {childShipments?.length > 0 ? (
                  <span style={{ color: "#50CD89" }}>
                    {childShipments?.length} child added
                  </span>
                ) : null}
              </div>
            </Col>
          ) : null}

          <Col lg={4} sm={6}>
            <Form.Item
              name="travelBy"
              rules={[
                {
                  required: true,
                  message: "Select travel by ",
                },
              ]}
              label="Travel by"
            >
              <Select
                // disabled={disabledInput}
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onKeyDown={(event) =>
                  handleKeyDown(event, travelByOptions, "travelBy", "value")
                }
                onSelect={handleTravelBy}
                // className="pincodeType-placecholder"
                placeholder="Select  Travel by"
                // optionFilterProp="children"
                options={travelByOptions}
              />
            </Form.Item>
          </Col>

          <Col lg={4} sm={6}>
            <Form.Item
              label="Service"
              name="service"
              rules={[
                {
                  required: true,
                  message: "Select service",
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                // className="pincodeType-placecholder"
                placeholder="Select service"
                // optionFilterProp="children"
                onChange={handleService}
                options={serviceOptions}
                onKeyDown={(event) =>
                  handleKeyDown(event, serviceOptions, "service", "value")
                }
              ></Select>
            </Form.Item>
          </Col>

          <Col lg={4} sm={6}>
            <Form.Item
              label="Is Sez"
              name="IsSez"
              rules={[
                {
                  required: true,
                  message: "Select IsSez",
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onKeyDown={(event) =>
                  handleKeyDown(event, sezOptions, "IsSez", "value")
                }
                className="pincodeType-placecholder"
                placeholder="Select service"
                options={sezOptions}
              />
            </Form.Item>
          </Col>

          <Col lg={12} sm={12}>
            <Form.Item
              name="content"
              label="Content"
              rules={[
                {
                  required: true,
                  message: "Select content",
                },
              ]}
            >
              <AutoComplete
                options={autoCompleteOptions}
                placeholder="Select a content"
                // optionFilterProp="children"
                onKeyDown={(event) =>
                  handleKeyDown(event, autoCompleteOptions, "content", "value")
                }
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </Col>

          <Col lg={4} sm={6}>
            <Form.Item
              style={{ whiteSpace: "nowrap" }}
              label="Payment mode"
              name="paymentMode"
              className="paymentMode"
              rules={[
                {
                  required: true,
                  message: "Select payment mode",
                },
              ]}
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onKeyDown={(event) =>
                  handleKeyDown(event, paymentOptions, "paymentMode", "value")
                }
                className="pincodeType-placecholder"
                placeholder="Select payment mode"
                //   onChange={handleService}
                options={paymentOptions}
              />
            </Form.Item>
          </Col>

          <Col lg={4} sm={6}>
            <Form.Item
              label="Freight"
              name="freight"
              rules={[
                {
                  required: false,
                  message: "Enter freight",
                },
                () => ({
                  validator(_, value) {
                    if (+value < +mainBaseCharge) {
                      return Promise.reject(
                        "Freight should not be less then base charge"
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
              onKeyPress={(event) => {
                if (event.code == "Enter") {
                  document.getElementById("submit_retail").blur();
                } else if (
                  checkSpecialCharacter(event) ||
                  event.key === "." ||
                  checkNumbervalue(event)
                ) {
                  event.preventDefault();
                }
              }}
            >
              <Input
                placeholder="Enter freight"
                maxLength={6}
                onChange={onFreightChange}
              />
            </Form.Item>
          </Col>
          {isNonDox && (
            <Col style={{ paddingTop: "5px", paddingBottom: "5px" }}>
              <b style={{ fontSize: "13px" }}>
                Note: Please provide accurate Length, Width and Height values to
                avoid reconciliation charges.
              </b>
            </Col>
          )}
          {/*
        <Col lg={4} sm={6}>
          <Form.Item
            label="CESS"
            name="cess"
            rules={[
              {
                required: false,
                message: "Enter base charge",
              },
            ]}
            onKeyPress={(event) => {
              if (event.code == "Enter") {
                document.getElementById("submit_retail").blur();
              } else if (
                checkSpecialCharacter(event) ||
                event.key === "." ||
                checkNumbervalue(event)
              ) {
                event.preventDefault();
              }
            }}
          >
            <Input
              placeholder="Enter cess"
              maxLength={6}
              onChange={onCESSChange}
            />
          </Form.Item>
        </Col> */}

          <Col lg={4} sm={6}></Col>
        </Row>
      </Spin>
    </>
  );
}

export default NewRetailForm;
