/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  List,
  Form,
  Spin,
  message,
  Carousel,
  Tooltip,
} from "antd";

import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { shallowEqual, useSelector, useDispatch } from "react-redux";

import "./index.scss";
import dashboardAnnouncementIcone from "../../assests/images/dashboard-annonsment.svg";
import dashbordMessageIcon from "../../assests/images/dashbord-message-icon.svg";
import accountBookingArrowIcon from "../../assests/images/arrow.svg";
import calenderIcon from "../../assests/images/calenderIcon.svg";

import { dashboardMenuList } from "../../config/dashboard";
import DashbordCard from "./DashbordCard";
import constants from "../../constants/messages";
import { getAccountService } from "../../actionsMethods/cashBookingMethods";
import { APIs, getToken, getPremiseId } from "../../utils/worker";
import axios from "axios";
import API from "../../api";
import { ReloadOutlined } from "@ant-design/icons";
import moment from "moment";
import nodeURLs from "../../api/nodeUrls";
import { capitalize } from "../../utils";

const token = getToken();
const premiseId = getPremiseId();

const { SHIPMENT_COUNT } = nodeURLs;

let userinfo = JSON.parse(localStorage.getItem("userinfo"));

const getRole = userinfo?.roles[0]?.replace(/.*_/, "");

function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ANNOUNCEMENT } = constants;

  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);

  const [accountDetails, setAccountDetails] = useState();
  const [day, setDay] = useState(1);
  const [onClickTime, setOnClickTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [todayData, setTodayData] = useState([]);

  const styleObj = {
    inscanned: {
      styles: {
        backgroundColor: "#FFD3D1",
        border: "1px solid #FFD5D3",
      },
      textColor: { color: "#E65B6B" },
    },
    outscan: {
      styles: {
        backgroundColor: "#27AE6014",
        border: "1px solid #8CE7B3",
      },
      textColor: { color: "#107A59" },
    },
    expected: {
      styles: {
        backgroundColor: "#fff7d9",
        border: "1px solid #ffe9bb",
      },
      textColo: { color: "#c59f0c" },
    },
    pending: {
      styles: {
        backgroundColor: "#fff7d9",
        border: "1px solid #ffe9bb",
      },
      textColor: { color: "#c59f0c" },
    },
    defalut: {
      styles: {
        backgroundColor: "#fff7d9",
        border: "1px solid #ffe9bb",
      },
      textColo: { color: "#c59f0c" },
    },
  };

  const getpremiseResponse = useSelector(
    (state) => state?.appReducer?.getCpPremiseReducer,
    shallowEqual
  );

  const { fetching, result } = getpremiseResponse;

  useEffect(() => {
    if (!fetching) {
      localStorage.setItem(
        "permises",
        JSON.stringify(result, getpremiseResponse?.result?.premises?.stateCode)
      );
    }
  }, [getpremiseResponse]);

  useEffect(() => {
    navigate("/dashboard", { state: { test: "test" } });

    getTodaysData();
    setAccountDetails(JSON.parse(localStorage.getItem("userinfo")));
    getAnnouncements();
  }, []);

  useEffect(() => {
    if (accountDetails !== undefined) {
      dispatch(getAccountService(accountDetails?.username));
    }
  }, [accountDetails]);

  const getTodaysData = async (date = null) => {
    setLoading(true);
    const param = {
      date,
    };

    const response = await API.Api(`${SHIPMENT_COUNT}`).NodeGetWithPremise(
      date && param
    );

    if (response?.status) {
      setLoading(false);
      let data = response?.response?.data;
      // const { total_count: inscannedCount } = data.find(
      //   (item) => item.status === "inscanned"
      // );
      // const { total_count: outscanCount } = data.find(
      //   (item) => item.status === "outscan"
      // );

      // const pendingCount = Math.max(
      //   0,
      //   parseInt(inscannedCount, 10) - parseInt(outscanCount, 10)
      // );

      // data.push({
      //   status: "pending",
      //   total_count: String(pendingCount),
      // });
      const order = ["expected", "inscanned", "outscan", "pending"];
      data?.sort(
        (a, b) => order?.indexOf(a?.status) - order?.indexOf(b?.status)
      );
      setOnClickTime(moment().format("hh:mm a"));
      setTodayData(data);
    } else {
      setLoading(false);
      setTodayData([]);

      message.error(response?.statusText || "Internal server error");
    }

    setLoading(false);
  };

  const getAnnouncements = async () => {
    try {
      let response = await axios.get(
        `${APIs.baseURL}/broadcast-service/v1/broadcasts/show`,
        {
          headers: {
            Authorization: token,
            "SMCS-PREMISE-ID": premiseId?.split("Bearer ")[1],
          },
        }
      );
      if (response?.status == 200) {
        setDataSource(response?.data);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          color: "black",
          fontSize: "15px",
          lineHeight: "1.5715 ",
        }}
        onClick={onClick}
      >
        <RightOutlined />
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          color: "black",
          fontSize: "15px",
          lineHeight: "1.5715 ",
        }}
        onClick={onClick}
      >
        <LeftOutlined />
      </div>
    );
  };

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  const handleAfterChange = (current) => {
    setDay(current);
    if (current) {
      getTodaysData();
    } else {
      getTodaysData(moment().subtract(1, "days").format("YYYY-MM-DD"));
    }
  };

  return (
    <div className="dashboard-container">
      <Spin spinning={fetching}>
        <Form
          form={form}
          layout="vertical"
          // onFinish={onFinish}
          autoComplete="off"
        >
          <div className="dashboardHeading">Dashboard</div>

          <Row gutter={[32, 32]} className="dashboard-container-row">
            <Col
              className="dashboard-container-col"
              xl={16}
              lg={16}
              md={24}
              sm={24}
              xs={24}
            >
              {getRole === "HUBADMIN" && (
                <Spin spinning={todayData === null}>
                  <Row>
                    <Col
                      xl={24}
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}
                      className="dashboard-container-col-innercol"
                    >
                      <div className="dashboard-container-col-innercol-trackOrder">
                        <span
                          style={{ color: "green", fontSize: "13px" }}
                          className="dashboard-container-col-innercol-trackOrder-name"
                        >
                          Shipment Status
                        </span>
                        <div style={{ color: "red", fontSize: "13px" }}>
                          Results as on{" "}
                          {day ? `${onClickTime} Today` : "Yesterday"}
                          {day ? (
                            <Tooltip title={"Reload"}>
                              <ReloadOutlined
                                // onClick={getCurrentTime}
                                onClick={() => getTodaysData()}
                                style={{
                                  fontSize: 18,
                                  paddingLeft: "10px",
                                  color: "black",
                                }}
                              />
                            </Tooltip>
                          ) : null}
                        </div>
                      </div>

                      <div className="custom-carousel">
                        <Carousel
                          // arrows
                          {...settings}
                          infinite={false}
                          afterChange={handleAfterChange}
                          initialSlide={1}
                          // beforeChange={getYesterdayData}
                        >
                          <Spin spinning={loading}>
                            <div>
                              {todayData.length > 0 && (
                                <ShipmentStatusCompo
                                  array={todayData}
                                  styleObj={styleObj}
                                />
                              )}
                            </div>
                          </Spin>
                          <Spin spinning={loading}>
                            <div>
                              {todayData.length > 0 && (
                                <ShipmentStatusCompo
                                  array={todayData}
                                  styleObj={styleObj}
                                />
                              )}
                            </div>
                          </Spin>
                        </Carousel>
                      </div>
                    </Col>
                  </Row>
                </Spin>
              )}
              <Row gutter={[32, 32]}>
                {dashboardMenuList.map((item, index) => {
                  if (
                    item?.role === "" ||
                    item.role.some((e) => accountDetails?.roles?.includes(e))
                  ) {
                    return (
                      <Col
                        xl={6}
                        lg={8}
                        md={10}
                        sm={12}
                        xs={12}
                        className="cash-booking"
                        key={index}
                      >
                        <DashbordCard
                          accountBookingArrowIcon={accountBookingArrowIcon}
                          accountBookingIcon={item.icon}
                          watchIcon={calenderIcon}
                          bookingType={item.title}
                          route={item.route}
                          id="cashBooking"
                        />
                      </Col>
                    );
                  }
                })}
              </Row>
            </Col>
            <Col xl={8} lg={8} md={24} sm={24} xs={24}>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Card
                    size="small"
                    className="dashboard-container-card-div"
                    style={{ height: "380px", marginBottom: "20px" }}
                    title={
                      <div>
                        {" "}
                        <img
                          width="24px"
                          height="24px"
                          className="dashboard-container-card-div-icon"
                          src={dashboardAnnouncementIcone}
                        />
                        <span className="announcement-text">
                          {" "}
                          {ANNOUNCEMENT}{" "}
                        </span>
                      </div>
                    }
                  >
                    <List
                      itemLayout="horizontal"
                      dataSource={dataSource}
                      pagination={
                        dataSource.length > 3
                          ? {
                              pageSize: 3,
                            }
                          : false
                      }
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta
                            title={
                              <div>
                                {" "}
                                <img
                                  width="29px"
                                  height="29px"
                                  className="dashboard-container-card-div-messageIcon"
                                  src={dashbordMessageIcon}
                                />
                                <span
                                  className="listTitle"
                                  style={{
                                    textTransform: "capitalize",
                                    color:
                                      item.priorityType === "HIGH"
                                        ? "#C40000"
                                        : item.priorityType === "MEDIUM"
                                        ? "#DF8100"
                                        : "#179503",
                                  }}
                                >
                                  {item.title}
                                </span>
                              </div>
                            }
                            description={
                              <div>
                                <span className="listDiscription">
                                  {item.validFrom + " - " + item.validTo}
                                </span>
                              </div>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>
  );
}
Dashboard.propTypes = {
  data1: PropTypes.number,
};

export default Dashboard;

const ShipmentStatusCompo = ({ array, styleObj }) => (
  <Row gutter={12} className="p-1">
    {array?.map((item, i) => (
      <Col lg={6} md={6} sm={6} key={i}>
        <div
          className="shipmentStatusContainer"
          style={styleObj[item?.status]?.styles}
        >
          <div className="textNumber" style={styleObj[item?.status]?.textColor}>
            {item?.total_count}
          </div>
          <div>{capitalize(item?.status)}</div>
        </div>
      </Col>
    ))}
  </Row>
);
