/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import "./style.scss";
import { Row, Col, Spin, Input, Form, Tooltip } from "antd";
import constantsMsgs from "../../../constants/messages";
import editButton from "../../../assests/images/penEdit.svg";
import activeListData from "../../../store/activeGstNumbers.json";
import ServiceNonDox from "./ServiceNonDox";
import { camelCase } from "../../../utils";
import {
  checkNumbervalue,
  checkSpecialCharacter,
  checkSpace,
  invalideMobileNo,
} from "../../../utils";
const index = ({
  allAppData,
  form,
  editTest,
  setEditTest,
  setInputState,
  visible,
  bookingType,
}) => {
  let cpOwnerNumber = JSON.parse(localStorage.getItem("userId"))?.userInfo
    ?.mobileNum;
  const [lastFormItem, setLastFormItem] = useState("lastFormItem");

  const [senderActive, setsenderACtive] = useState("highlight");
  const [receiverActive, setReceiverACtive] = useState("highlight");
  const [ewayHide, setewayHide] = useState("hide");
  const [ewayActive, setewayACtive] = useState("highlight");
  const [gstState, setGstState] = useState(false);
  const [ewayState, setEwayState] = useState(false);

  const [senderHide, setSenderHide] = useState("hide");
  const [receiverHide, setReceiverHide] = useState("hide");
  const [ewayFormItemHide, setEwayFormItemHide] = useState("form-item-hide");

  const [editStatusBtn, setEditStatusBtn] = useState(false);
  const [senderFormItemHide, setSenderFormItemHide] =
    useState("form-item-hide");
  const [receiverFormItemHide, setReceiverFormItemHide] =
    useState("form-item-hide");
  const senderAddressData = `${allAppData?.addressDetails?.senderCity},${" "} ${
    allAppData?.addressDetails?.senderAddressLine1
  },${" "}${allAppData?.addressDetails?.senderAddressLine2 || " "} ${""}${
    allAppData?.addressDetails?.senderState
  },${" "}${allAppData?.addressDetails?.senderPincode}`;

  const receiverAddressData = `${
    allAppData?.addressDetails?.receiverCity
  },${" "}${allAppData?.addressDetails?.receiverAddressLine1},${" "}${
    allAppData?.addressDetails?.receiverState
  },${" "}${allAppData?.addressDetails?.receiverPincode}`;
  const inputHandler = (e) => {
    e.preventDefault();
    setInputState(false);
  };
  const senderEditHandler = (e) => {
    e.preventDefault();
    if (senderActive === "") {
      setsenderACtive("highlight");
      setSenderFormItemHide("form-item-hide");
      setSenderHide("");
      setEditStatusBtn(false);
    } else {
      setsenderACtive("");
      setSenderFormItemHide("");
      setSenderHide("hide");
      setEditStatusBtn(true);
    }
  };
  const receiverEditHandler = (e) => {
    e.preventDefault();
    if (receiverActive === "") {
      setReceiverACtive("highlight");
      setReceiverFormItemHide("form-item-hide");
      setReceiverHide("");
      setEditStatusBtn(false);
    } else {
      setReceiverACtive("");
      setReceiverFormItemHide("");
      setReceiverHide("hide");
      setEditStatusBtn(true);
    }
  };
  const gstEditHandler = (e) => {
    e.preventDefault();
    if (!gstState) {
      setGstState(true);
      setEditStatusBtn(true);
    } else {
      setGstState(false);
      setEditStatusBtn(false);
    }
  };
  const ewayEditHandler = (e) => {
    e.preventDefault();

    if (!ewayState) {
      setEwayState(true);
      setEditStatusBtn(true);
    } else {
      setEwayState(false);
      setEditStatusBtn(false);
    }
  };
  useEffect(() => {
    if (senderActive === "") {
      setsenderACtive("");
      setSenderFormItemHide("");
      setSenderHide("hide");
      setReceiverACtive("");
      setReceiverFormItemHide("");
      setReceiverHide("hide");
      setewayHide("hide");
      setEwayFormItemHide("");
    } else {
      setReceiverACtive("highlight");
      setReceiverFormItemHide("form-item-hide");
      setReceiverHide("");
      setsenderACtive("highlight");
      setSenderFormItemHide("form-item-hide");
      setSenderHide("");
      setewayHide("");
      setEwayFormItemHide("form-item-hide");
    }
    form.setFieldsValue({
      ewayBill: allAppData?.shipmentDetails?.ewayBillNumber,
      senderGstNumber: allAppData?.addressDetails?.senderGst,
      senderPhoneNumber: allAppData?.addressDetails?.senderMobileNumber,
      receiverPhoneNumber: allAppData?.addressDetails?.receiverMobileNumber,
    });
    setEditTest(false);
    setGstState(false);
    setEwayState(false);
  }, []);
  // useEffect(() => {
  //   console.log(visible, "non-dox visible");
  // }, [visible]);

  useEffect(() => {
    form.setFieldsValue({
      ewayBill: allAppData?.shipmentDetails?.ewayBillNumber,
      senderGstNumber: allAppData?.addressDetails?.senderGst,
      senderPhoneNumber: allAppData?.addressDetails?.senderMobileNumber,
      receiverPhoneNumber: allAppData?.addressDetails?.receiverMobileNumber,
    });
  }, [editStatusBtn]);
  return (
    <div>
      <div className="modal-content non-dox-booking-summary-edit">
        <Spin spinning={false}>
          <Row>
            <Col span={24}>
              <Row>
                <div className="title highlight">
                  <h4>Booking Details</h4>
                </div>
              </Row>
              <Row className="booking-details">
                <Col md={8} lg={8} xs={12} sm={12}>
                  <div className="col">
                    <p>
                      {constantsMsgs?.AWB_NO} &nbsp;&nbsp;&nbsp;{"  "}
                      <span className="highlight">{allAppData?.awbNumber}</span>
                    </p>
                  </div>
                  <div>
                    <p>{constantsMsgs?.BOOKING_FROM}</p>{" "}
                  </div>
                  <div className="highlight">
                    {allAppData?.addressDetails?.senderCity}
                  </div>
                </Col>
                <Col md={14} lg={14} xs={12} sm={12}>
                  <div className="col">
                    <p>
                      {constantsMsgs?.BOOKING_DATE}&nbsp;{" "}
                      <span className="highlight">
                        {allAppData?.shipmentDetails?.bookingStartTime
                          ?.split(" ")[0]
                          ?.split("-")
                          ?.reverse()
                          ?.join("-")}
                      </span>
                    </p>
                  </div>
                  <div>
                    <p>{constantsMsgs?.BOOKING_TO}</p>{" "}
                  </div>
                  <div className="highlight">
                    {allAppData?.addressDetails?.receiverCity}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="address" gatter={32}>
            <Col>
              <Row>
                <div className="title">
                  <h4>Address</h4>
                </div>
              </Row>
              <Row className="adress-container">
                <Col md={12} lg={12} sm={24} xs={24}>
                  <div className="card ">
                    <div>
                      <h4></h4>
                    </div>
                    <h4>{allAppData?.addressDetails?.senderName}</h4>
                    <div className="card-box">
                      <p style={{ minHeight: "45px" }}>
                        {camelCase(senderAddressData)}
                      </p>
                      <p>{`Area: ${
                        allAppData?.addressDetails?.senderArea || " "
                      }`}</p>
                      <div className="edit-input-button-container">
                        <div>
                          {" "}
                          <p>Phone Number: </p>
                          <p
                            className={senderHide}
                            style={{ marginLeft: "5px" }}
                          >
                            {allAppData?.addressDetails?.senderMobileNumber ||
                              ""}
                          </p>
                          <Form.Item
                            name="senderPhoneNumber"
                            className={`${senderFormItemHide} edit-input-field phone-num-cls`}
                            onKeyPress={(event) => {
                              if (
                                checkNumbervalue(event) ||
                                checkSpecialCharacter(event) ||
                                checkSpace(event)
                              ) {
                                event.preventDefault();
                              }
                            }}
                            rules={[
                              () => ({
                                validator(rule, value) {
                                  if (value) {
                                    if (value.length > 0 && value.length < 10) {
                                      return Promise.reject(
                                        "Please enter 10 digits phone number"
                                      );
                                    } else if (
                                      parseInt(
                                        JSON.stringify(value).substring(1, 0)
                                      ) === 0
                                    ) {
                                      return Promise.reject(
                                        "Mobile number should not be 0"
                                      );
                                    } else if (
                                      invalideMobileNo.includes(parseInt(value))
                                    ) {
                                      return Promise.reject(
                                        "Please enter a valid phone number"
                                      );
                                    } else if (
                                      +cpOwnerNumber === parseInt(value)
                                    ) {
                                      return Promise.reject(
                                        "Please enter a valid phone number"
                                      );
                                    } else {
                                      return Promise.resolve({});
                                    }
                                  } else if (!value) {
                                    return Promise.reject(
                                      "Please enter Phone Number"
                                    );
                                  }
                                  return Promise.resolve({});
                                },
                                validateTrigger: "onSubmit",
                              }),
                            ]}
                          >
                            <Input
                              defaultValue={
                                allAppData?.addressDetails
                                  ?.senderMobileNumber || ""
                              }
                              placeholder="Phone Number"
                              className={senderActive}
                              maxLength={10}
                              onChange={inputHandler}
                            />
                          </Form.Item>
                        </div>
                        {bookingType !== "Account" ? (
                          <Tooltip title="Edit">
                            <button
                              onClick={senderEditHandler}
                              className="address-edit"
                            >
                              <img src={editButton} />
                            </button>
                          </Tooltip>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={12} lg={12} sm={24} xs={24}>
                  <div className="card card-one">
                    <div>
                      <h4>{allAppData?.addressDetails?.receiverName}</h4>
                    </div>
                    <div className="card-box">
                      <p style={{ minHeight: "45px" }}>
                        {camelCase(receiverAddressData)}
                      </p>
                      <p>
                        Area: {allAppData?.addressDetails?.receiverAreaName}
                      </p>
                      <div className="edit-input-button-container">
                        <div>
                          {" "}
                          <p>Phone Number: </p>
                          <p
                            className={receiverHide}
                            style={{ marginLeft: "5px" }}
                          >
                            {allAppData?.addressDetails?.receiverMobileNumber ||
                              ""}
                          </p>
                          <Form.Item
                            name="receiverPhoneNumber"
                            className={`${receiverFormItemHide} edit-input-field phone-num-cls`}
                            onKeyPress={(event) => {
                              if (
                                checkNumbervalue(event) ||
                                checkSpecialCharacter(event) ||
                                checkSpace(event)
                              ) {
                                event.preventDefault();
                              }
                            }}
                            rules={[
                              () => ({
                                validator(rule, value) {
                                  if (value) {
                                    if (value.length > 0 && value.length < 10) {
                                      return Promise.reject(
                                        "Please enter 10 digits phone number"
                                      );
                                    } else if (
                                      parseInt(
                                        JSON.stringify(value).substring(1, 0)
                                      ) === 0
                                    ) {
                                      return Promise.reject(
                                        "Mobile number should not be 0"
                                      );
                                    } else if (
                                      invalideMobileNo.includes(parseInt(value))
                                    ) {
                                      return Promise.reject(
                                        "Please enter a valid phone number"
                                      );
                                    } else if (
                                      +cpOwnerNumber === parseInt(value)
                                    ) {
                                      return Promise.reject(
                                        "Please enter a valid phone number"
                                      );
                                    } else {
                                      return Promise.resolve({});
                                    }
                                  } else if (!value) {
                                    return Promise.reject(
                                      "Please enter Phone Number"
                                    );
                                  }
                                  return Promise.resolve({});
                                },
                                validateTrigger: "onSubmit",
                              }),
                            ]}
                          >
                            <Input
                              defaultValue={
                                allAppData?.addressDetails
                                  ?.receiverMobileNumber || ""
                              }
                              placeholder="Phone Number"
                              className={receiverActive}
                              maxLength={10}
                              onChange={inputHandler}
                            />
                          </Form.Item>
                        </div>

                        <Tooltip title="Edit">
                          <button
                            onClick={receiverEditHandler}
                            className="address-edit"
                          >
                            <img src={editButton} />
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="service">
            <Col span={24}>
              <Row>
                <div className="title">
                  <h4>Service Details</h4>
                </div>
              </Row>
            </Col>
          </Row>
          <ServiceNonDox
            allAppData={allAppData}
            editButton={editButton}
            gstEditHandler={gstEditHandler}
            inputHandler={inputHandler}
            gstState={gstState}
            ewayState={ewayState}
            ewayEditHandler={ewayEditHandler}
            editStatusBtn={editStatusBtn}
            form={form}
            bookingType={bookingType}
          />

          {/* child data */}
          {allAppData?.childShipmentDetails?.length > 0 ? (
            <Row className="payment">
              <Col span={24}>
                <Row>
                  <div className="title">
                    <h4>Child Details </h4>
                  </div>
                </Row>
                <Row gutter={24}>
                  {allAppData?.childShipmentDetails?.map((data, index) => (
                    <Col md={12} lg={12} sm={24} xs={24} key={index}>
                      <div className="card left-card">
                        <Row>
                          <Col span={12} className="key">
                            <p>AWB No. : </p>
                            <p>Weight : </p>
                            <p>Vol weight : </p>
                            <p>Value : </p>
                            <p className="last">Eway Bill No. : </p>
                          </Col>
                          <Col span={12} className="value">
                            <p>{data?.childAwbNumber}</p>
                            <p>{data?.weight}</p>
                            <p>{data?.volumetricWeight}</p>
                            <p>{data?.value}</p>
                            <p className="last">{data?.ewayBillNumber || ""}</p>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          ) : null}
          {/* {allAppData?.charges !== null && ( */}
          {bookingType == "Cash" && (
            <Row className="payment">
              <Col span={24}>
                <Row>
                  <div className="title">
                    <h4>Payment</h4>
                  </div>
                </Row>
                <Row>
                  <Col md={12} lg={12} sm={24} xs={24}>
                    <div className="card left-card">
                      <Row>
                        <Col span={12} className="key">
                          <p>Total Charges: </p>
                          <p>SGST @ 9% : </p>
                          <p className="last">CGST @ 9% : </p>
                        </Col>
                        <Col span={12} className="value">
                          <p>{allAppData?.charges?.charges}</p>
                          <p>
                            {allAppData?.charges?.sgst
                              ? allAppData?.charges?.sgst
                              : "00.00"}
                          </p>
                          <p className="last">
                            {allAppData?.charges?.cgst
                              ? allAppData?.charges?.cgst
                              : "00.00"}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={12} lg={12} sm={24} xs={24}>
                    <div className="card right-card">
                      <Row>
                        <Col span={12} className="key">
                          <p> {constantsMsgs?.IGST}: </p>
                          <p>{constantsMsgs?.CESS} : </p>
                          <p className="last"></p>
                        </Col>
                        <Col span={12} className="value">
                          <p>
                            {allAppData?.charges?.igst
                              ? allAppData?.charges?.igst
                              : "00.00"}
                          </p>
                          <p>
                            {allAppData?.charges?.cess
                              ? allAppData?.charges?.cess
                              : "00.00"}
                          </p>
                          <p className="last"></p>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="frieght-class">
                <p id="frieght"> Frieght :</p>
                <p id="frieghtValue">{allAppData?.charges?.freight}</p>
              </Col>
            </Row>
          )}
        </Spin>
      </div>
    </div>
  );
};

export default index;
