import React, { useState } from "react";
import { Tabs } from "antd";
// import Heading from "../../../components/Heading";
// import OnboardHub from "./CreateHubOnboard";
// import ViewHub from "./ListHubOnboard";
import "./style.scss";
import Heading from "../../../components/Heading";
import ClientOnboardingSteps from "./ClientOnboardingSteps";
import { useNavigate } from "react-router-dom";
// import ViewClientTable from "./viewClientTable";

const ClientOnboarding = () => {
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState("1");
  const [check, setCheck] = useState(false);

  const changeTab = (key) => {
    setActiveKey(key);
    setCheck(!check);
    if (key === "2") {
      navigate("/client-list");
    }
  };

  return (
    <div className="p-2">
      <Heading title="Client Onboarding" />
      <div className="global-card-tab-container">
        <Tabs
          activeKey={activeKey}
          onChange={changeTab}
          items={[
            {
              label: "Client Onboarding",
              key: "1",
              children: <ClientOnboardingSteps />,
            },
            {
              label: "View All Clients",
              key: "2",
              children: "",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ClientOnboarding;
