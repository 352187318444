/* eslint-disable no-unused-vars */
import { Divider, Steps, Row, Col, message, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AddAgreement from "./AddAgreement";
import AddRateCard from "./AddRateCard";
import BasicDetails from "./BasicDetails";
import OrganisationDetails from "./OrganizationDetails";
import PincodeMapping from "./PincodeMapping";
import API from "../../../../api";
import urls from "../../../../api/urls";
import "./style.scss";
import Smcs_Loader from "../../../../assests/images/loader-new.gif";

const { GET_CLIENT_DETAILS, PATCH_CLIENT_DETAILS } = urls;

export const Onboarding = () => {
  const [current, setCurrent] = useState(0);
  const [sideStepHide, setSideStepHide] = useState(false);
  const [getData, setGetData] = useState(null);
  const [stepsData, setStepsData] = useState(null);
  const userId = JSON.parse(localStorage.getItem("userId"));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let clientId = localStorage.getItem("clientId");
    if (clientId) {
      getClientDetails(clientId);
    }
    window.scrollTo(0, 0);
  }, [current]);

  const getClientDetails = async (clientId) => {
    setLoading(true);

    let response = await API.Api(
      GET_CLIENT_DETAILS + `${clientId}`
    ).getCustomHeader({ cpId: userId?.userInfo?.premiseId });
    if (response?.status) {
      setLoading(false);
      setGetData(response?.response?.data);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    let clientId = localStorage.getItem("clientId");
    if (clientId) {
      getClientDetails_(clientId);
    }
  }, []);

  const getClientDetails_ = async (clientId) => {
    let response = await API.Api(
      GET_CLIENT_DETAILS + `${clientId}`
    ).getCustomHeader({ cpId: userId?.userInfo?.premiseId });
    let data = response?.response?.data;
    if (data.clientOnboard.status === "Rejected") {
      setCurrent(1);
    } else if (data.clientDocument.length > 0) {
      setCurrent(4);
    } else if (data.ratecard) {
      setCurrent(3);
    } else if (data.pincodeMapping) {
      setCurrent(2);
    } else if (
      data?.clientOrganizationDetails?.contactPersonName !== null &&
      data?.clientOrganizationDetails?.contactPersonName !== undefined
    ) {
      setCurrent(1);
    }
    setStepsData(data);
    setGetData(data);
  };

  const patchClient = async (values) => {
    setLoading(true);
    let clientId = localStorage.getItem("clientId");
    let response = await API.Api(
      PATCH_CLIENT_DETAILS + `${clientId}`
    ).patchCustomHeader(values, { cpId: userId?.userInfo?.premiseId });
    if (response?.status) {
      setLoading(false);
      message.success("Data added successfully.");
      setCurrent(current + 1);
    } else {
      setLoading(false);
    }
  };

  const onChange = (value) => {
    // console.log("onChange:", value);
    setCurrent(value);
  };

  const handleStepsChange = () => {
    setCurrent(current + 1);
  };

  const goBackStep = () => {
    window.scrollTo(0, 0);
    setCurrent(current - 1);
  };

  const sideStepHideFunc = () => {
    setSideStepHide(true);
  };

  const sideStepShowFunc = () => {
    setSideStepHide(false);
  };

  return (
    <div className="master-main-container">
      <Spin
        spinning={loading}
        indicator={
          <img src={Smcs_Loader} style={{ height: "100px", width: "100px" }} />
        }
      >
        <Row
          className={sideStepHide ? "" : "p-1"}
          style={{ height: "70vh", overflow: "scroll" }}
        >
          {!sideStepHide && (
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={6}
              className="global-steps-container"
            >
              <Steps
                current={current}
                direction="vertical"
                onChange={onChange}
                items={[
                  {
                    title: "Organisation Details",
                    description: "Please provide your org details",
                  },
                  {
                    title: "Business Details",
                    description: "Please provide your business details",
                    // disabled:
                    //   stepsData?.clientOrganizationDetails
                    //     ?.contactPersonName !== null &&
                    //   stepsData?.clientOrganizationDetails
                    //     ?.contactPersonName !== undefined
                    //     ? false
                    //     : true,
                  },
                  {
                    title: "Pincode Mapping",
                    description: "Please create a PIN Code map",
                    // disabled: stepsData?.pincodeMapping ? false : true,
                  },
                  {
                    title: "Rate Card",
                    description: "Please provide your rate card details",
                    // disabled: stepsData?.ratecard ? false : true,
                  },

                  {
                    title: "Agreement Details",
                    description: "Please upload your document",
                    // disabled:
                    //   stepsData?.clientDocument.length > 0 ? false : true,
                  },
                ]}
              />
            </Col>
          )}
          <Col xs={24} sm={24} md={24} lg={24} xl={sideStepHide ? 24 : 18}>
            {current === 0 ? (
              <BasicDetails
                handleStepsChange={handleStepsChange}
                patchClient={patchClient}
                getData={getData}
              />
            ) : current === 1 ? (
              <OrganisationDetails
                handleStepsChange={handleStepsChange}
                patchClient={patchClient}
                getData={getData}
                goBackStep={goBackStep}
              />
            ) : current === 2 ? (
              <PincodeMapping
                handleStepsChange={handleStepsChange}
                goBackStep={goBackStep}
              />
            ) : current === 3 ? (
              <AddRateCard
                handleStepsChange={handleStepsChange}
                sideStepsHide={sideStepHideFunc}
                sideStepsShow={sideStepShowFunc}
                goBackStep={goBackStep}
                rateCardPresent={getData?.ratecard}
                clientName={getData?.clientOnboard?.clientName}
              />
            ) : current === 4 ? (
              <AddAgreement
                handleStepsChange={handleStepsChange}
                getData={getData}
                goBackStep={goBackStep}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default Onboarding;
