/* eslint-disable indent */
/* eslint-disable react/react-in-jsx-scope */

import moment from "moment";

import { camelCase, commonSorterForTable, normalizeString } from "../../utils";
import { ReactComponent as Substract } from "../../assests/images/subtract.svg";
import { ReactComponent as Aubstract } from "../../assests/images/aubtract.svg";
import { ReactComponent as Audit_icon } from "../../assests/images/middleMile/audited-icon.svg";
import { ReactComponent as InfoIcon } from "../../assests/images/infoIcon.svg";
import { Row, Col, Tooltip } from "antd";
// const navigate = useNavigate();

const convertDate = (isoDate) => {
  return moment.utc(isoDate).format("DD-MM-YYYY HH:mm");
};

export const bookingInfoCol = [
  {
    title: "Date",
    dataIndex: "bookingDate",
    key: "bookingDate",
    sorter: (a, b) => commonSorterForTable(a, b, "bookingDate"),

    render: (text) => <span> {text ? convertDate(text) : "----"}</span>,
  },
  {
    title: "Booking CP",
    dataIndex: "bookingCpName",
    key: "bookingCpName",
    render: (text) => <span>{normalizeString(text)}</span>,
  },
  {
    title: "Receiver’s Name",
    dataIndex: "receiverName",
    key: "receiverName",
    render: (text) => <span>{camelCase(text)}</span>,
  },
  {
    title: "Recipient CP",
    key: "destinationCpName",
    dataIndex: "destinationCpName",
    render: (text) => <span>{camelCase(text)}</span>,
  },
  {
    title: "Service Type",
    key: "serviceType",
    dataIndex: "serviceType",
    render: (text) => <span>{camelCase(text)}</span>,
  },
  {
    title: "Travel Type",
    key: "travelType",
    dataIndex: "travelType",
    render: (text) => <span>{camelCase(text)}</span>,
  },
  {
    title: "Shipment Type",
    key: "shipmentType",
    dataIndex: "shipmentType",
    render: (text) => <span>{normalizeString(text)}</span>,
  },
  // {
  //   title: "Last Updated on",
  //   key: "updateTime",
  //   dataIndex: "updateTime",
  //   render: (text) => (
  //     <span>{text ? moment(text)?.format("DD-MM-YYYY HH:mm") : "----"}</span>
  //   ),
  // },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    render: (text) => <span>{normalizeString(text)}</span>,
  },
];
function loactionFun(rec) {
  if (rec.status === "HUB-OUTSCAN") {
    return (
      <span>{`${normalizeString(rec?.status)} at ${camelCase(
        rec?.premiseName
      )} to ${camelCase(
        rec?.outscanDetails?.toPremiseName || rec?.toPremisename
      )}`}</span>
    );
  } else {
    return (
      <span>{`${
        rec?.status ? normalizeString(rec?.status) : "----"
      } at ${camelCase(rec?.premiseName)}`}</span>
    );
  }
}

export const customisezColoum = [
  {
    title: "Date and Time",
    dataIndex: "date",

    render: (text) => <span>{text ? convertDate(text) : "----"}</span>,
    sorter: (a, b) => commonSorterForTable(a, b, "date"),
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (text) => <span>{text ? normalizeString(text) : "----"}</span>,
  },

  {
    title: "Location",
    dataIndex: "location",
    render: (text, rec) => (!rec.isException ? loactionFun(rec) : "----"),
  },
  {
    title: "User",
    dataIndex: "user",
  },

  {
    title: "Exception",
    dataIndex: "exception",
    render: (text, rec) => (rec.isException ? loactionFun(rec) : "----"),

    // render: (text) => dateFun(text),
  },
  {
    title: "Remarks",
    // dataIndex: "remarks",
    render: (record) => {
      return record?.status === "Outscan" ||
        record?.status === "HUB-OUTSCAN" ? (
        <span>
          {`Outscan from ${normalizeString(
            record?.premiseName
          )} to ${normalizeString(record?.toPremisename)} `}
          <span className="tooltipClass">
            <Tooltip
              className="tooltipClass"
              placement="bottom"
              title={
                <div className="tooltipClass">
                  <div className="item">
                    <span className="label">Co-loader:</span>{" "}
                    <span className="value">{record?.coLoader || "N/A"}</span>
                  </div>
                  <div className="item">
                    <span className="label">Transport Type:</span>{" "}
                    <span className="value">{record?.travelBy || "N/A"}</span>
                  </div>
                  <div className="item">
                    <span className="label">Vehicle No:</span>{" "}
                    <span className="value">{record?.vehicleNum || "N/A"}</span>
                  </div>
                  <div className="item">
                    <span className="label">Driver Name:</span>{" "}
                    <span className="value">{record?.driverName || "N/A"}</span>
                  </div>
                </div>
              }
            >
              <InfoIcon />
            </Tooltip>
          </span>
        </span>
      ) : record?.remarks ? (
        <span>{record?.remarks}</span>
      ) : (
        <div>----</div>
      );
    },
  },
];

export const deliveryInfoCol = [
  {
    title: "Delivery Date",
    key: "action",
  },
  {
    title: "From Center",
    key: "action",
  },
  {
    title: "To Center",
    key: "action",
  },
  {
    title: "Receiver Name",
    key: "action",
  },
  {
    title: "Receiver Phone",
    key: "action",
  },
  {
    title: "Reason",
    key: "action",
  },
  {
    title: "Status",
    key: "action",
  },
  {
    title: "POD.",
    key: "action",
  },
  {
    title: "Signature",
    key: "action",
  },
  {
    title: "Remarks",
    key: "action",
  },
];

export const travelInfoColSecondPage = customisezColoum.reduce(
  (acc, item, index) => {
    if (index === 3) {
      acc.push({ title: "User", dataIndex: "user" });
    } else {
      acc.push(item);
    }
    return acc;
  },
  []
);

export const auditCol = [
  {
    title: "Awb No",
    dataIndex: "awbNo",
    key: "awbNo",

    render: (text, val) => {
      return (
        <div>
          <span style={{ marginRight: "10px" }}>{text}</span>
          {val?.auditStatus && <Audit_icon />}
        </div>
      );
    },
  },
  {
    title: "Source Hub",
    dataIndex: "sourceHubName",
    key: "sourceHubName",

    render: (text) => {
      return text ? camelCase(text) : "-----";
    },
  },
  {
    title: "Destination Hub",
    dataIndex: "destinationHubName",
    key: "destinationHubName",
    sorter: (a, b) =>
      a?.destinationHubName?.localeCompare(b?.destinationHubName),

    render: (text) => {
      return text ? camelCase(text) : "-----";
    },
  },
  {
    title: "Audited Hub Details",
    dataIndex: "auditedHubName",
    key: "auditedHubName",
    render: (text, rec) => {
      return (
        <>
          <div>{text && camelCase(text)}</div>
          <div>{rec?.auditTime && rec?.auditTime}</div>
        </>
      );
    },
  },
  {
    title: "Booking types",
    dataIndex: "bookingType",
    key: "bookingType",

    render: (text) => {
      return text ? text : "-----";
    },
    width: 120,
  },
  {
    title: "Service type",
    dataIndex: "serviceType",
    key: "serviceType",
    render: (text) => {
      return text ? text : "-----";
    },
    width: 120,
  },

  {
    title: "Audited for",
    dataIndex: "auditType",
    key: "auditType",

    render: (text) => (text ? camelCase(text) : "-----"),
  },
  {
    title: "Booking Value",
    dataIndex: "auditType",
    key: "premiseName",

    render: (text, rec) => (
      <TableCompo
        actual={rec?.bookedWeight}
        l={rec?.bookedLength}
        b={rec?.bookedWidth}
        h={rec?.bookedHeight}
        volumetric={rec?.volumetricBookedValue}
      />
    ),
    width: 250,
  },
  {
    title: "Audited Value",
    dataIndex: "auditType",
    key: "premiseName",
    render: (text, rec) => (
      <TableCompo
        actual={rec?.auditedWeight}
        l={rec?.auditedLength}
        b={rec?.auditedWidth}
        h={rec?.auditedHeight}
        volumetric={rec?.volumetricAuditedValue}
      />
    ),
    width: 250,
  },
  {
    title: "Alert type",
    dataIndex: "volWeightDiffPerc",
    key: "volWeightDiffPerc",
    fixed: "right",

    render: (recored, rec) => {
      const shouldRenderSubstract =
        (rec?.weightDiff && rec?.weightDiff > 10) ||
        (rec?.volWeightDiff && rec?.volWeightDiff > 10);

      return shouldRenderSubstract ? <Aubstract /> : <Substract />;
    },
  },
];

const TableCompo = ({ l, b, h, volumetric, actual }) => {
  return (
    <Row justify={"space-between"} gutter={[10, 15]} style={{ width: "500" }}>
      <Col lg={16} style={{ fontWeight: "500" }}>
        Actual
      </Col>
      <Col lg={8}>{actual}</Col>
      <Col lg={16} style={{ fontWeight: "500" }}>
        Volumetric
      </Col>
      <Col lg={8}>{volumetric}</Col>
      <Col lg={16} style={{ fontWeight: "500" }}>
        LWH
      </Col>
      <Col lg={8}>{`${l},${b},${h}`}</Col>
    </Row>
  );
};
