import React, { useState } from "react";
import "./style.scss";
import { StepComponent } from "../../../components/Steps";

const OnbordingSteps = () => {
  const [current, setCurrent] = useState(0);

  const onChange = (value) => {
    setCurrent(value);
  };
  const description = "This is a description.";

  const steps = [
    {
      step: 1,
      title: "CP Details",
      //   icon: book,
      content: <p>Hellow world</p>,
      description,
    },
    {
      step: 2,
      title: "Firm Details",
      //   icon: firm,
      content: <p>Hellow world1</p>,
      description,
    },
    {
      step: 3,
      title: "Personal Details",
      content: <p>Hellow world2</p>,
      description,
      //   icon: personal,
    },
  ];

  return (
    <div>
      <StepComponent current={current} onChange={onChange} steps={steps} />
    </div>
  );
};

export default OnbordingSteps;
