/* eslint-disable no-unused-vars */
/* eslint-disable indent */

import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Progress,
  Radio,
  Spin,
  Tooltip,
  message,
  Input,
  Select,
  Empty,
} from "antd";
import { useNavigate } from "react-router-dom";
import { camelCase } from "../../../utils";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import editButton from "../../../assests/images/penEdit.svg";
// import rightArrow from "../../../assests/images/rightVector.svg";
import shipmentTruck from "../../../assests/images/shipmentTruck.svg";
import TruckProgress from "../../../assests/images/TruckProgress.svg";
import urls from "../../../api/urls";
import API from "../../../api";
import {
  getPacketListBySatus,
  getManifestVehicle,
  getManifestedList,
  updateManifestDriver,
  getPacketListClear,
  putManifestLockClear,
  clearManifestDriver,
} from "../../../actionsMethods/manifestMethods";
import lock from "../../../assests/images/lock.svg";
import readytolockicon from "../../../assests/images/readytolockicon.svg";
import tik from "../../../assests/images/tik.svg";
import Minicard from "./Minicard";
import Bigcard from "./Bigcard";
import "./style.scss";
import Heading from "../../../components/Heading";
const { GET_DRIVER_LIST, PUT_DRIVER_DETAILS } = urls;
const { Option } = Select;

const index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [manifestListArr, setManifestListArr] = useState([]);
  const [radioValue, setRadioValue] = useState();
  const [hide, setHide] = useState("");
  const [loadHide, setloadHide] = useState("hide");
  const [loaded, setLoaded] = useState(false);
  const [loadToVehicle, setloadToVehicle] = useState("hide");
  const [TruckButton, setTruckButton] = useState("none");
  const [inputHide, setInputHide] = useState("");
  const [ltv, setLtv] = useState(true);
  const [editBtnStatus, setEditBtnStatus] = useState(true);
  const [filteredTruck, setFilteredTruck] = useState();
  const [driverNameList, setDriverNameList] = useState([]);
  const [filterSelectedTruck, setFilterSelectedTruck] = useState([]);
  const manifestFor = localStorage.getItem("manifestFor");
  const hubName = camelCase(
    JSON.parse(localStorage.getItem("userId"))?.premises?.parentPremiseName
  );
  const manifestIdData = JSON.parse(
    localStorage.getItem("manifestedId")
  )?.manifestId;
  let manifest = localStorage.getItem("manifestedId");
  let manifestId = JSON.parse(manifest);
  const { fetching: fetching, result: getManifestedresult } = useSelector(
    (state) => state.manifestReducer.getManifestedListReducer,
    shallowEqual
  );
  const vehicleDetails = useSelector(
    (state) => state.manifestReducer.getManifestVehicleReducer,
    shallowEqual
  );

  const updateDriverResponse = useSelector(
    (state) => state.manifestReducer.updateDriverDetailsReducer,
    shallowEqual
  );

  const loadToVehcileResponse = useSelector(
    (state) => state.manifestReducer.updateManifestDriverReducer,
    shallowEqual
  );

  const manifestLockedResponce = useSelector(
    (state) => state.manifestReducer.putManifestLockReducer
  );

  const { fetching: fetching2, result: manifestLockedResult } =
    manifestLockedResponce;
  const { fetching: fetching3, result: vehicleResultData } = vehicleDetails;
  const { error: loadVehicleError } = loadToVehcileResponse;
  const { result: updateDriverResult, error: updateDriverError } =
    updateDriverResponse;

  let vehicleResult = vehicleResultData?.filter(
    (data) => data?.status == "ACTIVE"
  );

  let arr =
    getManifestedresult?.shipmentScanDtos?.map((val) => val?.packetsdto) || [];

  let arr2 =
    getManifestedresult?.shipmentScanDtos.map((val) => val?.shipmentDetails) ||
    [];

  let totalPackets = arr?.filter(function (el) {
    return el != null;
  });

  let totalShipments = arr2?.filter(function (el) {
    return el != null;
  });

  let filterByweightTruck = vehicleResult?.filter(
    (data) => data?.loadCapacity >= getManifestedresult?.totalweight
  );
  const getDriverList = async (filterTruckData) => {
    if (filterTruckData?.length > 0) {
      setLoading(true);
      let response = await API.Api(
        `${GET_DRIVER_LIST}${filterTruckData[0]?.vehicleClass.toUpperCase()}`
      ).getWithPremiseHeader();
      if (response?.status) {
        setDriverNameList(response?.response?.data);
        setLtv(false);
        setLoading(false);
      } else {
        message.error(response?.response?.data?.message);
        setLtv(true);
        setLoading(false);
      }
    }
  };

  const onRadioChange = (e) => {
    e.preventDefault();
    setRadioValue(e.target.value);
  };
  const updateDriver = async (params) => {
    setTruckButton("none");
    setInputHide("");
    let driverIds = driverNameList?.filter(
      (data) => data.driverId === params?.drivername
    );
    let vehicleNumber = filterSelectedTruck[0]?.vehicleNo;
    if (driverIds) {
      let response = await API.Api(
        `${PUT_DRIVER_DETAILS}${vehicleNumber}/driver/${driverIds[0]?.driverId}`
      ).put();
      if (response?.status) {
        setDriverNameList([response?.response?.data]);
        message.success("Driver name updated successfully");
        setFilteredTruck(response?.response?.data);
        dispatch(getManifestVehicle());
      } else {
        message.error("Network error");
      }
    }
  };

  const totalIndividual = (objKey, bookingStatus) => {
    return manifestListArr.reduce((counter, obj) => {
      if (
        Object.keys(obj).includes(objKey) &&
        obj?.bookingStatus === bookingStatus
      )
        counter += 1;
      return counter;
    }, 0);
  };
  const loadVehicleHandler = (e) => {
    setEditBtnStatus(false);
    e.preventDefault();
    setHide("hide");
    setloadHide("");
    setLoaded(true);
    setTruckButton("none");
    setInputHide("");
    dispatch(getPacketListBySatus("manifest", manifestFor));
    let manifestDriverPayload = {
      pickupDriverId: filterSelectedTruck[0]?.vehicleNo,
    };
    dispatch(updateManifestDriver(manifestDriverPayload, manifestIdData));
    if (filterSelectedTruck?.length > 0) {
      setFilteredTruck(filterSelectedTruck[0]);
    }
  };
  const lockVehicleHandler = (e) => {
    e.preventDefault();
    setloadHide("hide");
    setloadToVehicle("");
    let data = {
      manifestId: manifestId?.manifestId,
      ...filteredTruck,
    };

    navigate("/cp-eway-bill", {
      state: data,
    });
  };

  const editHandler = () => {
    if (TruckButton === "none") {
      setTruckButton("");
      setInputHide("none");
    } else {
      setTruckButton("none");
      setInputHide("");
    }
  };
  const cancelEditHandle = () => {
    setTruckButton("none");
    setInputHide("");
  };
  // const backFunction = () => window.history.back();
  const driverSelectHandle = (e) => {
    let choosenDriver = driverNameList?.filter((data) => data?.driverId == e);
    form.setFieldValue(
      "contactNumber",
      choosenDriver.length > 0 ? choosenDriver[0]?.contactNumber : null
    );
  };
  useEffect(() => {
    // form.setFieldValue("drivername", filteredTruck?.driverName);
    // form.setFieldValue("contactNumber", filteredTruck?.contactNo);
  }, [loaded]);
  useEffect(() => {
    setFilterSelectedTruck(
      vehicleResult?.filter((val, index) => {
        return index == radioValue;
      })
    );
  }, [vehicleResultData]);
  useEffect(() => {
    let filterTruckData = filterByweightTruck?.filter((val, index) => {
      return index == radioValue;
    });
    getDriverList(filterTruckData);
    setFilterSelectedTruck(filterTruckData);
  }, [radioValue]);
  useEffect(() => {
    dispatch(getManifestVehicle());
  }, [manifestFor]);
  useEffect(() => {
    dispatch(getManifestedList(manifestId?.manifestId));
  }, [manifestId?.manifestId]);

  useEffect(() => {
    if (loadToVehcileResponse?.result !== null) {
      message.success("Loaded to vehicle");
    } else if (loadVehicleError !== null) {
      message.error("Network error");
    }
  }, [loadToVehcileResponse]);

  useEffect(() => {
    if (updateDriverResult !== null) {
      message.success("Drive details updated");
      // dispatch(getManifestVehicle());
    } else if (updateDriverError !== null) {
      message.error("Network error");
    }
  }, [updateDriverResult]);

  useEffect(() => {
    if (manifestLockedResult !== null) {
      message.success("Manifested successfully");
      dispatch(getPacketListClear());
      dispatch(putManifestLockClear());
      dispatch(clearManifestDriver());
      // navigate("/viewBooking");
      navigate("/cp-eway-bill", { state: manifestId?.manifestId });
    }
  }, [manifestLockedResult]);

  useEffect(() => {
    if (getManifestedresult !== null) {
      let arr3 = [...arr, ...arr2];
      let filtered = arr3?.filter(function (el) {
        return el != null;
      });

      setManifestListArr(filtered);
    }
  }, [getManifestedresult]);

  useEffect(() => {
    totalIndividual("awbNumber", "MANIFESTED");
  }, [manifestListArr]);

  return (
    <div>
      <Row className="truck-container">
        <Col className="left">
          <Spin spinning={fetching || fetching2 || fetching3 || loading}>
            <Row className="header">
              <Col span={12}>
                {/* <a
                  // href="/scan"
                  style={{ background: "none" }}
                  onClick={backFunction}
                >
                  <img src={rightArrow} />
                </a> */}
                <Heading title={"Load to vehicle"} />

                {/* <h4>Load to vehicle</h4> */}
              </Col>

              <Col span={5}>
                {/* <span>Packets : </span>
                <div className="num">{totalPackets?.length}</div> */}
              </Col>
              <Col span={5}>
                <span>Individuals: </span>
                <div className="num">{totalShipments?.length}</div>
              </Col>
            </Row>
            <Row className="middle-heading">
              <div className="head">
                <h4>
                  <img src={shipmentTruck} /> Booked Shipment (s)
                </h4>
                <p className="total-weight">
                  Total Vol Weight:{" "}
                  <span>{getManifestedresult?.totalVolWeight} Kg</span>
                </p>
                <p className="total-weight">
                  Total Weight:{" "}
                  <span>{getManifestedresult?.totalweight} Kg</span>
                </p>
              </div>
            </Row>
            <Row className="outer-container">
              <Row className="main-table" gutter={32}>
                {manifestListArr &&
                  manifestListArr?.map((el, idx) => {
                    if (
                      Object.keys(el).includes("packetBarcode") &&
                      el?.status === "MANIFESTED"
                    ) {
                      return <Bigcard item={el} key={idx} />;
                    }
                    if (
                      Object.keys(el).includes("awbNumber") &&
                      el?.bookingStatus == "MANIFESTED"
                    ) {
                      return <Minicard item={el} key={idx} color={"greens"} />;
                    }
                  })}
              </Row>
            </Row>
          </Spin>
          <div className="space"></div>
          <Row className="load-box">
            {!loaded ? null : (
              <div className="loaded">
                <p>
                  <img src={tik} /> loaded
                </p>
              </div>
            )}
          </Row>
        </Col>
        <Col></Col>

        {/* //////////////////TRUCK///////////////////// */}
        <Col className="right">
          <Row className="header-two">
            <Col>
              <h4>Vehicles</h4>
            </Col>
          </Row>
          <Row className="truck-row">
            <Radio.Group onChange={onRadioChange} value={radioValue}>
              {filteredTruck ? (
                <Row className="truck-one">
                  <Form
                    layout="vertical"
                    form={form}
                    onFinish={updateDriver}
                    autoComplete="off"
                  >
                    <Col span={24} className="middle-heading-right-edit">
                      <div className="truck-one-first-heading">
                        <p>
                          {hubName}
                          <Tooltip title="Edit">
                            <Button
                              onClick={() => editHandler()}
                              disabled={editBtnStatus}
                              className="edit-pen"
                            >
                              <img src={editButton}></img>
                            </Button>
                          </Tooltip>
                        </p>
                      </div>
                      <Row>
                        <Col span={6}>
                          <Form.Item
                            label="Driver name"
                            colon={false}
                            style={{ display: `${inputHide}` }}
                          >
                            {filteredTruck?.driverName}
                          </Form.Item>
                          <Form.Item
                            label="Driver name"
                            name="drivername"
                            colon={false}
                            className="input-edit"
                            style={{ display: `${TruckButton}` }}
                            rules={[
                              {
                                required: true,
                                message: "Enter driver name",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Please Select Name"
                              onChange={driverSelectHandle}
                            >
                              {driverNameList.length > 0
                                ? driverNameList?.map((driver) => (
                                    <Option
                                      key={driver?.driverId}
                                      value={driver?.driverId}
                                    >
                                      {driver?.name}
                                    </Option>
                                  ))
                                : null}
                            </Select>
                          </Form.Item>
                          <Form.Item label="Load capacity" colon={false}>
                            {(filteredTruck?.loadCapacity / 1000).toFixed(2)}
                            Ton
                          </Form.Item>
                          <Form.Item label="Route permit type" colon={false}>
                            {filteredTruck?.permitType}
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            label="Contact number"
                            colon={false}
                            style={{ display: `${inputHide}` }}
                          >
                            {filteredTruck?.driverContractNumber}
                          </Form.Item>

                          <Form.Item
                            label="Contact number"
                            name="contactNumber"
                            colon={false}
                            className="input-edit"
                            style={{ display: `${TruckButton}` }}
                          >
                            <Input
                              defaultValue={filteredTruck?.contactNo}
                              disabled
                              type="text"
                              maxLength={10}
                              onKeyPress={(e) => {
                                if (/[^0-9]/.test(e.key)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>

                          <Form.Item label="Truck" colon={false}>
                            {filteredTruck?.vehicleNo}
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Row className="truck-img">
                            <img src={TruckProgress} />
                            <Progress
                              percent={
                                getManifestedresult?.totalweight === undefined
                                  ? 0
                                  : (
                                      (parseInt(
                                        getManifestedresult?.totalweight
                                      ) /
                                        filteredTruck?.loadCapacity) *
                                      100
                                    ).toFixed(0)
                              }
                            />
                          </Row>
                        </Col>
                      </Row>

                      <Row
                        className="truck-button"
                        style={{ display: `${TruckButton}` }}
                      >
                        <Button
                          className="back-button"
                          onClick={cancelEditHandle}
                        >
                          CANCEL
                        </Button>
                        <Button className="next-button" htmlType="submit">
                          SAVE
                        </Button>
                      </Row>
                    </Col>
                  </Form>
                </Row>
              ) : filterByweightTruck?.length > 0 ? (
                filterByweightTruck?.map((item, index) => {
                  return (
                    <Row className="truck-one" key={item}>
                      <Col span={24} className="middle-heading-right">
                        <div className="truck-one-first-heading">
                          <p>
                            {hubName}
                            <Button
                              onClick={() => editHandler()}
                              disabled={editBtnStatus}
                            >
                              <img src={editButton}></img>
                            </Button>
                          </p>

                          <Radio value={index}></Radio>
                        </div>
                        <Row>
                          <Col span={6}>
                            <Form layout="vertical">
                              <Form.Item label="Driver name" colon={false}>
                                {item?.driverName}
                              </Form.Item>

                              <Form.Item label="Load capacity" colon={false}>
                                {(item?.loadCapacity / 1000).toFixed(2)} Ton
                              </Form.Item>
                              <Form.Item
                                label="Route permit type"
                                colon={false}
                              >
                                {item?.permitType}
                              </Form.Item>
                            </Form>
                          </Col>
                          <Col span={6}>
                            <Form layout="vertical">
                              <Form.Item label="Contact number" colon={false}>
                                {item?.driverContractNumber}
                              </Form.Item>

                              <Form.Item label="Truck" colon={false}>
                                {item?.vehicleNo}
                              </Form.Item>
                            </Form>
                          </Col>
                          <Col span={12}>
                            <Row className="truck-img">
                              <img src={TruckProgress} />
                              <Progress percent={0} />
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  );
                })
              ) : (
                <Empty style={{ marginTop: "25%" }} />
              )}
            </Radio.Group>
          </Row>
          {filterByweightTruck?.length > 0 ? (
            <Row className="footer-button">
              <Col>
                <Button className={`locked ${loadToVehicle}`}>
                  <img src={lock} /> vehicle locked
                </Button>
                <Button
                  className={`ready-lock ${loadHide}`}
                  onClick={lockVehicleHandler}
                  disabled={filteredTruck?.driverName ? false : true}
                >
                  <img src={readytolockicon} /> Update E-way Bill
                </Button>
                <Button
                  className={`load-to-vehicle ${hide}`}
                  disabled={ltv}
                  onClick={loadVehicleHandler}
                >
                  load to vehicle
                </Button>
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

export default index;
