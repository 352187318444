/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Radio,
  Button,
  Spin,
  message,
  Collapse,
} from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import API from "../../../../api";
import urls from "../../../../api/urls";
import Fuzzy from "fuzzy";
const { GET_CLIENT_BY_CP } = urls;
import Alert from "../../../../components/Alerts";
import RateCardTable from "../../../../components/Master/RateCard/RateCardTable";
import FovForm from "./fovForm";
import AllocateForm from "./allocateForm";
import {
  getCategoryBasedValue,
  createCashRateCardTemplate,
  getCashRateCardDetails,
  allocateCashRateCard,
  clearAllocateCashRateCard,
  clearCreateCashRateCardTemplate,
  clearCashRateCardTemplates,
  getExistingCashRateCardTemplates,
} from "../../../../actionsMethods/masterMethods";
import { APIs, getToken } from "../../../../utils/worker";
import { cashBookingRateCardData } from "./cashBookingRateCardData";
import { defaultRateCard } from "./defaultRateCard";
import "../../index.scss";
import "../../../../commonStyles/button.scss";
import FormItem from "antd/es/form/FormItem";
import { capitaliseWithHyphen, getFinancialYearData } from "../../../../utils";
import { label } from "aws-amplify";
const { Panel } = Collapse;

const CreateTemplate = ({
  templateType,
  templateId,
  allocateStatus,
  allocateFor,
}) => {
  let token = getToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [standardDox, setStandardDox] = useState([]);
  const [standardNonDox, setStandardNonDox] = useState([]);
  const [fastTrackSurface, setFastTrackSurface] = useState([]);
  const [fastTrackAir, setFastTrackAir] = useState([]);
  const [fastTrackNonDox, setFastTrackNonDox] = useState([]);
  const [valuePlusDox, setValuePlusDox] = useState([]);
  const [valuePlusNonDox, setValuePlusNonDox] = useState([]);
  const [showTemplateDropdown, setShowTemplateDropdown] = useState(false);
  const [categoryBasedOptions, setCategoryBasedOptions] = useState([]);
  const [categoryBasedData, setCategoryBasedData] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [categoryValues, setCategoryValues] = useState("");
  const [cpBasedClientList, setCpBasedClientList] = useState([]);
  const [financialYearData, setFinancialYearData] = useState([]);
  const [rateCardName, setRateCardName] = useState("");
  const [financialYear, setFinancialYear] = useState(
    `${new Date().getFullYear() - 1}-${new Date().getFullYear()}`
  );
  const [fov, setFov] = useState("0.00");
  const [insurance, setInsurance] = useState("0.00");
  const [handlingCharges, setHandlingCharges] = useState("0.00");
  const [premiumServiceCharges, setPremiumServiceCharges] = useState("0.00");
  const [fuelServiceCharges, setFuelServiceCharges] = useState("0.00");
  const [codCharges, setCodCharges] = useState("0.00");
  const [isTemplate, setIsTemplate] = useState(true);
  const [rateCardTemplatesData, setRateCardTemplatesData] = useState([]);
  const [allocationFromDate, setAllocationFromDate] = useState(new Date());
  const [allocationToDate, setAllocationToDate] = useState(
    new Date().getDate() + 1
  );
  const [alertData, setAlertData] = useState(false);
  ///--Selector--//
  const categoryBasedValue = useSelector(
    (state) => state.masterReducer.getCategoryBasedValueReducer,
    shallowEqual
  );

  const createCashRateCardResponse = useSelector(
    (state) => state.masterReducer.createCashRateCardReducer,
    shallowEqual
  );

  const rateCardDetailsResponse = useSelector(
    (state) => state.masterReducer.getCashRateCardDetailsReducer,
    shallowEqual
  );

  const cashRateCardTemplates = useSelector(
    (state) => state.masterReducer.getExistingCashRateCardTemplatesReducer,
    shallowEqual
  );

  const allocateRateCardResponse = useSelector(
    (state) => state.masterReducer.allocateCashRateCardReducer,
    shallowEqual
  );

  ///------///

  ///---Data Fetching---///
  const {
    fetching: loading,
    result: categoryResult,
    error: categoryError,
  } = categoryBasedValue;

  const {
    fetching: loading1,
    result: createCashRateCardResult,
    error: createCashRateCardError,
  } = createCashRateCardResponse;

  const { fetching: loading2, result: rateCardDetails } =
    rateCardDetailsResponse;

  const { result: cashRateCardTemplatesResults } = cashRateCardTemplates;

  const {
    fetching: loading3,
    result: allocateCashRateCardResult,
    error: allocateCashRateCardError,
  } = allocateRateCardResponse;

  ///------///
  useEffect(() => {
    if (allocateCashRateCardResult) {
      setAlertData({
        visible: true,
        message: allocateCashRateCardResult.message,
      });
      dispatch(clearAllocateCashRateCard());
      dispatch(clearCashRateCardTemplates());
      setTimeout(() => {
        navigate(
          allocateFor === "RETAIL"
            ? "/cash-booking-ratecard"
            : "/account-booking-ratecard",
          { state: "allocate" }
        );
      }, 3000);
    }
    if (allocateCashRateCardError) {
      message.error(allocateCashRateCardError.message);
      dispatch(clearAllocateCashRateCard());
    }
  }, [allocateCashRateCardResult, allocateCashRateCardError]);

  useEffect(() => {
    if (cashRateCardTemplatesResults?.data) {
      let temp = [];
      cashRateCardTemplatesResults?.data.map((e) => {
        temp.push({ label: e.ratecardName, value: e.id });
      });
      setRateCardTemplatesData(temp);
    }
  }, [cashRateCardTemplatesResults]);

  useEffect(() => {
    if (createCashRateCardResult) {
      message.success(createCashRateCardResult.message);
      dispatch(clearCreateCashRateCardTemplate());
      dispatch(clearCashRateCardTemplates());
      form.setFieldValue("ratecardName", "");
      form.setFieldValue("fovItem", "0.00");
      form.setFieldValue("insurance", "0.00");
      form.setFieldValue("handlingCharges", "0.00");
      form.setFieldValue("premiumServiceCharges", "0.00");
      form.setFieldValue("fuelServiceCharges", "0.00");

      setFov("0.00");
      setRateCardName("");
      setFinancialYear(
        `${new Date().getFullYear()}-${new Date().getFullYear() + 1}`
      );
      setInsurance("0.00");
      setHandlingCharges("0.00");
      setPremiumServiceCharges("0.00");
      setFuelServiceCharges("0.00");
      setCodCharges("0.00");

      navigate(
        allocateFor === "RETAIL"
          ? "/cash-booking-ratecard"
          : "/account-booking-ratecard",
        {
          state: templateId ? "update" : "create",
        }
      );
    }
    if (createCashRateCardError) {
      message.error(createCashRateCardError.message);
      dispatch(clearCreateCashRateCardTemplate());
    }
  }, [createCashRateCardResult, createCashRateCardError]);
  let nextDate = new Date();
  nextDate.setDate(nextDate.getDate() + 1);

  useEffect(() => {
    let nextDate = new Date();
    nextDate.setDate(nextDate.getDate() + 1);
    let ddNext = String(nextDate.getDate()).padStart(2, "0");
    let mmNext = String(nextDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyyNext = nextDate.getFullYear();

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();
    today = `${dd}-${mm}-${yyyy}`;
    nextDate = ddNext + "-" + mmNext + "-" + yyyyNext;
    setAllocationFromDate(today);
    setAllocationToDate(nextDate);
    if (templateId) {
      dispatch(getCashRateCardDetails(templateId));
    }
  }, []);

  useEffect(() => {
    if (rateCardDetails) {
      if (isTemplate && templateType !== "create") {
        let data = rateCardDetails?.data;
        form.setFieldValue(
          "ratecardName",
          data?.ratecardName ? data.ratecardName : ""
        );
        form.setFieldValue(
          "fovItem",
          data?.fov ? parseFloat(data.fov).toFixed(2) : "0.00"
        );
        form.setFieldValue(
          "insurance",
          data?.insurance ? parseFloat(data.insurance).toFixed(2) : "0.00"
        );
        form.setFieldValue(
          "handlingCharges",
          data?.handlingCharges
            ? parseFloat(data.handlingCharges).toFixed(2)
            : "0.00"
        );
        form.setFieldValue(
          "premiumServiceCharges",
          data?.premiumServiceCharges
            ? parseFloat(data.premiumServiceCharges).toFixed(2)
            : "0.00"
        );
        form.setFieldValue(
          "fuelServiceCharges",
          data?.fuelServiceCharges
            ? parseFloat(data.fuelServiceCharges).toFixed(2)
            : "0.00"
        );
        form.setFieldValue(
          "codCharges",
          data?.codCharges ? parseFloat(data.codCharges).toFixed(2) : "0.00"
        );
        form.setFieldValue("financialYear", data?.financialYear);
        form.setFieldValue("category", data?.category && data.category);
        form.setFieldValue(
          "value",
          data?.values ? data?.values?.split(",") : []
        );
        form.setFieldValue(
          "clientId",
          data?.clientNames ? data?.clientNames : []
        );
        form.setFieldValue(
          "fromDate",
          moment(data?.allocatedFromDate ? data.allocatedFromDate : new Date())
        );
        form.setFieldValue(
          "toDate",
          moment(data?.allocatedToDate ? data.allocatedToDate : nextDate)
        );
        form.setFieldValue(
          "expiryThreshold",
          data?.expiryThreshold && data.expiryThreshold
        );
        setFov(data?.fov ? parseFloat(data.fov).toFixed(2) : "0.00");
        setRateCardName(data?.ratecardName);
        setFinancialYear(data?.financialYear);
        setInsurance(
          data?.insurance ? parseFloat(data.insurance).toFixed(2) : "0.00"
        );
        setHandlingCharges(
          data?.handlingCharges
            ? parseFloat(data.handlingCharges).toFixed(2)
            : "0.00"
        );
        setPremiumServiceCharges(
          data?.premiumServiceCharges
            ? parseFloat(data.premiumServiceCharges).toFixed(2)
            : "0.00"
        );
        setFuelServiceCharges(
          data?.fuelServiceCharges
            ? parseFloat(data.fuelServiceCharges).toFixed(2)
            : "0.00"
        );
        setCodCharges(
          data?.codCharges ? parseFloat(data.codCharges).toFixed(2) : "0.00"
        );
      }
      let temp = rateCardDetails?.data?.services;
      let standardTemp = [];
      let fastTrackTemp = [];
      let valuePlusTemp = [];

      if (temp) {
        temp.map((e) => {
          switch (e.serviceType) {
            case "STANDARD":
              {
                standardTemp.push(e.docs);
              }
              break;
            case "FAST TRACK":
              {
                fastTrackTemp.push(e.docs);
              }
              break;
            case "VALUE PLUS":
              {
                valuePlusTemp.push(e.docs);
              }
              break;
          }
        });
      }
      if (standardTemp) {
        standardTemp.map((item) => {
          item.map((e) => {
            switch (e.docType) {
              case "DOX": {
                let standardDoxTemp = [];
                e.transports.map((item) => {
                  switch (item.mode) {
                    case "Surface": {
                      item.rates.map((rateValue, index) => {
                        standardDoxTemp.push({
                          title:
                            index === item.rates.length - 1
                              ? `Add ${rateValue.rateMode.split("Slab ")[1]}`
                              : `up to ${rateValue.rateMode}`,
                          design: index === item.rates.length - 1 ? true : null,
                          value: Object.entries(
                            item.rates[index]?.rateTypes
                          ).map(([title, value]) => ({
                            title,
                            value: parseFloat(value).toFixed(2),
                          })),
                        });
                      });
                    }
                  }
                });
                setStandardDox(standardDoxTemp);
                break;
              }

              case "NON_DOX":
                {
                  let standardNonDoxTemp = [];
                  e.transports.map((item) => {
                    switch (item.mode) {
                      case "Surface": {
                        standardNonDoxTemp.push({
                          title: "By Surface (Per Kg)",
                          design: null,
                          value: Object.entries(item.rates[0]?.rateTypes).map(
                            ([title, value]) => ({ title, value })
                          ),
                        });
                        break;
                      }
                      case "Air": {
                        item.rates.map((rateItem, index) => {
                          standardNonDoxTemp.push({
                            title:
                              index === item.rates.length - 1
                                ? `Add ${rateItem.rateMode.split("Slab ")[1]}`
                                : "By Air (Per Kg)",
                            design:
                              index === item.rates.length - 1 ? true : null,
                            value: Object.entries(
                              item.rates[index]?.rateTypes
                            ).map(([title, value]) => ({ title, value })),
                          });
                        });
                        break;
                      }
                    }
                  });

                  setStandardNonDox(standardNonDoxTemp);
                }
                break;
            }
          });
        });
      }
      if (fastTrackTemp) {
        fastTrackTemp.map((item) => {
          item.map((e) => {
            switch (e.docType) {
              case "DOX": {
                let fastTrackDoxSurfaceTemp = [];
                let fastTrackDoxAirTemp = [];
                e.transports.map((item) => {
                  switch (item.mode) {
                    case "Surface": {
                      item.rates.map((rateValue, index) => {
                        fastTrackDoxSurfaceTemp.push({
                          title:
                            index === item.rates.length - 1
                              ? `Add ${rateValue.rateMode.split("Slab ")[1]}`
                              : `up to ${rateValue.rateMode}`,
                          design: index === item.rates.length - 1 ? true : null,
                          value: Object.entries(
                            item.rates[index]?.rateTypes
                          ).map(([title, value]) => ({ title, value })),
                        });
                      });
                      break;
                    }
                    case "Air": {
                      item.rates.map((rateValue, index) => {
                        fastTrackDoxAirTemp.push({
                          title:
                            index === item.rates.length - 1
                              ? `Add ${rateValue.rateMode.split("Slab ")[1]}`
                              : `up to ${rateValue.rateMode}`,
                          design: index === item.rates.length - 1 ? true : null,
                          value: Object.entries(
                            item.rates[index]?.rateTypes
                          ).map(([title, value]) => ({ title, value })),
                        });
                      });
                    }
                  }
                });
                setFastTrackSurface(fastTrackDoxSurfaceTemp);
                setFastTrackAir(fastTrackDoxAirTemp);
                break;
              }

              case "NON_DOX":
                {
                  let fastTrackNonDoxTemp = [];
                  e.transports.map((item) => {
                    switch (item.mode) {
                      case "Surface": {
                        fastTrackNonDoxTemp.push({
                          title: "By Surface (Per Kg)",
                          design: null,
                          value: Object.entries(item.rates[0]?.rateTypes).map(
                            ([title, value]) => ({ title, value })
                          ),
                        });
                        break;
                      }
                      case "Air": {
                        item.rates.map((rateItem, index) => {
                          fastTrackNonDoxTemp.push({
                            title:
                              index === item.rates.length - 1
                                ? `Add ${rateItem.rateMode.split("Slab ")[1]}`
                                : "By Air (Per Kg)",
                            design:
                              index === item.rates.length - 1 ? true : null,
                            value: Object.entries(
                              item.rates[index]?.rateTypes
                            ).map(([title, value]) => ({ title, value })),
                          });
                        });
                        break;
                      }
                    }
                  });
                  setFastTrackNonDox(fastTrackNonDoxTemp);
                }
                break;
            }
          });
        });
      }
      if (valuePlusTemp) {
        valuePlusTemp.map((item) => {
          item.map((e) => {
            switch (e.docType) {
              case "DOX": {
                let valuePlusDoxTemp = [];
                e.transports.map((item) => {
                  switch (item.mode) {
                    case "Surface": {
                      item.rates.map((rateValue, index) => {
                        valuePlusDoxTemp.push({
                          title:
                            index === item.rates.length - 1
                              ? `Add ${rateValue.rateMode.split("Slab ")[1]}`
                              : `up to ${rateValue.rateMode}`,
                          design: index === item.rates.length - 1 ? true : null,
                          value: Object.entries(
                            item.rates[index]?.rateTypes
                          ).map(([title, value]) => ({ title, value })),
                        });
                      });
                    }
                  }
                });
                setValuePlusDox(valuePlusDoxTemp);
                break;
              }

              case "NON_DOX":
                {
                  let valuePlusNonDoxTemp = [];
                  e.transports.map((item) => {
                    switch (item.mode) {
                      case "Surface": {
                        valuePlusNonDoxTemp.push({
                          title: "By Surface (Per Kg)",
                          design: null,
                          value: Object.entries(item.rates[0]?.rateTypes).map(
                            ([title, value]) => ({ title, value })
                          ),
                        });
                        break;
                      }
                      case "Air": {
                        item.rates.map((rateItem, index) => {
                          valuePlusNonDoxTemp.push({
                            title:
                              index === item.rates.length - 1
                                ? `Add ${rateItem.rateMode.split("Slab ")[1]}`
                                : "By Air (Per Kg)",
                            design:
                              index === item.rates.length - 1 ? true : null,
                            value: Object.entries(
                              item.rates[index]?.rateTypes
                            ).map(([title, value]) => ({ title, value })),
                          });
                        });
                        break;
                      }
                    }
                  });
                  setValuePlusNonDox(valuePlusNonDoxTemp);
                }
                break;
            }
          });
        });
      }
    }
  }, [rateCardDetails]);

  let {
    standardDoxDefaultDatas,
    fastTrackSurfaceDefaultDatas,
    standardNonDoxDefaultDatas,
    fastTrackAirDefaultDatas,
    fastTrackNonDoxDefaultDatas,
    valuePlusDoxDefaultDatas,
    valuePlusNonDoxDefaultDatas,
  } = defaultRateCard();

  let {
    standardDoxDatas,
    fastTrackSurfaceDatas,
    standardNonDoxDatas,
    fastTrackAirDatas,
    fastTrackNonDoxDatas,
    valuePlusDoxDatas,
    valuePlusNonDoxDatas,
  } = cashBookingRateCardData();

  useEffect(() => {
    setStandardDox(standardDoxDatas);
    setStandardNonDox(standardNonDoxDatas);
    setFastTrackSurface(fastTrackSurfaceDatas);
    setFastTrackAir(fastTrackAirDatas);
    setFastTrackNonDox(fastTrackNonDoxDatas);
    setValuePlusDox(valuePlusDoxDatas);
    setValuePlusNonDox(valuePlusNonDoxDatas);
    setFinancialYearData(getFinancialYearData());
  }, []);

  useEffect(() => {
    if (categoryResult) {
      let temp = [];
      categoryResult.map((e) => {
        temp.push({
          id: e.premiseId,
          label: capitaliseWithHyphen(e.value),
          value: e.id,
        });
      });
      setCategoryBasedOptions(temp);
      setCategoryBasedData(temp);
    }
    if (categoryError) {
      message.error(categoryError.message);
    }
  }, [categoryResult, categoryError]);

  const setWeightSlabData = (data, title) => {
    switch (title) {
      case "standardDocs":
        setStandardDox(data);
        break;
      case "standardNonDocs":
        setStandardNonDox(data);
        break;
      case "fastTrackSurface":
        setFastTrackSurface(data);
        break;
      case "fastTrackAir":
        setFastTrackAir(data);
        break;
      case "fastTrackNonDox":
        setFastTrackNonDox(data);
        break;
      case "valueplusDox":
        setValuePlusDox(data);
        break;
      case "valueplusNonDox":
        setValuePlusNonDox(data);
        break;
    }
  };

  const changeDynamicSelect = (title, data) => {
    switch (title) {
      case "standardNonDocs":
        setStandardNonDox(data);
        break;
      case "fastTrackNonDox":
        setFastTrackNonDox(data);
        break;
      case "valueplusNonDox":
        setValuePlusNonDox(data);
        break;
    }
  };

  const selectTemplate = (e) => {
    form.setFieldValue("tempValue", null);
    setStandardDox(standardDoxDatas);
    setStandardNonDox(standardNonDoxDatas);
    setFastTrackSurface(fastTrackSurfaceDatas);
    setFastTrackAir(fastTrackAirDatas);
    setFastTrackNonDox(fastTrackNonDoxDatas);
    setValuePlusDox(valuePlusDoxDatas);
    setValuePlusNonDox(valuePlusNonDoxDatas);
    setShowTemplateDropdown(e.target.value === 2 ? true : false);
    if (e.target.value === 1) {
      setIsTemplate(true);
      setStandardDox(standardDoxDefaultDatas);
      setStandardNonDox(standardNonDoxDefaultDatas);
      setFastTrackSurface(fastTrackSurfaceDefaultDatas);
      setFastTrackAir(fastTrackAirDefaultDatas);
      setFastTrackNonDox(fastTrackNonDoxDefaultDatas);
      setValuePlusDox(valuePlusDoxDefaultDatas);
      setValuePlusNonDox(valuePlusNonDoxDefaultDatas);
    }

    if (e.target.value === 2) {
      setIsTemplate(false);
      dispatch(getExistingCashRateCardTemplates(""));
    }
  };

  const selectExistingTemplate = (e) => {
    dispatch(getCashRateCardDetails(e));
  };

  const getCategoryValueOption = (e) => {
    setCategoryName(e);
    dispatch(getCategoryBasedValue(e));
    setCategoryValues([]);
    form.setFieldValue("value", []);
  };

  const selectCategoryBasedValue = async (e, item) => {
    setCategoryValues(e);
    form.setFieldValue("clientId", []);
    if (e && allocateFor === "CREDIT") {
      let response = await API.Api(GET_CLIENT_BY_CP).getCustomHeader({
        cpId: item.id,
      });
      if (response?.status) {
        setCpBasedClientList(
          response?.response?.data?.map((item) => ({
            label: capitaliseWithHyphen(item.clientName),
            value: item.clientId,
            id: item.clientId,
          }))
        );
      } else {
        message.error(response?.response?.message || "Internal server error.");
      }
    }
  };

  const searchValue = (e) => {
    //console.log("trigger");
    // categoryBasedOptions.map((e)=>x.push(e.value));
    // var results = Fuzzy.filter(e, x);
    // var matches = results.map(function(el) { return el.string; });
    // console.log(matches, "results");
    // var list = [
    //   {rompalu: "baconing", zibbity: "simba"}
    //   , {rompalu: "narwhal" , zibbity: "mufasa"}
    //   , {rompalu: "a mighty bear canoe", zibbity: "saddam hussein"}
    // ];
    // if (e) {
    //   var options = {
    //     extract: function (el) {
    //       return el.value;
    //     },
    //   };
    //   var results = Fuzzy.filter(e, categoryBasedOptions, options);
    //   var matches = results.map(function (el) {
    //     return el.original;
    //   });
    //   // console.log(matches, "matches");
    //   setCategoryBasedOptions([...matches]);
    // } else {
    //   setCategoryBasedOptions(categoryBasedData);
    // }
  };

  const onSearch = () => {};

  const handleFovChange = (e, data) => {
    switch (data) {
      case "fov":
        setFov(e);
        break;
      case "insurance":
        setInsurance(e);
        break;
      case "handlingCharges":
        setHandlingCharges(e);
        break;
      case "premiumServiceCharges":
        setPremiumServiceCharges(e);
        break;
      case "fuelServiceCharges":
        setFuelServiceCharges(e);
        break;
      case "codCharges":
        setCodCharges(e);
        break;
    }
  };

  const changeAllocationFrom = (date, dateString) => {
    setAllocationFromDate(dateString);
    form.setFieldValue("toDate", moment(date).add(1, "days"));
    //moment().add(1, 'days')
  };

  const getRatesDox = (data) => {
    let rateData = [];
    data.map((e) => {
      let temp = {};
      e.value.map(
        (item) =>
          (temp[item.title] = item.value
            ? parseFloat(item.value).toFixed(2)
            : "0.00")
      );
      rateData.push({
        rateMode: e.title.split(" ").includes("up")
          ? e.title.split("to ")[1]
          : `Additional Weight Slab ${e.title.split("Add ")[1]}`,
        rateTypes: temp,
      });
    });
    return rateData;
  };

  const getRatesNonDoxSurface = (data) => {
    let temp1 = {};
    let temp2 = {};
    data[0].value.map(
      (item) =>
        (temp1[item.title] = item.value
          ? parseFloat(item.value).toFixed(2)
          : "0.00")
    );
    data[2].value.map(
      (item) =>
        (temp2[item.title] = item.value
          ? parseFloat(item.value).toFixed(2)
          : "0.00")
    );
    let rateData = [
      {
        rateMode: "Weight Slab 1000 Gms",
        rateTypes: temp1,
      },
      {
        rateMode: `Additional Weight Slab ${data[2].title.split("Add ")[1]}`,
        rateTypes: temp2,
      },
    ];
    return rateData;
  };

  const getRatesNonDoxAir = (data) => {
    let temp1 = {};
    let temp2 = {};
    data[1].value.map(
      (item) =>
        (temp1[item.title] = item.value
          ? parseFloat(item.value).toFixed(2)
          : "0.00")
    );
    data[2].value.map(
      (item) =>
        (temp2[item.title] = item.value
          ? parseFloat(item.value).toFixed(2)
          : "0.00")
    );
    let rateData = [
      {
        rateMode: "Weight Slab 1000 Gms",
        rateTypes: temp1,
      },
      {
        rateMode: `Additional Weight Slab ${data[2].title.split("Add ")[1]}`,
        rateTypes: temp2,
      },
    ];
    return rateData;
  };

  const onSubmit = () => {
    const standardDoxData = getRatesDox(standardDox);
    const fastTrackSurfaceData = getRatesDox(fastTrackSurface);
    const fastTrackAirData = getRatesDox(fastTrackAir);
    const valuePlusDoxData = getRatesDox(valuePlusDox);
    const standardNonDoxSurfaceData = getRatesNonDoxSurface(standardNonDox);
    const standardNonDoxAirData = getRatesNonDoxAir(standardNonDox);
    const fastTrackNonDoxSurfaceData = getRatesNonDoxSurface(fastTrackNonDox);
    const fastTrackNonDoxAirData = getRatesNonDoxAir(fastTrackNonDox);
    const valuePlusNonDoxSurfaceData = getRatesNonDoxSurface(valuePlusNonDox);
    const valuePlusNonDoxAirData = getRatesNonDoxAir(valuePlusNonDox);
    let rateCardId = templateId ? { id: templateId } : "";
    const payload = {
      ...rateCardId,
      ratecardName:
        rateCardName.charAt(0).toUpperCase() + rateCardName.slice(1),
      financialYear: financialYear,
      fov: fov,
      insurance: insurance,
      handlingCharges: handlingCharges,
      premiumServiceCharges: premiumServiceCharges,
      fuelServiceCharges: fuelServiceCharges,
      codCharges: codCharges,
      services: [
        {
          serviceType: "STANDARD",
          docs: [
            {
              docType: "DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: standardDoxData,
                },
                {
                  mode: "Air",
                  rates: standardDoxData,
                },
              ],
            },
            {
              docType: "NON_DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: standardNonDoxSurfaceData,
                },
                {
                  mode: "Air",
                  rates: standardNonDoxAirData,
                },
              ],
            },
          ],
        },
        {
          serviceType: "FAST TRACK",
          docs: [
            {
              docType: "DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: fastTrackSurfaceData,
                },
                {
                  mode: "Air",
                  rates: fastTrackAirData,
                },
              ],
            },
            {
              docType: "NON_DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: fastTrackNonDoxSurfaceData,
                },
                {
                  mode: "Air",
                  rates: fastTrackNonDoxAirData,
                },
              ],
            },
          ],
        },
        {
          serviceType: "VALUE PLUS",
          docs: [
            {
              docType: "DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: valuePlusDoxData,
                },
                {
                  mode: "Air",
                  rates: valuePlusDoxData,
                },
              ],
            },
            {
              docType: "NON_DOX",
              transports: [
                {
                  mode: "Surface",
                  rates: valuePlusNonDoxSurfaceData,
                },
                {
                  mode: "Air",
                  rates: valuePlusNonDoxAirData,
                },
              ],
            },
          ],
        },
      ],
    };
    dispatch(createCashRateCardTemplate(payload, templateId ? "PUT" : "POST"));
  };

  const allocateTemplate = (values) => {
    let clientId = null;
    if (allocateFor === "CREDIT") {
      clientId = {
        clientId: values.clientId,
      };
    }
    let payload = {
      ratecardId: templateId,
      category: values.category,
      value: allocateFor === "CREDIT" ? [values.value] : values.value,
      fromDate: allocationFromDate,
      toDate: allocationToDate,
      expiryThreshold: parseInt(values.expiryThreshold),
      rateCardType: allocateFor,
      ...clientId,
    };
    console.log(payload, "payload");
    dispatch(allocateCashRateCard(payload));
  };

  const upperCase = (e) => {
    let splitStr = e.target.value.toLowerCase().split(" ");
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    setRateCardName(splitStr.join(" "));
    form.setFieldValue("ratecardName", splitStr.join(" "));
  };

  const cancelTemlate = () => {
    if (templateType === "create") {
      navigate("/master-card-child", { state: 5 });
    } else if (templateType === "allocate") {
      navigate("/cash-booking-ratecard", {
        state: "allocate",
      });
    } else {
      navigate("/cash-booking-ratecard", {
        state: templateId ? "update" : "create",
      });
    }
  };
  return (
    <Spin spinning={loading || loading1 || loading2 || loading3}>
      {alertData && <Alert alertData={alertData} />}
      <div
        className="template-container"
        style={{ height: templateType === "create" ? "70vh" : "75vh" }}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={templateType === "allocate" ? allocateTemplate : onSubmit}
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
        >
          <Row className="mt-1">
            <Col xs={1} sm={1} md={3} lg={3} xl={3}></Col>
            <Col xs={22} sm={22} md={18} lg={18} xl={18}>
              <span className="master-title">
                {templateType} Rate Card Template
              </span>

              <Row
                className={"booking-details-container mainForm "}
                style={{ marginTop: "1rem" }}
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Collapse
                    defaultActiveKey={
                      templateType === "allocate" ? ["1", "3"] : ["1"]
                    }
                    expandIcon={({ isActive }) => (
                      <CaretRightOutlined
                        rotate={isActive ? 90 : 0}
                        className="cash-rate-collapse"
                      />
                    )}
                  >
                    <Panel
                      header="Template Details"
                      key="1"
                      className="cash-rate-pannel"
                    >
                      <Row>
                        <Col
                          className="form-inputs"
                          xs={24}
                          sm={24}
                          md={11}
                          lg={11}
                          xl={11}
                          offset={1}
                        >
                          <Form.Item
                            name="ratecardName"
                            label="Template Name"
                            initialValue={rateCardName}
                            rules={[
                              {
                                required: true,
                                message: "Please enter template name",
                              },
                              {
                                pattern: /^[a-zA-Z].*/g,
                                message: "First Character should be alphabet",
                              },
                              {
                                min: 3,
                                message:
                                  "Template name must be minimum of 3 characters.",
                              },

                              () => ({
                                async validator(_, value) {
                                  if (
                                    value.length > 3 &&
                                    templateType === "create"
                                  ) {
                                    try {
                                      let res = await axios.get(
                                        `${APIs.baseURL}/ratecard-service/v1/ratecard/availibility/${value}`,
                                        {
                                          headers: {
                                            Authorization: token,
                                          },
                                        }
                                      );
                                      return Promise.resolve();
                                    } catch (error) {
                                      return Promise.reject(
                                        "This name is already exists."
                                      );
                                    }
                                  }
                                },
                              }),
                            ]}
                          >
                            <Input
                              placeholder="Enter template name"
                              onChange={(e) => setRateCardName(e.target.value)}
                              disabled={
                                templateType === "create" ? false : true
                              }
                              initialValue={rateCardName}
                              // onKeyDown={(e) => {
                              //   if (!/[a-zA-Z]/.test(e.target.value))
                              //     e.target.value = "";
                              // }}
                              onKeyPress={(e) => {
                                if (/[^A-Za-z0-9-_@ ]/.test(e.key)) {
                                  e.preventDefault();
                                }
                                if (e.target.value.length >= 80) {
                                  e.preventDefault();
                                }
                                if (e.target.value.length > 0) {
                                  e.target.value.toLowerCase();
                                }
                                //e.target.value.capitalize();
                              }}
                              //style={{ textTransform: "capitalize" }}
                              className="template-name"
                              onBlur={upperCase}
                              autoComplete="off"
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          className="form-inputs"
                          xs={24}
                          sm={24}
                          md={11}
                          lg={11}
                          xl={11}
                          offset={1}
                        >
                          <Form.Item
                            name="financialYear"
                            label="Financial Year"
                            rules={
                              templateType !== "view" && [
                                {
                                  required: true,
                                  message: "Please select financial year",
                                },
                              ]
                            }
                            initialValue={financialYear}
                          >
                            <Select
                              placeholder="Select financial year"
                              options={financialYearData}
                              defaultValue={financialYear}
                              onChange={(e) => setFinancialYear(e)}
                              disabled={
                                templateType === "view" ||
                                templateType === "allocate"
                                  ? true
                                  : false
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Panel>
                    <Panel
                      header="Miscellaneous Charges"
                      key="2"
                      className="cash-rate-pannel"
                    >
                      <FovForm
                        fovData={fov}
                        insuranceData={insurance}
                        handlingChargesData={handlingCharges}
                        premiumServiceChargesData={premiumServiceCharges}
                        fuelServiceChargesData={fuelServiceCharges}
                        codChargesData={codCharges}
                        handleFovFormChange={(e, data) =>
                          handleFovChange(e, data)
                        }
                        disabled={
                          templateType === "view" || templateType === "allocate"
                            ? true
                            : false
                        }
                      />
                    </Panel>
                    {/*//----//*/}
                    {templateType === "allocate" ||
                    (templateType === "view" && allocateStatus === "Yes") ? (
                      <Panel
                        header="Allocation Details"
                        key="3"
                        className="cash-rate-pannel"
                      >
                        <AllocateForm
                          getCategoryValueOption={getCategoryValueOption}
                          categoryBasedOptions={categoryBasedOptions}
                          searchValue={searchValue}
                          allocationFromDate={allocationFromDate}
                          allocationToDate={allocationToDate}
                          disabled={templateType === "view" ? true : false}
                          templateType={templateType}
                          allocateFor={allocateFor}
                          changeFromDate={(date, dateString) =>
                            changeAllocationFrom(date, dateString)
                          }
                          changeToDate={(date, dateString) => {
                            setAllocationToDate(dateString);
                          }}
                          selectCategoryBasedValue={(e, item) =>
                            selectCategoryBasedValue(e, item)
                          }
                          clientList={cpBasedClientList}
                        />
                      </Panel>
                    ) : (
                      ""
                    )}
                    {/* </Panel> */}
                  </Collapse>
                  {/* //--Table Data Selection--// */}
                  {templateType === "view" ||
                  templateType === "allocate" ? null : (
                    <Row>
                      <Col
                        className="form-inputs"
                        xs={24}
                        sm={24}
                        md={11}
                        lg={11}
                        xl={11}
                        offset={1}
                        style={{ marginTop: "1.3rem" }}
                      >
                        <Form.Item
                          name="templateType"
                          label="Select Template Type"
                          className="span-color"
                        >
                          <Radio.Group onChange={selectTemplate}>
                            <Radio value={1}>
                              <span className="f-14">Default</span>
                            </Radio>
                            <Radio value={2} className="ml-2">
                              <span className="f-14">Existing Template</span>
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      {showTemplateDropdown && (
                        <Col
                          className="form-inputs"
                          xs={24}
                          sm={24}
                          md={11}
                          lg={11}
                          xl={11}
                          offset={1}
                          style={{ marginTop: "0.8rem" }}
                        >
                          <Form.Item
                            name="tempValue"
                            label="Select Template"
                            className="pr-1"
                          >
                            <Select
                              showSearch
                              placeholder="Template name"
                              options={rateCardTemplatesData}
                              onSearch={onSearch}
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              onChange={selectExistingTemplate}
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  )}
                  {/* //--// */}
                </Col>
              </Row>
            </Col>
            <Col xs={1} sm={1} md={3} lg={3} xl={3}></Col>
          </Row>

          <RateCardTable
            standardDocsData={standardDox}
            standardNonDocsData={standardNonDox}
            fastTrackSurfaceData={fastTrackSurface}
            fastTrackAirData={fastTrackAir}
            fastTrackNonDoxData={fastTrackNonDox}
            valuePlusDoxData={valuePlusDox}
            valuePlusNonDoxData={valuePlusNonDox}
            handleChangeDocs={(e, data) => setWeightSlabData(e, data)}
            templateType={templateType}
            handleDynamicSelect={(data, title) =>
              changeDynamicSelect(data, title)
            }
          />

          {/* //--Footer--// */}
          {templateType === "view" ? (
            <>
              <hr className="divider mt-3" />
              <div className="d-flex flex-end p-1">
                <Button
                  className="btn-style cancel-btn w-10"
                  onClick={cancelTemlate}
                >
                  CANCEL
                </Button>
              </div>
            </>
          ) : (
            <>
              <hr className="divider mt-3" />
              <div className="d-flex flex-end p-1">
                <Button
                  className="btn-style cancel-btn w-10"
                  onClick={cancelTemlate}
                >
                  CANCEL
                </Button>
                {templateType === "create" || templateType === "edit" ? (
                  <Button
                    className="btn-style save-btn ml-1 w-10"
                    onClick={form.submit}
                  >
                    SAVE
                  </Button>
                ) : (
                  <Button
                    htmlType="submit"
                    className="btn-style save-btn ml-1 w-10"
                  >
                    ALLOCATE
                  </Button>
                )}
              </div>
            </>
          )}
          {/* //----// */}
        </Form>
      </div>
    </Spin>
  );
};
export default CreateTemplate;
