import { call } from "redux-saga/effects";
import actions from "../../constants/cashBookingActions";
import constants from "../../constants/constants";
import { getUserAndPremiseId } from "../../utils";
import { getDataFromAPI } from "../../utils/sagas";

import { getToken, APIs } from "../../utils/worker";

const getTokenId = getToken() || null;
const authToken = getTokenId || null;

const getUserInfo = getUserAndPremiseId();
const premiseId = getUserInfo?.premiseId;
const userId = getUserInfo?.userId;

export function* loginUser({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/api/users/sign-in`,
    successAction: actions.LOGIN_USER_SUCCESS,
    errorAction: actions.LOGIN_USER_ERROR,
    params: { ...data },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.POST);
}

export function* getCpPremises({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/premise-service/v1/premises/${data}`,
    successAction: actions.GET_CP_PREMISES_SUCCESS,
    errorAction: actions.GET_CP_PREMISES_ERROR,
    params: { ...data, authToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* getsenderPincode({ data }) {
  const reqParams = {
    url: `https://api.postalpincode.in/pincode/${data}`,
    successAction: actions.GET_SENDER_PINCODE_SUCCESS,
    errorAction: actions.GET_SENDER_PINCODE_ERROR,
    params: { ...data, authToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* getGSTDetails({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/gst-service/gstDetails`,
    successAction: actions.GET_GST_DETAILS_SUCCESS,
    errorAction: actions.GET_GST_DETAILS_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* getBookingId({ data, bookingType }) {
  const reqParams = {
    url: `${APIs.baseURL}/barcode-service/v1/barcode/type/${data}`,
    successAction: actions.GET_BARCODE_ID_SUCCESS,
    errorAction: actions.GET_BARCODE_ID_ERROR,
    params: { ...data, bookingType, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET, {
    "SMCS-PREMISE-ID": premiseId,
    "BOOKING-TYPE": bookingType,
  });
}

export function* getBookingDetails({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/v1/bookings/${data}`,
    successAction: actions.GET_BOOKING_DETAILS_SUCCESS,
    errorAction: actions.GET_BOOKING_DETAILS_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* getServiceAbilityCheck({ data, token }) {
  let authToken = token;
  const reqParams = {
    // url: `${APIs.baseURL}/pincode-service/v1/serviceability/${data}`,
    url: `${APIs.bffBaseURL}/v1/bff/serviceability/?pinCode=${data}`,
    successAction: actions.GET_SERVICEABILITY_DETAILIS_SUCCESS,
    errorAction: actions.GET_SERVICEABILITY_DETAILIS_ERROR,
    params: { ...data, authToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET, {
    "Access-Control-Allow-Origin": "*",
  });
}

export function* getCalculaterTatChart({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/tat-service/v1/tat/from/${data.to}/to/${data.from}`,
    successAction: actions.GET_CAL_TATCHART_SUCCESS,
    errorAction: actions.GET_CAL_TATCHART_ERROR,
    params: { ...data, authToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET, {
    "SMCS-PREMISE-ID": premiseId,
    "SMCS-DPREMISE-ID": data?.destinationPremiseId,
  });
}

export function* getPinCodeArea({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/pincode-service/v1/pincode/${data}/areas`,
    successAction: actions.GET_PINCODE_AREASUCCESS,
    errorAction: actions.GET_PINCODE_AREAERROR,

    params: { ...data, authToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* getAccountService({ data, token }) {
  let authToken = token;
  if (token !== undefined) {
    const reqParams = {
      url: `${APIs.bffBaseURL}/v1/bff/bootstrap?emailId=${data}`,
      successAction: actions.GET_ACCOUNT_SERVICE_SUCCESS,
      errorAction: actions.GET_ACCOUNT_SERVICE_ERROR,
      params: { ...data, authToken },
    };
    yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET, {
      "Access-Control-Allow-Origin": "*",
    });
  }
}

export function* postShipmentWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/shipment-service/v1/shipment`,
    successAction: actions.CREATE_SHIPMENT_SUCCESS,
    errorAction: actions.CREATE_SHIPMENT_ERROR,
    clearAction: actions.CREATE_SHIPMENT_CLEAR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.POST, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}

export function* updateShipmentWorker({ data, awbNum }) {
  const reqParams = {
    url: `${APIs.baseURL}/shipment-service/v1/shipment/patch/${awbNum}`,

    successAction: actions.UPDATE_SHIPMENT_SUCCESS,
    errorAction: actions.UPDATE_SHIPMENT_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.PATCH, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}

export function* updateAWBNumWorker({ data, awbNum }) {
  const reqParams = {
    url: `${APIs.baseURL}/shipment-service/v1/shipment/${awbNum}`,

    successAction: actions.UPDATE_AWB_NUM_SUCCESS,
    errorAction: actions.UPDATE_AWB_NUM_ERROR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.PUT);
}

export function* getSingleBookingWorker({ data }) {
  const reqParams = {
    // url: `${APIs.baseURL}/shipment-booking-service/v1/shipments/${data}`,
    url: `${APIs.baseURL}/shipment-booking-service/v1/shipment-detail/${data}`,
    successAction: actions.GET_SINGLE_VIEWBOOKING_SUCCESS,
    errorAction: actions.GET_SINGLE_VIEWBOOKING_ERROR,
    params: { ...data, authToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET);
}

export function* getchildPacketIdsWorker({ p1, p2 }) {
  const reqParams = {
    url: `${APIs.baseURL}/barcode-service/v1/barcode/child/type/${p1}/status/NEW?childBagageCount=${p2}`,
    successAction: actions.GET_CHILDPACKETIDs_SUCCESS,
    errorAction: actions.GET_CHILDPACKETIDS__ERROR,
    params: { ...p1, authToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET, {
    "SMCS-PREMISE-ID": premiseId,
  });
}

export function* getPostalPincodeWorker({ payload }) {
  const reqParams = {
    url: `https://api.postalpincode.in/pincode/${payload}`,
    successAction: actions.GET_POSTAL_PINCODE_SUCCESS,
    errorAction: actions.GET_POSTAL_PINCODE_ERROR,
    params: { ...payload, authToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET, {
    "Access-Control-Allow-Origin": "*",
  });
}

export function* createChildShipmentWorker({ query, payload }) {
  const reqParams = {
    url: `${APIs.baseURL}/shipment-service/v1/shipment/${query}/child`,
    successAction: actions.CREATE_CHILD_SHIPMENT_SUCCESS,
    errorAction: actions.CREATE_CHILD_SHIPMENT_ERROR,
    clearAction: actions.CREATE_CHILD_SHIPMENT_CLEAR,
    params: { payload, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.POST);
}

// e-way bill

export function* getEWayBillWorker({ payload }) {
  const reqParams = {
    url: `https://api.mastergst.com/ewaybillapi/v1.03/ewayapi/getewaybill?email=pravat@hutechsolutions.com&ewbNo=${payload}`,
    successAction: actions.GET_E_WAY_BILL_SUCCESS,
    errorAction: actions.GET_E_WAY_BILL_ERROR,
    params: { ...payload },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
    ip_address: "192.168.0.126",
    client_id: "7527f4c8-dcb3-4394-8386-e29cde76aaf9",
    client_secret: "355c8f28-71f1-43cf-b26e-43558e1e23c1",
    gstin: "05AAACH6188F1ZM",
  });
}

// vehicle onboard
export function* onboardVehicleWorker({ payload }) {
  const reqParams = {
    url: `${APIs.baseURL}/vehicle-service/v1/vehicle`,
    successAction: actions.ONBOARD_VEHICLE_SUCCESS,
    errorAction: actions.ONBOARD_VEHICLE_ERROR,
    params: { ...payload, authToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.POST, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}

export function* updateOnboardedVehicleWorker({ payload, query }) {
  const reqParams = {
    url: `${APIs.baseURL}/vehicle-service/v1/vehicles/${query}`,
    successAction: actions.UPDATE_ONBOARDED_VEHICLE_SUCCESS,
    errorAction: actions.UPDATE_ONBOARDED_VEHICLE_ERROR,
    params: { ...payload, authToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.PUT, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}

export function* disableOnboardedVehicleWorker({ payload, query }) {
  const reqParams = {
    url: `${APIs.baseURL}/vehicle-service/v1/vehicles/${query}/status/INACTIVE`,
    successAction: actions.DISBALE_ONBOARDED_VEHICLE_SUCCESS,
    errorAction: actions.DISBALE_ONBOARDED_VEHICLE_ERROR,
    params: { ...payload, authToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.PUT, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}

export function* getSingleOnboarededVehicleWorker({ payload }) {
  const reqParams = {
    url: `${APIs.baseURL}/vehicle-service/v1/vehicles/${payload}`,
    successAction: actions.GET_SINGLE_ONBOARDED_VEHICLE_SUCCESS,
    errorAction: actions.GET_SINGLE_ONBOARDED_VEHICLE_ERROR,
    params: { ...payload, authToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}

export function* getAllOnboarededVehiclesWorker({ payload }) {
  const reqParams = {
    url: `${APIs.baseURL}/vehicle-service/v1/vehicle`,
    successAction: actions.GET_ALL_ONBOARDED_VEHICLES_TABLE_SUCCESS,
    errorAction: actions.GET_ALL_ONBOARDED_VEHICLES_TABLE_ERROR,
    params: { ...payload, authToken },
  };
  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.GET, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}

export function* accountPostShipmentWorker({ data }) {
  const reqParams = {
    url: `${APIs.baseURL}/shipment-service/v1/shipment/account`,
    successAction: actions.ACCOUNT_CREATE_SHIPMENT_SUCCESS,
    errorAction: actions.ACCOUNT_CREATE_SHIPMENT_ERROR,
    clearAction: actions.ACCOUNT_CREATE_SHIPMENT_CLEAR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.POST, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}

export function* accountUpdateShipmentWorker({ data, awbNum }) {
  const reqParams = {
    url: `${APIs.baseURL}/shipment-service/v1/shipment/account/patch/${awbNum}`,
    successAction: actions.ACCOUNT_UPDATE_SHIPMENT_SUCCESS,
    errorAction: actions.ACCOUNT_UPDATE_SHIPMENT_ERROR,
    clearAction: actions.ACCOUNT_UPDATE_SHIPMENT_CLEAR,
    params: { ...data, authToken },
  };

  yield call(getDataFromAPI, reqParams, constants.HTTP_METHODS.PATCH, {
    ...constants.HTTP_MULTIPART_FORM_HEADERS,
    "SMCS-PREMISE-ID": premiseId,
    "USER-ID": userId,
  });
}
